import { useState, useEffect } from 'react';
import './StyleBar.css';

export default function MenuBar({ grupos, css }) {
  const [stickyClass, setStickyClass]= useState('barradenavegacao')

  useEffect(()=>{
    window.addEventListener('scroll', stickNavbar)
    return()=>{
      window.removeEventListener('scroll', stickNavbar) 
    }
  },[])
  const stickNavbar =()=>{
    if(window !== undefined){
      let windowHeight = window.scrollY 
      windowHeight > 220? setStickyClass('nav-top') : setStickyClass('barradenavegacao')
    }
  }
  const scrollToGroup = (groupID) => {
    const groupElement = document.getElementById(groupID);
    const yOffset = -90; 
    const y = groupElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  return (
    <div className={`${stickyClass}`} style={{ background: `${css?.bgBarraNav}`, color: `${css?.colorBarraNav}` }}>
    <div className='barraNav' >
      {Array.isArray(grupos)? (grupos.map((item, index)=>
        <div className='navGrupos' key={index} onClick={() => scrollToGroup(item.ID_GRUPO)}>
          <div className='navGruposBox'>
            <div className='nameGrupo'>{item.GRUPO}</div>
          </div>
        </div>
      )) :null}  
    </div>
  </div>
    )
}