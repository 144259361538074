import '../../../Styles/StyleLogin.css'
import Footer from '../../Footer/Footer'
import LoginHeader from './LoginHeader'
import LoginGoogle from './LoginMetodos/LoginGoogle'
import LoginApple from './LoginMetodos/LoginApple'
import { useTranslation } from 'react-i18next'
import BtnsFlutuantes from '../../BarrasNavegacao/BtnsFlutuantes'

export default function Login({ css }){
    const {t} = useTranslation();

    return(
        <div className='pagina'>
            <BtnsFlutuantes
                css={css}
            />
            <div className='Main'>
                <div>
                    <LoginHeader/>
                </div>
                <div className='MainLogin'>
                    <div className='MainLoginTexto'> {t('acesseConta')} </div>
                        <LoginGoogle/>
                        <LoginApple/>
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}