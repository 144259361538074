import '../style.css'

export default function TopoPagina({ css }){
    
    const handleTopo=()=>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        })
    }

    return(
        <div className='btn-topo-pagina'>
            <button className='btnTopo' onClick={handleTopo}>
                <img src={'data:image/png;base64,' + css?.iconeTopo} className='icone40P'/>
            </button>
        </div>
    )
}