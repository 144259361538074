import { useState, useEffect } from 'react'
import TopoHeaderBar from '../../header/TopoHeaderBar'
import Footer from '../../Footer/Footer'
import PedidosCorpo from './PedidosCorpo'
import './pedidoscorpo.css'
import BtnsFlutuantes from '../../BarrasNavegacao/BtnsFlutuantes'

export default function PedidoCartaoMesa({ css, dadosConexao }){
    const [numeroPedido, setNumeroPedido] = useState('');
    
    useEffect(()=>{
        const numeroPedido = localStorage.getItem('numeroPedido')
            setNumeroPedido(numeroPedido)
    }, [])
    
    return(
        <div className='pagina' style={{ background: `${css?.bgPedidoMesaCartao}` }}>
            <BtnsFlutuantes
                css={css}
            />
            <div>
                <TopoHeaderBar
                    css={css}
                />
            </div>
            <div className='Main'>
                <div className='margin'>
                    <PedidosCorpo 
                        css={css}
                        numeroPedido={numeroPedido}
                        dadosConexao={dadosConexao}
                    />
                </div>
            </div>
        <div >
          <Footer
            css={css}
          />
        </div>
      </div>
    )
}