import { useState, useEffect } from 'react'
import '../../../../Styles/StyleEndereco.css'
import '../../../../Styles/StyleImagens.css'
import { api } from '../../../../conecções/api';
import ModalEndereco from '../../../Usuarios/UsuariosInfo/partesUser/ModalEndereco'
import ModalCadastrarEndereco from '../../../Usuarios/UsuariosInfo/partesUser/ModalCadastrarEndereco'
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula'

export default function Endereços ({ user, setEnderecoSelecionado, dadosConexao, setTaxaEntrega, css }){
    const [endereco, setEndereco] = useState([]);
    const [isOpenModalEndereco, setIsOpenModalEndereco] = useState(false);
    const [enderecoMudar, setEnderecoEditar] = useState('');
    const [isOpenCadastrarEndereco, setIsOpenCadastrarEndereco] = useState(false);
    const [listaTamanhosAtivos, setListaTamanhosAtivos] = useState(null);
    const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
    const [atualizarEnderecos, setAtualizarEnderecos] = useState(false);
    const uidToken = user.uid; 
    const retirarNoLocal = {
        item: "",
        index: -1
    }
    const {t} = useTranslation();

    useEffect(()=>{
        if(dadosConexao && uidToken){
            if(atualizarEnderecos === true){
                handleEnderecos()
            }
            handleEnderecos()
        }
    }, [atualizarEnderecos, dadosConexao, uidToken])
    const RadioEndereco = (item, index) => {
        if(selectedRadioIndex === index){
            setSelectedRadioIndex(null)
        } else {
            setSelectedRadioIndex(index)
            if(index !== -1){
                verificarEntrega(item)
            } else {
                setEnderecoSelecionado(item)
                setTaxaEntrega(0)
            }
        }
    };  
    const verificarEntrega=(item)=>{
        const ROTAbairros = `http://${dadosConexao.api}:${dadosConexao.porta}/bairros/${item.ID_CIDADES}`
        const dados_conexao = encodeURIComponent(ROTAbairros)
        api
        .post(`/requisicaoDados/${dados_conexao}`)
            .then((response) => {
                const bairrosAceitos = response.data;
                const selectedAddress = bairrosAceitos.find((bairro) => bairro.ID === item.ID_BAIRRO);
                    if (selectedAddress && selectedAddress.ENTREGAR === 'NÃO') {
                        const mensagem = t('entregaNaoBairro')
                        const cor = 'amarelo'
                        JanelaMensagem(mensagem, cor)
                    } else {
                        setTaxaEntrega(selectedAddress.TAXA_ENTREGA)
                        setEnderecoSelecionado(item)
                    }
            })
            .catch((error) => {
                const mensagem = 'Erro 554'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const handleEnderecos=()=>{
        const ROTAbairros = `http://${dadosConexao.api}:${dadosConexao.porta}/enderecos/${uidToken}`
        const dados_conexao = encodeURIComponent(ROTAbairros)
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((getdata)=>{
                    setEndereco(getdata.data)
                    setAtualizarEnderecos(false)
                })
                .catch((error) => {
                    const mensagem = 'Erro 553'
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const editarEndereco=(item)=>{
        setIsOpenModalEndereco(true)
        setEnderecoEditar(item)
    }
    const toggleListaTamanhos = (IdEndereco) => {
        if (listaTamanhosAtivos === IdEndereco) {
            setListaTamanhosAtivos(null);
        } else {
            setListaTamanhosAtivos(IdEndereco);
        }
    }
 
    return(
        <div className='endereços'>
            <div className='tituloEnderecos flex between' style={{ background: `${css?.bgCartTituloEnderecos}`, color: `${css?.colorCartTituloEndereco}` }} >
                <div className='flex'>
                    <img src={'data:image/png;base64,' + css?.iconeLocalizacao} className='iconeLocalizacao'/>
                    <div className='nomeEndereco'> {capitalizeFirstLetter(t('enderecos'))} </div>
                </div>
                <div style={{ marginRight: '10px'}}>
                    <button className='Btn' onClick={()=> setIsOpenCadastrarEndereco(true)} style={{ background: `${css?.bgBtnAddEndereco}`, color: `${css?.colorBtnAddEndereco}` }}> 
                        <div className='sign'>
                            <img src={'data:image/png;base64,' + css?.iconeAdd} className='iconeSoma'/>
                        </div>
                        <div className='text' style={{ fontSize: '13px'}}> {capitalizeFirstLetter(t('novoEndereço'))} </div>
                    </button>
                </div>
            </div>
            <div 
                className='descricaoendereco'
                style={{ background: `${selectedRadioIndex === retirarNoLocal.index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${css?.colorCartCardEnderecos}` }}
            >
                <div className='enderecoApelido' onClick={() => RadioEndereco(retirarNoLocal.item, retirarNoLocal.index)}>
                    <div className='cardIcon'>
                        <input type='checkbox' checked={selectedRadioIndex === retirarNoLocal.index} onChange={() => {}}/>
                            {selectedRadioIndex === retirarNoLocal.index
                                ? (
                                    <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                                )
                                : (
                                    <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                                )
                            }
                    </div>
                    <div className='textoApelido'>
                            {t('retirarLocal')}
                    </div>
                    <div className='btneditarendereco'>
                    </div>
                    <div className='caixaBtnExpandir'></div>
                </div>
            </div>
            <div className='endereco'>
                {Array.isArray(endereco) ?(
                    endereco.map((item, index)=>
                        <div 
                            style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}
                            onClick={() => RadioEndereco(item, index)} 
                            className='descricaoendereco'
                            key={index}
                        >
                            <div className='enderecoApelido'> 
                                <div className='cardIcon'>
                                    <input type='radio' name='endereco' checked={selectedRadioIndex === index} onChange={() => {}}/>
                                        {selectedRadioIndex === index
                                            ? (
                                                <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                                            )
                                            : (
                                                <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                                            )
                                        }
                                </div>
                                <div className='textoApelido'> {capitalizeFirstLetter(item.APELIDO.toLowerCase())} </div>
                                <div className='btneditarendereco'>
                                    <button className='BtnEditar' onClick={()=> editarEndereco(item)}>
                                        <img src={'data:image/png;base64,' + css?.iconeEditar} className='iconeEditar'/>
                                    </button>
                                </div>
                                <div className='caixaBtnExpandir' onClick={() => toggleListaTamanhos(item.ID)}>
                                    {listaTamanhosAtivos === item.ID 
                                        ? <img src={'data:image/png;base64,' + css?.iconeParaCima} className='tamanhoIcone'/> 
                                        : <img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='tamanhoIcone'/>
                                    }
                                </div>
                            </div>
                                {listaTamanhosAtivos === item.ID ? (
                                <div>
                                    <div className='caixaEndereco'>
                                        <div className='enderecoRua'>
                                            <div className='tituloRua'
                                                style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}
                                            >
                                                {t('rua')} 
                                            </div>
                                            <div className='textoRua'> {capitalizeFirstLetter(item.RUA.toLowerCase())} </div>
                                        </div>
                                        <div className='enderecoNumero'
                                                style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}> 
                                            <div className='tituloRua'
                                                style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}> {t('numero')} </div>
                                            <div className='textoRua'> {item.NUMERO} </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='tituloRua'
                                                style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}> {t('referencia')} </div>
                                        <div className='textoRua'> {capitalizeFirstLetter(item.REFERENCIA.toLowerCase())} </div>
                                    </div>
                                    <div>
                                        <div className='tituloRua'
                                                style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}> {t('bairro')} </div>
                                        <div className='textoRua'> {capitalizeFirstLetter(item.BAIRRO.toLowerCase())} </div>
                                    </div>
                                    <div>
                                        <div className='tituloRua'
                                                style={{ background: `${selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCartCardEnderecos}`}`, color: `${selectedRadioIndex === index ? `${css?.colorChecked}` : `${css?.colorCartCardEnderecos}`}` }}> {t('cidade')} </div>
                                        <div className='textoRua'> {capitalizeFirstLetter(item.CIDADE.toLowerCase())} </div>
                                    </div>
                                </div>
                                ) : null}
                        </div>
                    )
                ) : null}
            </div>
                <div>
                    {isOpenModalEndereco && <ModalEndereco user={user} item={enderecoMudar} setIsOpenModalEndereco={setIsOpenModalEndereco} setAtualizarEnderecos={setAtualizarEnderecos} css={css} />}
                </div>
                <div>
                    {isOpenCadastrarEndereco && <ModalCadastrarEndereco user={user} setIsOpenCadastrarEndereco={setIsOpenCadastrarEndereco} css={css} />}
                </div>
        </div>
    )
}