import { useState, useEffect } from 'react'
import './Styles/StyleTelaInicial.css'
import { useNavigate } from 'react-router-dom'
import { auth } from './components/Usuarios/LoginPage/Firebase/firebaseConfig';
import { signOut } from 'firebase/auth';
import { JanelaMensagem } from './auxiliares/janelaFlutuante/JanelaMensagem'
import { useTranslation } from 'react-i18next';
import './Tradutor'
import Runnotification from './notifications/Runnotification'
import { descript, pegarDadosEmpresa } from './funcoes/Funcoes';

export default function TelaInicialCardapio({ dadosConexao, css }){
    const [tipoComanda, setTipoComanda] = useState(null);
    const [numeroComanda, setNumeroComanda] = useState(null);
    const [pagamento, setPagamento] = useState(null);
    const [dadosEmpresa, setDadosEmpresa] = useState(null);
    const [codigoEmpresa, setCodigoEmpresa] = useState(null);
    const [navegar, setNavegar] = useState(false);
    const navigate = useNavigate()
    const { t } = useTranslation();
    
    useEffect(()=>{
        localStorage.clear()
            signOut(auth)
        const urlParams = new URLSearchParams(window.location.search);
        const codigoEmpresa = urlParams.get('17011d0b');
            localStorage.setItem('codigoEmpresa', codigoEmpresa)
            setCodigoEmpresa(codigoEmpresa)
        if(codigoEmpresa !== null){
            handlePegarDados(codigoEmpresa)
            const criptTipoComanda = urlParams.get('00061d0e2e001e020f0204');
            const criptNumeroComanda = urlParams.get('1a1a00041f00300c0c070b1200');
            const criptPagamentoComanda = urlParams.get('040e0a00000a1d170e');
                unCript(criptTipoComanda, criptNumeroComanda, criptPagamentoComanda)
        }
    }, [])
    const unCript = async (criptTipoComanda, criptNumeroComanda, criptPagamentoComanda) =>{
        try{
            const tipoComanda = await descript(criptTipoComanda)
                setTipoComanda(tipoComanda === 'TO' ? null : tipoComanda)
            const numeroComanda = await descript(criptNumeroComanda)
                setNumeroComanda(numeroComanda === 'TO' ? null : numeroComanda)
            const pagamento = await descript(criptPagamentoComanda)
                setPagamento(pagamento === 'TO' ? null : pagamento)
                setNavegar(true)
        } catch(error){
            console.log(error.message)
        }
    }    
    const handlePegarDados = async () =>{
        try{
            const dados = await pegarDadosEmpresa()
                setDadosEmpresa(dados)
        } catch(error){
            console.log(error.message)
        }
    }
    useEffect(()=>{
        if(navegar){
            if(codigoEmpresa !== null && dadosConexao?.status && tipoComanda !== null){
                handleNavegar()
            }
        }
    }, [codigoEmpresa, dadosConexao, tipoComanda, navegar])
    const handleNavegar=()=>{ 
        if(dadosConexao?.status === 'ATIVO'){
            if (pagamento === 'SIM'){
                irParaPagamentoCartao()
            } 
            else {
                if(tipoComanda === "MESA"){
                    irParaMenu()
                } 
                else if(tipoComanda === "CARTAO"){
                    irParaMenu()
                }
                else if (tipoComanda === "CARDAPIO"){
                    irParaCardapioAbreviado()
                }
                else if (tipoComanda === "DELIVERY"){
                    irParaMenu()
                }
            }
        } 
        else if (dadosConexao?.status === 'DESATIVADO'){
            const mensagem = (t('paginaDesativada'));
            const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
        }
    }
    const irParaMenu=()=>{
        console.log(numeroComanda)
        if (tipoComanda === 'DELIVERY') {
            Runnotification()
        }
        auth.onAuthStateChanged((user) => {
            if(user !== null){
                localStorage.setItem('uidToken', user.uid)
            }
        });
        localStorage.setItem('tipoComanda', tipoComanda)
        localStorage.setItem('numeroComanda', numeroComanda)
        localStorage.setItem('pagamento', pagamento)
        localStorage.setItem('cardapio', "NAO")
        const timeout = setTimeout(() => {
            navigate('/Main')
        }, 3000);
        return () => {
            clearTimeout(timeout);
    }}
    const irParaPagamentoCartao=()=>{
        localStorage.setItem('tipoComanda', tipoComanda)
        localStorage.setItem('numeroComanda', numeroComanda)
        localStorage.setItem('pagamento', pagamento)
        localStorage.setItem('cardapio', "NAO")
        const timeout = setTimeout(() => {    
            navigate('/Pedidos')
        }, 3000);
        return () => {
            clearTimeout(timeout);
        }
    }
    const irParaCardapioAbreviado =()=>{
        localStorage.setItem('tipoComanda', "MESA")
        localStorage.setItem('numeroComanda', 0)
        localStorage.setItem('pagamento', pagamento)
        localStorage.setItem('cardapio', "SIM")
        const timeout = setTimeout(() => {
                navigate('/Main')
        }, 3000);
        return () => {
            clearTimeout(timeout);
        }   
    }
    return(
        <div className='main-TelaInicial' style={{ background: `${css?.bgTelaLoad}`, color: `${css?.colorTelaLoad}` }}>
            <div className='box-telainicial'>
                <div className='logo-box'>
                <div className='logo-telainicial'>
                    {dadosEmpresa !== null ? (
                        <img src={'data:image/png;base64,' + dadosEmpresa.FOTO} alt='Restaurante' className='img-restaurante'/>
                    ) : (
                        <div></div>
                    )}
                </div>
                </div>
                <div className='text-box'>
                    <div> {t('facaPedido')}!</div>  
                </div>
                <div className='box-loading'>
                </div>
                <div className='poweredby'>
                    <div style={{ color: `${css?.colorTelaLoad}` }}> Powered By B&d Info Services. </div>
                </div>
            </div>
        </div>
    )
}
