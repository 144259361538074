import { useState, useEffect } from 'react';
import { api } from '../../../../../../conecções/api';
import { useLocation } from 'react-router-dom';
import '../../../../../../Styles/StyleForAdicionais.css'
import '../../../../../../Styles/StyleImagens.css'
import ListaSaborBusca from './ListaSaborBusca'
import Decimal from 'decimal.js';
import { JanelaMensagem } from '../../../../../../auxiliares/janelaFlutuante/JanelaMensagem'
import { useTranslation } from 'react-i18next';

export default function GruposSabores({ setTamanhoAlterado, dadosConexao, listaSaboresPizzas, setTamanhoParaAdd, setListaSaboresPizzas, quantidadeTotal, setQuantidadeTotal, setValorTotalCustoPizza, setValorTotalSabores, setSaboresSelecionados, SaboresSelecionados, css}){
    const { state } = useLocation();
    const [tamanho, setTamanho] = useState(null);
    const [grupoTamanho, setGrupoTamanho] = useState([]);
    const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
    const [infoClientes, setInfoClientes] = useState('');
    const tipoComanda = localStorage.getItem('tipoComanda')
    const { data } = state;
    const {t} = useTranslation();
    
    useEffect(()=>{
      if(dadosConexao){
        handleListaTamanhos()
      }
    }, [dadosConexao])
    const handleListaTamanhos=()=>{
      const ROTAadicionaisTamanho = `http://${dadosConexao.api}:${dadosConexao.porta}/listaTamanhos/${data.ID_PRODUTO}/${tipoComanda}`;
      const dados_conexao = encodeURIComponent(ROTAadicionaisTamanho);
        api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata) => {
            setGrupoTamanho(getdata.data);
            setSelectedRadioIndex(0);
            setTamanho(getdata.data[0]);
            primeiraLista(getdata.data[0])
          })
          .catch((error) => {
            const mensagem = t('frase.RotaGenerico')
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
          });
    }
    const primeiraLista=(item)=>{
      setTamanhoParaAdd(item)
      const ROTAdadosEmpresa = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosEmpresa/${localStorage.getItem('codigoEmpresa')}`
      const dados_conexao55 = encodeURIComponent(ROTAdadosEmpresa)
      api
        .post(`/requisicaoDados/${dados_conexao55}`)
        .then((getdata) => {
            setInfoClientes(getdata.data[0]);
            const idCardapio = getdata.data[0].ID_CARDAPIO
            handleListaSabores(item, idCardapio)
        })
    }
    const handleListaSabores=(item, idCardapio)=>{
      const ROTAsabores = `http://${dadosConexao.api}:${dadosConexao.porta}/listaSaboresPizza/${item.ID}/${idCardapio}`;
      const dados_conexao2 = encodeURIComponent(ROTAsabores);
        api
          .post(`/requisicaoDados/${dados_conexao2}`)
          .then((getdata)=>{
            const lista = getdata.data.map((item) => ({
              ...item,
              quantidade: 0,
          }));
            const procurarSabor = lista.find((itemSabor) => itemSabor.PRODUTO === data.PRODUTO);
              if (procurarSabor) {
                procurarSabor.quantidade += 1;
              }
              setListaSaboresPizzas(lista)
          })
          .catch((error=>{
            const mensagem = t('frase.RotaGenerico')
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
          }))
    }
    const RadioTamanhos = (item, index) => {
      setSelectedRadioIndex(index);
      setTamanho(item)
      setTamanhoParaAdd(item)
      novaListaSabores(item)
      setTamanhoAlterado(true)
    };
    const novaListaSabores=(item)=>{
      const ROTAsabores = `http://${dadosConexao.api}:${dadosConexao.porta}/listaSaboresPizza/${item.ID}/${infoClientes.ID_CARDAPIO}`;
      const dados_conexao2 = encodeURIComponent(ROTAsabores);
        api
          .post(`/requisicaoDados/${dados_conexao2}`)
          .then((getdata)=>{
            const lista = getdata.data.map((item) => ({
              ...item,
              quantidade: 0,
            }));
            const updatedDataWithQuantities = lista.map((dataItem) => {
              const matchingItem = SaboresSelecionados.find(
                (selectedItem) => selectedItem.PRODUTO === dataItem.PRODUTO
              );
              if (matchingItem) {
                return {
                  ...dataItem,
                  quantidade: matchingItem.quantidade,
                };
              }
              return dataItem;
            });
            setListaSaboresPizzas(updatedDataWithQuantities);
          })
    }
    useEffect(() => {
      let totalCusto = new Decimal(0);
      SaboresSelecionados.forEach(item => {
        if (item.VALOR_MAIOR_MISTA === "NAO") {
          totalCusto = totalCusto.plus(new Decimal(item.VALOR_CUSTO).times(item.quantidade));
        } else if (item.VALOR_MAIOR_MISTA === "SIM") {
          totalCusto = totalCusto.plus(new Decimal(item.VALOR_CUSTO).times(item.quantidade).dividedBy(quantidadeTotal));
        }
      });
      setValorTotalCustoPizza(totalCusto);
    }, [SaboresSelecionados, quantidadeTotal]);
    useEffect(() => {
        if(tamanho !== null){
          if(tamanho.VALOR_MAIOR_MISTA === "NAO"){
            const totalItem = SaboresSelecionados.reduce((acc, item) => {
              const multipliedValue = new Decimal(item.VALOR_VENDA).times(item.quantidade);
              const dividedValue = multipliedValue.dividedBy(quantidadeTotal);
              return acc.plus(dividedValue);
            }, new Decimal(0));
              setValorTotalSabores(totalItem);
          } else if (tamanho.VALOR_MAIOR_MISTA === "SIM"){
            const totalItem = SaboresSelecionados.reduce((acc, item) => {
              const multipliedValue = new Decimal(item.VALOR_VENDA).times(item.quantidade);
              return acc.plus(multipliedValue);
            }, new Decimal(0));
              setValorTotalSabores(totalItem);
          }
        }
    }, [SaboresSelecionados, quantidadeTotal, tamanho]);
    useEffect(() => {
      if (tamanho !== null) {
        if (quantidadeTotal > tamanho.QTD_MAXIMO) {
          const novaListaSabores = [...listaSaboresPizzas];
          let quantidadeParaRemover = quantidadeTotal - tamanho.QTD_MAXIMO;
          for (let i = novaListaSabores.length - 1; i >= 0; i--) {
            const sabor = novaListaSabores[i];
            const quantidadeAtual = sabor.quantidade;
                if (quantidadeAtual > 0) {
              const quantidadeARemover = Math.min(quantidadeParaRemover, quantidadeAtual);
              novaListaSabores[i].quantidade -= quantidadeARemover;
              quantidadeTotal -= quantidadeARemover;
              quantidadeParaRemover -= quantidadeARemover;
              if (quantidadeParaRemover === 0) {
                break;
              }
            }
          }
          setListaSaboresPizzas(novaListaSabores);
        }
      }
    }, [quantidadeTotal, tamanho]);
    
return( 
    <div>
      <div>
      <div>
          <div>{Array.isArray(grupoTamanho) ? (
              <div className='tituloTamanhos' style={{ background: `${css?.bgTituloTamanhoPizza}`, color: `${css?.colorTituloTamanhoPizza}` }}> {t('tamanhos').toUpperCase()} </div>
            ) : (
              <div></div>
            )}
          </div>    
          <div className='listaTamanhos'> 
            {Array.isArray(grupoTamanho)
              ? grupoTamanho.map((item, index) => (
                <div
                  style={{ background: `${selectedRadioIndex === index ? css?.bgChecked : css?.bgCardTamanhoPizza}`, color: `${selectedRadioIndex === index ? css?.colorChecked : css?.colorCardTamanhoPizza}` }}
                  className='cardTamanhos'
                  key={item.ID}
                  onClick={() => RadioTamanhos(item, index)}
                >
                <div className='box-tamanhos-1'>
                  <div className='tamanhos-nome'>{item.TAMANHO}</div>
                </div>
                <div className='box-tamanhos-2'>
                </div>
                <div className='Card-Icon'>
                  <input type='radio' name='tamanhos' checked={selectedRadioIndex === index} onChange={() => {}}/>
                    {selectedRadioIndex === index 
                       ? (
                        <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                      )
                      : (
                        <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                      ) 
                    }
                </div>
              </div>
                ))
              : null}
            </div> 
        </div>
      </div>
        <ListaSaborBusca
            listaSaboresPizzas={listaSaboresPizzas}
            setListaSaboresPizzas={setListaSaboresPizzas}
            Min={tamanho !== null ? tamanho.QTD_MINIMO : ""}
            Max={tamanho !==null ? tamanho.QTD_MAXIMO : ""}

            setSaboresSelecionados={setSaboresSelecionados}
            SaboresSelecionados={SaboresSelecionados}

            quantidadeTotal={quantidadeTotal}
            setQuantidadeTotal={setQuantidadeTotal}
            css={css}
            dadosConexao={dadosConexao}
        />
    </div>
)
}