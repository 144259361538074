import { useState, useEffect } from 'react'
import '../../../../../../Styles/StyleForAdicionais.css'
import '../../../../../../Styles/StyleImagens.css'
import { formCurrency } from '../../../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../../../auxiliares/utilidades/FormataUSD';
import Decimal from 'decimal.js';
import { capitalizeFirstLetter } from '../../../../../../auxiliares/utilidades/PrimeiraMaiuscula';

export default function ListaAdicionais({ itemGrupoAdd, dadosConexao, quantidadeTotalGrupos, Maximo, listaAdicionais, setListaAdicionais, css }){
  const [faltam, setFaltam] = useState('')
  const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
  const [selectedAdicionalIndex, setSelectedAdicionalIndex] = useState(null);
  const moeda = dadosConexao?.moeda

  useEffect(()=>{ 
    let faltam = Maximo;
    const total = faltam - quantidadeTotalGrupos[itemGrupoAdd.ID_GRUPO_OPCOES];
      setFaltam(total)
  }, [Maximo, quantidadeTotalGrupos])
  const aumentarQuantidade = (index) => { 
    const updatedListaOpcionais = [...listaAdicionais];
    const quantidade = new Decimal(updatedListaOpcionais[index].quantidade);
    updatedListaOpcionais[index].quantidade = quantidade.plus(1).toNumber();
        setListaAdicionais(updatedListaOpcionais);
  };
  const diminuirQuantidade = (index) => {
    const updatedListaOpcionais = [...listaAdicionais];
    const quantidade = new Decimal(updatedListaOpcionais[index].quantidade);
    if (quantidade.gt(0)) {
    updatedListaOpcionais[index].quantidade = quantidade.minus(1).toNumber();
        setListaAdicionais(updatedListaOpcionais);
    }
  };
  const selecionarAdicional = (index) => { 
    const updatedListaOpcionais = [...listaAdicionais];
    const quantidade = new Decimal(updatedListaOpcionais[index].quantidade);
    if (quantidade.gt(0)) {
      updatedListaOpcionais[index].quantidade = quantidade.minus(1).toNumber();
    } else {
      updatedListaOpcionais[index].quantidade = quantidade.plus(1).toNumber();
    }
    setListaAdicionais(updatedListaOpcionais);
  };
  const selecionarAdicionalRadio = (index) => {
    if (selectedAdicionalIndex === index) {
      return;
    }
    const updatedListaOpcionais = listaAdicionais.map((item, i) => {
      if (i === index) {
        return { ...item, quantidade: item.quantidade + 1 };
      } else {
        return { ...item, quantidade: 0 };
      }
    });
    setSelectedAdicionalIndex(index);
    setListaAdicionais(updatedListaOpcionais);
    setSelectedRadioIndex(index);
  };
  
  return(
        <div>
            <div className='AdicionaisList'>
              {Array.isArray(listaAdicionais)
                ? listaAdicionais.map((item, index) => (
                    <div key={index} >
                      {itemGrupoAdd.PERMITIR_ITEM_REPETIDO === "SIM" 
                        ? (
                            <div className='cardAdicionais' style={{ background: `${selectedRadioIndex === index ? 'rgb(0 255 0 / 52%)' : css?.bgCardAdicionalBusca}`, color: `${selectedRadioIndex === index ? css?.colorchecked : css?.colorCardAdicionalBusca}` }} key={item.ID} >
                              <div className='descricaoAdicional'>
                                <div> {capitalizeFirstLetter(item.DESCRICAO.toLowerCase())} </div>
                              </div>
                              <div className='valorAdicional'>
                                <div className='adicionalValor'>
                                  {
                                    moeda === 'BRL' ? formCurrency.format(item.VALOR_VENDA) :
                                    moeda === 'ARS' ? formCurrencyArgentina.format(item.VALOR_VENDA) : 
                                    moeda === 'USD' ? formCurrencyUSD.format(item.VALOR_VENDA) : null
                                  } 
                                </div>
                              </div>
                              <div className='funcaoAdicional'>
                                <div className='cardQuantidadesAdd'>
                                  <div className='btnQuantiaAdd'>
                                    <button className='btn' onClick={() => diminuirQuantidade(index)}>
                                      <img src={'data:image/png;base64,' + css?.iconeMenos} className='iconeCart'/>
                                    </button>
                                  </div>
                                    <div className='quantiaAdd'>{item.quantidade}</div>
                                  <div className='btnQuantiaAdd'>
                                    <button className='btn'onClick={() => aumentarQuantidade(index)} disabled={faltam === 0}>
                                     <img src={'data:image/png;base64,' + css?.iconeMais} className='iconeCart'/>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            ) 
                              : itemGrupoAdd.PERMITIR_ITEM_REPETIDO === "NAO" === itemGrupoAdd.MAXIMO > 1 ?
                                (
                                  <div className='cardAdicionais'
                                      style={{ background: `${selectedRadioIndex === index ? 'rgb(0 255 0 / 52%)' : css?.bgCardAdicionalBusca}`, color: `${selectedRadioIndex === index ? css?.colorchecked  : css?.colorCardAdicionalBusca}` }}
                                       key={item.ID}
                                       onClick={() => {
                                        if (faltam !== 0) {
                                          selecionarAdicional(index);
                                        } else if (item.quantidade === 1) {
                                          selecionarAdicional(index);
                                        }
                                        }}>
                                  <div className='descricaoAdicional'>
                                    <div> {capitalizeFirstLetter(item.DESCRICAO.toLowerCase())} </div>
                                  </div>
                                  <div className='valorAdicional'>
                                    <div className='adicionalValor'> 
                                      {
                                        moeda === 'BRL' ? formCurrency.format(item.VALOR_VENDA) :
                                        moeda === 'ARS' ? formCurrencyArgentina.format(item.VALOR_VENDA) : 
                                        moeda === 'USD' ? formCurrencyUSD.format(item.VALOR_VENDA) : null
                                      }
                                    </div>
                                  </div>
                                  <div className='funcaoAdicional'>
                                    <input
                                      type="checkbox"
                                      disabled={faltam === 0 && item.quantidade !== 1}
                                      checked={item.quantidade === 1}
                                      onChange={() => selecionarAdicional(index)}
                                    />
                                      {item.quantidade === 1 
                                        ? (
                                          <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                                        )
                                        : (
                                          <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                                        )  
                                      }
                                  </div>
                                </div>
                                ) 
                              : itemGrupoAdd.MINIMO === 1 ? 
                                (
                                  <div
                                    style={{ background: `${selectedRadioIndex === index ? 'rgb(0 255 0 / 52%)' : css?.bgCardAdicionalBusca}`, color: `${selectedRadioIndex === index ? css?.colorchecked  : css?.colorCardAdicionalBusca}` }}
                                    className='cardAdicionais'
                                    key={item.ID}
                                    onClick={() => selecionarAdicionalRadio(index)}
                                  >
                                    <div className='descricaoAdicional'>
                                      <div> {capitalizeFirstLetter(item.DESCRICAO.toLowerCase())} </div>
                                    </div>
                                    <div className='valorAdicional'>
                                      <div className='adicionalValor'>
                                        {
                                          moeda === 'BRL' ? formCurrency.format(item.VALOR_VENDA) :
                                          moeda === 'ARS' ? formCurrencyArgentina.format(item.VALOR_VENDA) : 
                                          moeda === 'USD' ? formCurrencyUSD.format(item.VALOR_VENDA) : null
                                        }
                                      </div>
                                    </div>
                                    <div className='funcaoAdicional'>
                                      <input type='radio' name='radio' checked={selectedRadioIndex === index} onChange={() => {}} />
                                      {selectedRadioIndex === index 
                                        ? (
                                          <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                                        )
                                        : (
                                          <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                                        ) 
                                      }
                                    </div>
                                  </div>
                                )
                              : null
                            }
                    </div>
                  ))
                : null}   
            </div>
        </div>
    )
}