import { useState, useEffect } from 'react'
import { api } from '../../../../conecções/api';
import { formCurrency } from '../../../../auxiliares/utilidades/FormataBRL';
import { useLocation } from 'react-router-dom';
import TopoHeaderBar from '../../../header/TopoHeaderBar'
import '../../../../Styles/StyleImagens.css'
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';
import { formCurrencyArgentina } from '../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../auxiliares/utilidades/FormataUSD';
import BtnsFlutuantes from '../../../BarrasNavegacao/BtnsFlutuantes';

export default function DetalhesDoPedido({ css, dadosConexao }){
    const [dadosCompraPedido, setDadosCompraPedido] = useState('');
    const tipoComanda = localStorage.getItem('tipoComanda');
    const { state } = useLocation();
    const { itemPedido } = state;
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda

    useEffect(()=>{
        if(dadosConexao){
            if (tipoComanda === "DELIVERY" && itemPedido.STATUS === 6){
                handleListaItensCancelados(itemPedido)
            } else {
                handleListaItensPedido(itemPedido)
            }
        }
    }, [tipoComanda, itemPedido, dadosConexao]);
    const handleListaItensPedido=(itemPedido)=>{
        const ROTAlistaItensPedidos = `http://${dadosConexao.api}:${dadosConexao.porta}/listaItensPedidos/${itemPedido.ID}`
        const dados_conexao2 = encodeURIComponent(ROTAlistaItensPedidos)
            api
                .post(`/requisicaoDados/${dados_conexao2}`)
                .then((getdata)=>{
                    setDadosCompraPedido(getdata.data);
                })
                .catch((error) => {
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const handleListaItensCancelados=(itemPedido)=>{
        const ROTAlistaItensCancelados = `http://${dadosConexao.api}:${dadosConexao.porta}/listaItensCancelados/${itemPedido.ID}`
        const dados_conexao = encodeURIComponent(ROTAlistaItensCancelados)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setDadosCompraPedido(getdata.data);
            })  
            .catch((error) => {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    return(
    <>
        <BtnsFlutuantes
            css={css}
        />
        <div>
            <TopoHeaderBar
                css={css} 
            />
        </div>
        <div className='listaDetalhesPedido' style={{ background: `${css?.bgDetalhesPedido}`, minHeight: '100vh' }} >
            <div className='quadroDetalhesPedido' style={{ background: `${css?.bgDetalhes}`, color: `${css?.colorDetalhes}` }}>
                <div className='quadroPedidoLinha'  >
                    <div className='numeroPedido'> {capitalizeFirstLetter(t('pedido'))} {t('n')} {itemPedido.ID_PEDIDO} </div>
                    <div className={
                      'statusPedidos ' +
                      (itemPedido.STATUS === 1 ? 'novo'
                        : itemPedido.STATUS === 2 ? 'aceito'
                        : itemPedido.STATUS === 3 ? 'preparo'
                        : itemPedido.STATUS === 4 ? 'transporte'
                        : itemPedido.STATUS === 5 ? 'finalizados'
                        : itemPedido.STATUS === 6 ? 'cancelado'
                        : '')
                    }>
                        {itemPedido.STATUS === 1 ? t('novo')
                          : itemPedido.STATUS === 2 ? t('aceito')
                          : itemPedido.STATUS === 3 ? t('preparo')
                          : itemPedido.STATUS === 4 ? t('transporte')
                          : itemPedido.STATUS === 5 ? t('finalizados')
                          : itemPedido.STATUS === 6 ? t('cancelado')
                          : ''
                        }
                  </div>
                </div>
            </div>
            <div className='quadroDetalhesPedido' style={{ background: `${css?.bgListaItensPedido}`, color: `${css?.colorListaItensPedido}` }}>
                <div className='detalhesPedidoTitulo'> {t('items')} </div>
                <div className='detalhesPedidoBox'> 
                    <div className='detalhesPedidoQtd'> {t('qtd')} </div>
                    <div className='detalhesPedidoDesc'> {t('descrição')} </div>
                    <div className='detalhesPedidoValor'> {capitalizeFirstLetter(t('valor'))} </div>
                </div>
                <div>
                    {Array.isArray(dadosCompraPedido)? (
                        dadosCompraPedido.map((item, index)=>
                        <div key={index} className='pedidoItemCard'>
                            <div className='itemCardLinha'>
                                <div className='itemPedidoQTD'> {item.QTDE_COM} </div>
                                <div className='itemPedidoDesc'> {item.DESCRICAO} </div>
                                <div className='itemValor'>
                                    {
                                        moeda === 'BRL' ? formCurrency.format(item.TOTAL) :
                                        moeda === 'ARS' ? formCurrencyArgentina.format(item.TOTAL) : 
                                        moeda === 'USD' ? formCurrencyUSD.format(item.TOTAL) : null
                                    } 
                                </div>
                            </div>
                            <div className='itemCardLinha textOpcional'>
                                <div className='itemCardIcone'>
                                    <div className='iconeItemCardIcone'></div>
                                </div>
                                <div className='itemCardOpcoes'>
                                    {item.SABORES !== null ? (
                                        <div className='itemSabores'> {item.SABORES !== null ? (item.SABORES.toLowerCase()) : null} </div>
                                    ) : null}
                                    <div className='itemOpcionais'>
                                        <div className='nomeOpcional'> {item.OPCOES !== null ? (item.OPCOES.toLowerCase()) : null} </div>
                                    </div>
                                    <div className='itemObservacoes'> {item.OBSERVACOES} </div>
                                </div>
                            </div>
                        </div>
                    )
                    ) : null}
                </div>
            </div>
            <div className='quadroDetalhesPedido' style={{ background: `${css?.bgTotalPedidos}`, color: `${css?.colorTotalPedidos}` }}>
                <div className='barraTotalPedido'>
                    <div> {capitalizeFirstLetter(t('total'))} </div>
                    <div>
                        {
                            moeda === 'BRL' ? formCurrency.format(itemPedido.TOTAL) :
                            moeda === 'ARS' ? formCurrencyArgentina.format(itemPedido.TOTAL) : 
                            moeda === 'USD' ? formCurrencyUSD.format(itemPedido.TOTAL) : null
                        } 
                    </div>
                </div>
            </div>
            
        </div>
    </>
    )
}