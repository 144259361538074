import { api } from "../conecções/api";
import { JanelaMensagem } from "../auxiliares/janelaFlutuante/JanelaMensagem";

export const retornaDadosConexao = async (codigoEmpresa) => {
    try {
        const getdata = await api.get(`/dadosConexao/${codigoEmpresa}`);
            return getdata.data[0];
    } catch (error) {
        const mensagem = 'Erro 501'; 
        const cor = 'vermelho';
            JanelaMensagem(mensagem, cor);
        throw error;
    }
};

export const descript = async (palavra) =>{
    const codigoEmpresa = localStorage.getItem('codigoEmpresa')
    const dadosConexao = await retornaDadosConexao(codigoEmpresa)
    const ROTAdescript = `http://${dadosConexao.api}:${dadosConexao.porta}/descript/${palavra}`
    const dados_conexao = encodeURIComponent(ROTAdescript)    
    try {
        const getdata = await api.post(`/requisicaoDados/${dados_conexao}`);
            return getdata.data;
    } catch(error){
        console.log(error.message)
    }
}

export const pegarDadosEmpresa = async () =>{
    const codigoEmpresa = localStorage.getItem('codigoEmpresa')
    const dadosConexao = await retornaDadosConexao(codigoEmpresa)
    const ROTAdadosEmpresa = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosEmpresa/${codigoEmpresa}`
    const dados_conexao = encodeURIComponent(ROTAdadosEmpresa)    
    try {
        const getdata = await api.post(`/requisicaoDados/${dados_conexao}`);
        const dados = Array.isArray(getdata.data) ? getdata.data[0] : null
            return dados;
    } catch(error){
        console.log(error.message)
    }
}