import { Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import TelaInicialCardapio from './TelaInicialCardapio'
import PaginaMain from './components/Paginas/PaginaMain'
import MainPizzas from './components/Paginas/Produtos/Pizzas/MainPizzas'
import AdicionaisMain from './components/Paginas/Produtos/Adicionais/AdicionaisMain'
import Carrinho from './components/Carrinho/Carrinho'
import LoginPage from "./components/Usuarios/LoginPage/LoginPage"
import PaginaUsuario from './components/Usuarios/UsuariosInfo/PaginaUsuario'
import MeusPedidos from './components/Carrinho/Delivery/MeusPedidos'
import DetalhesPedidoDelivery from './components/Carrinho/Delivery/partes/DetalhesPedidoDelivery'
import PedidosCartaoMesa from './components/Carrinho/PedidosCartaoMesa/PedidoCartaoMesa'
import BuscaProduto from "./components/Paginas/Produtos/BuscaProduto/MainPesquisaProduto";
import RunNotification from './notifications/Runnotification'
import './app.css'
import { useState, useEffect } from "react";
import { retornaDadosConexao } from "./funcoes/Funcoes";

export default function App({ css, toggleTheme, theme }){
  const codigoEmpresa = localStorage.getItem('codigoEmpresa')
  const cardapioAbreviado = localStorage.getItem('cardapio')
  const pagamento = localStorage.getItem('pagamento')
  const [dadosConexao, setDadosConexao] = useState(null);

  useEffect(()=>{
    if(codigoEmpresa){
      handleDadosConexao()
    }
  }, [codigoEmpresa])
  const handleDadosConexao= async ()=>{
    const dados = await retornaDadosConexao(codigoEmpresa)
      setDadosConexao(dados)
  }
  useEffect(()=>{
    if(localStorage.getItem('tipoComanda') === 'DELIVERY'){
      RunNotification()
    }
  }, [])
  
  return(
    <Provider store={store}>
          <Routes>
            <Route path='/' element={<TelaInicialCardapio dadosConexao={dadosConexao} css={css} />}/>
            <Route path='/Main' element={ <PaginaMain dadosConexao={dadosConexao} toggleTheme={toggleTheme} theme={theme} css={css} /> } />
            <Route path='/BuscaProduto' element={<BuscaProduto css={css} theme={theme} dadosConexao={dadosConexao} />}/>
            <Route path='/Adicionais' element={ <AdicionaisMain css={css} dadosConexao={dadosConexao} /> }/>
            <Route path='/Pizzas' element={ <MainPizzas css={css} dadosConexao={dadosConexao} /> }/>
            {
              cardapioAbreviado === 'SIM' ? null : (
                <>
                  <Route path='/Pedidos' element={<PedidosCartaoMesa css={css} dadosConexao={dadosConexao} />}/>
                  {
                    pagamento === 'SIM' ? null : (
                      <>
                        <Route path='/login' element={<LoginPage css={css} />}></Route>
                        <Route path='/Usuario' element={<PaginaUsuario css={css} theme={theme} dadosConexao={dadosConexao} />}/>
                        <Route path='/MeusPedidos' element={<MeusPedidos css={css} dadosConexao={dadosConexao} />}/>
                        <Route path='/DetalhesPedido' element={<DetalhesPedidoDelivery css={css} dadosConexao={dadosConexao} />}></Route>
                        <Route path='/Carrinho' element={<Carrinho css={css} dadosConexao={dadosConexao} />}/>
                      </>
                    ) 
                  }
                </>
              )
            }
          </Routes>
    </Provider>
    )
}



