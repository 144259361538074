import TopoHeaderBar from '../../../header/TopoHeaderBar'
import Footer from '../../../Footer/Footer'
import BuscaProduto from './paginas/BuscaProduto'
import './paginas/buscaproduto.css'
import { useState, useEffect } from 'react'
import BtnsFlutuantes from '../../../BarrasNavegacao/BtnsFlutuantes'

export default function Mainpesquisaproduto({ css, dadosConexao, theme }){
  const [aparelho, setAparelho] = useState('');
  
  useEffect(()=>{
    handleDetectarMobile()
  }, [])
  const handleDetectarMobile=()=> { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
       setAparelho(true)
     }
    else {
       setAparelho(false)
     }
  }

    return(
    <div>
      <BtnsFlutuantes
        css={css}
      />
      <div className='Main' style={{minHeight: '100vh'}}>
        <div>
          <TopoHeaderBar
            css={css}
          />
        </div>
        <div className='margintop'>
            <BuscaProduto
              css={css}
              theme={theme}
              dadosConexao={dadosConexao}
            />
        </div>
      </div>
        <div style={ aparelho === true ? {marginBottom: '50px'} : null}>
          <Footer
            css={css}
          />
        </div>
    </div>
    )
}