import { useState, useEffect } from 'react';
import '../../../Styles/StyleCarrinho.css'
import '../../../Styles/StyleImagens.css'
import { useDispatch, useSelector } from 'react-redux'
import { clearCart, removeItem} from '../../../redux/cartSlice';
import { formCurrency } from '../../../auxiliares/utilidades/FormataBRL';
import Decimal from 'decimal.js';
import { TotalItem } from './TotalItem';
import { capitalizeFirstLetter } from '../../../auxiliares/utilidades/PrimeiraMaiuscula';
import ModalInfoCart from './ModalInfoCart'
import { useTranslation } from 'react-i18next';
import { formCurrencyArgentina } from '../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../auxiliares/utilidades/FormataUSD';

export default function CartItem({ css, dadosConexao }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [totalCart, setTotalCart] = useState('');
  const [modalInfoCart, setmodal] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState('');
  const { t } = useTranslation();
  const moeda = dadosConexao?.moeda

  useEffect(() => {
    if (cart && Array.isArray(cart)) {
      let total = new Decimal(0) || 0;
      cart.forEach(item => {
        total = total.plus(new Decimal(item.quantity || 0).times(item.totalCompra || 0));
      });
      setTotalCart(total.toFixed(2));
    }
  }, [cart]);
  const handleAbrirmodal=(item)=>{
    setItemSelecionado(item)
    setmodal(true)
  }
  
  return (
    <div className='MainCartList'>
      <div className='CartList' style={{ background: `${css?.listaCart}` }}>
        <div className='cartTitulo' style={{ background: `${css?.bgTituloCart}`, color: `${css?.colorTituloCart}` }}> 
          {
           <img src={'data:image/png;base64,' + css?.iconCart} className='iconeCarrinhoCart'/>
          }
          <div className='carrinhoName'> {capitalizeFirstLetter(t('carrinho'))} </div>
        </div>
        <div className='cartListItems'>
          {Array.isArray(cart) ? (
              cart.map((item, index)=>
              <div className='cardCart' style={{ background: `${css?.bgCardCart}`, color: `${css?.colorCardCart}` }} key={index} >
                <div className='cartBoxIconeLixeira'>
                  <img src={'data:image/png;base64,' + css?.iconeLixeira} onClick={()=> dispatch(removeItem(item.id))} className='iconeLixeira'/>
                </div>
                  <div className='boxDescricao-nome' onClick={()=> handleAbrirmodal(item)}> {item.tipo === "NAO" || item.tipo === "NÃO" ? (<div> {capitalizeFirstLetter(item.produto.PRODUTO.toLowerCase())} </div>) : (<div> {t('pizza')} {item.produto.TAMANHO.toLowerCase()} </div>) } </div>
                <div className='cartBox-valor'>
                  <TotalItem
                    itemquantity={item.quantity}
                    itemid={item.id}
                    itemvalor={item.totalCompra}
                    css={css}
                    dadosConexao={dadosConexao}
                  />
                </div>
              </div>
              )
          ) : null}
        </div>
          <div className='barraTotalCartList' style={{ background: `${css?.bgTotalCart}`, color: `${css?.colorTotalCart}` }}>
            <div className='cartBoxIconeLixeira'>
              <img src={'data:image/png;base64,' + css?.iconeLixeira} onClick={()=> dispatch(clearCart())} className='iconeLixeira'/>
            </div>
            <div className='totalCartList'>
              <div> {t('total').toUpperCase()} {
                      moeda === 'BRL' ? formCurrency.format(totalCart) :
                      moeda === 'ARS' ? formCurrencyArgentina.format(totalCart) : 
                      moeda === 'USD'   ? formCurrencyUSD.format(totalCart) : null
                    }
            </div>
            </div>
          </div>
      </div>
      {modalInfoCart && <ModalInfoCart item={itemSelecionado} setmodal={setmodal} css={css}/>}
    </div>
  )
}

