import '../../../Styles/StylePaginaUsuario.css'
import Footer from '../../Footer/Footer'
import CorpoUsuario from './partesUser/CorpoUser'
import BtnsFlutuantes from '../../BarrasNavegacao/BtnsFlutuantes'

export default function PaginaUsuario({ css, theme, dadosConexao }){
    
    return(
        <div className='paginaUser'>
            <BtnsFlutuantes
                css={css}
            />
            <div className='principalUser'>
                <div>
                    <CorpoUsuario
                        css={css}
                        theme={theme}
                        dadosConexao={dadosConexao}
                    />
                </div>
            </div>
            <div>
                <Footer 
                    css={css}
                />
            </div>
        </div>
    )
}