import { useState, useEffect } from 'react'
import { auth } from '../../../Usuarios/LoginPage/Firebase/firebaseConfig';
import '../meuspedidos.css'
import { api } from '../../../../conecções/api';
import { formCurrency } from '../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../auxiliares/utilidades/FormataUSD';
import { useNavigate } from 'react-router-dom';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import '../../../../Styles/StyleTerminal.css'

export default function CorpoPedidosDelivery({ css, dadosConexao }){
    const [usuario, setUsuario] = useState('');
    const [user, setUser] = useState('');
    const [listaPedidos, setListaPedidos] = useState([]);
    const [filtroNovos, setFiltroNovos] = useState(true);
    const [filtroPreparo, setFiltroPreparo] = useState(true);
    const [filtroTransporte, setFiltroTransporte] = useState(true);
    const [filtroFinalizados, setFiltroFinalizados] = useState(false);
    const [filtroCancelado, setFiltroCancelado] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda

    useEffect(()=>{
        auth.onAuthStateChanged((user) => {
            setUser(user)
            const uidToken = user.uid
                if(uidToken !== undefined) {
                    handlePegarDadosUser(uidToken)
                }
            handleListaPedidos(uidToken)
        });
    }, []);
    const handlePegarDadosUser=(uidToken)=>{
        api
            .get(`/pegarDadosCliente/${uidToken}`)
            .then((getdata)=>{
                setUsuario(getdata.data)
            })
            .catch((error) => {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const handleListaPedidos=(uidToken)=>{
        const ROTAlistaPedidosCliente = `http://${dadosConexao.api}:${dadosConexao.porta}/listaPedidosCliente/${uidToken}`
        const dados_conexao2 = encodeURIComponent(ROTAlistaPedidosCliente)
            api
                .post(`/requisicaoDados/${dados_conexao2}`)
                .then((getdata)=>{
                    setListaPedidos(getdata.data);
                })
                .catch((error) => {
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const filteredPedidos = Array.isArray(listaPedidos) ? listaPedidos.filter((itemPedido) => {
        if (filtroNovos && itemPedido.STATUS === 1) {
            return true;
        }
        if (filtroPreparo && itemPedido.STATUS === 3) {
            return true;
        }
        if (filtroTransporte && itemPedido.STATUS === 4) {
            return true;
        }
        if (filtroFinalizados && itemPedido.STATUS === 5) {
            return true;
        }
        if (filtroCancelado && itemPedido.STATUS === 6) {
            return true;
        }
        return false;
    } ) : (
        null
    )
    const selecionarPedido = (itemPedido, usuario) => {
        navigate('/DetalhesPedido', { state: { itemPedido, usuario} });
    };

    return(
        <div>
            <div className='caixaUser'>
                <div className='userName' style={{ background: `${css?.bgUserName}`, color: `${css?.colorUserName}` }} > {Array.isArray(usuario) ? (usuario.map((item) => item.nome)) : null} </div>
            </div>
        <div className='ListaPedidos'>
            <div className='barraAtalhoTerminal' style={{ background: `${css?.bgBarraAtalho}`, color: `${css?.colorBarraAtalho}` }} >
                <div className='caixaAtalhoTerminal'>
                    <div className='caixaparaolho'>
                            <label className="containerparacheckmark">
                                <input type="checkbox" checked={filtroNovos} onChange={() => setFiltroNovos(!filtroNovos)}/>
                                    <svg className="eye" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path></svg>
                                    <svg className="eye-slash" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>
                            </label>
                    </div>
                    <div className='atalhoTerminalNome'> {t('novos')} </div>
                </div>

                <div className='caixaAtalhoTerminal'>
                  <div className='caixaparaolho'>
                            <label className="containerparacheckmark">
                                <input type='checkbox' checked={filtroPreparo} onChange={() => setFiltroPreparo(!filtroPreparo)} />
                                    <svg className="eye" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path></svg>
                                    <svg className="eye-slash" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>
                            </label>
                  </div>
                  <div className='atalhoTerminalNome'> {t('preparo')} </div>
                </div>
                <div className='caixaAtalhoTerminal'>
                    <div className='caixaparaolho'>
                            <label className="containerparacheckmark">
                                <input type='checkbox' checked={filtroTransporte} onChange={() => setFiltroTransporte(!filtroTransporte)} />
                                    <svg className="eye" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path></svg>
                                    <svg className="eye-slash" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>
                            </label>
                    </div>
                    <div className='atalhoTerminalNome'> {t('transporte')} </div>
                </div>
                <div className='caixaAtalhoTerminal'>
                    <div className='caixaparaolho'>
                            <label className="containerparacheckmark">
                                <input type='checkbox' checked={filtroCancelado} onChange={() => setFiltroCancelado(!filtroCancelado)} />
                                    <svg className="eye" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path></svg>
                                    <svg className="eye-slash" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>
                            </label>
                    </div>
                    <div className='atalhoTerminalNome'> {t('cancelados')} </div>
                </div>
                <div className='caixaAtalhoTerminal'>
                    <div className='caixaparaolho'>
                        <label className="containerparacheckmark">
                            <input type='checkbox' checked={filtroFinalizados} onChange={() => setFiltroFinalizados(!filtroFinalizados)} /> 
                                <svg className="eye" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path></svg>
                                <svg className="eye-slash" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>
                        </label>
                    </div>
                    <div className='atalhoTerminalNome'> {t('finalizados')} </div>
                </div>
            </div>
            <div>
            {Array.isArray(filteredPedidos) ? (
                filteredPedidos.map((itemPedido, index) => (
                    <div 
                        style={{ background: `${css?.bgCardPedidoUser}`, color: `${css?.colorCardPedidoUser}` }}
                        key={index} 
                        className='listaPedidoCard' 
                        onClick={() => selecionarPedido(itemPedido, usuario)}
                    >
                        <div>
                        </div>
                        <div className='pedidoCard-linha'>
                            <div className='linhaEsquerda'>{itemPedido.TIPOCOMANDA} {t('n')} {itemPedido.ID}</div>
                            <div className='linhaDireita'>{itemPedido.HORA.split(':').slice(0, 2).join(':')}</div>
                        </div>
                        <div className='pedidoCard-linha linhaBaixo'>
                            <div className='linhaEsquerda'>
                                {
                                    moeda === 'BRL' ? formCurrency.format(itemPedido.TOTAL) :
                                    moeda === 'ARS' ? formCurrencyArgentina.format(itemPedido.TOTAL) : 
                                    moeda === 'USD' ? formCurrencyUSD.format(itemPedido.TOTAL) : null
                                }
                            </div>
                        <div 
                            style={{ background: `${itemPedido.STATUS === 1 ? css?.bgNovo
                                                : itemPedido.STATUS === 2 ? css?.bgAceito
                                                : itemPedido.STATUS === 3 ? css?.bgPreparo
                                                : itemPedido.STATUS === 4 ? css?.bgTransporte
                                                : itemPedido.STATUS === 5 ? css?.bgFinalizado
                                                : itemPedido.STATUS === 6 ? css?.bgCancelado
                                                : ''}`,
                                    color: `${itemPedido.STATUS === 1 ? css?.colorNovo
                                                : itemPedido.STATUS === 2 ? css?.colorAceito
                                                : itemPedido.STATUS === 3 ? css?.colorPreparo
                                                : itemPedido.STATUS === 4 ? css?.colorTransporte
                                                : itemPedido.STATUS === 5 ? css?.colorFinalizado
                                                : itemPedido.STATUS === 6 ? css?.colorCancelado
                                                : ''}`
                                    }}
                            className={
                            'statusPedidos ' +
                            (itemPedido.STATUS === 1 ? 'novo'
                                : itemPedido.STATUS === 2 ? 'aceito'
                                : itemPedido.STATUS === 3 ? 'preparo'
                                : itemPedido.STATUS === 4 ? 'transporte'
                                : itemPedido.STATUS === 5 ? 'finalizados'
                                : itemPedido.STATUS === 6 ? 'cancelado'
                                : '')
                            }>
                                {itemPedido.STATUS === 1 ? t('novo')
                                    : itemPedido.STATUS === 2 ? t('aceito')
                                    : itemPedido.STATUS === 3 ? t('preparo')
                                    : itemPedido.STATUS === 4 ? t('transporte')
                                    : itemPedido.STATUS === 5 ? t('finalizados')
                                    : itemPedido.STATUS === 6 ? t('cancelado')
                                    : ''
                                }
                        </div>
                        </div>
                    </div>
            ))
            ) : null}
            </div>
        </div>
        </div>
    )
}