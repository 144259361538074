import { useState, useEffect } from 'react'
import '../../../Styles/StyleLogin.css'
import '../../../Styles/StyleImagens.css'
import { pegarDadosEmpresa } from '../../../funcoes/Funcoes';

export default function cartHeader(css){
    const [infoClientes, setInfoClientes] = useState([]);
    
    useEffect(()=>{
        handleDadosEmpresa();
    }, [])
    const handleDadosEmpresa= async()=>{
        const dados = await pegarDadosEmpresa(localStorage.getItem('codigoEmpresa'))
            setInfoClientes(dados)
    }
    
    return(
        <div className='loginHeader'>
            <div className='loginHeaderTopo'>
                <div className=''></div>
                <div className='login-logo-garline-header'>
                    <div className='loginLogoGarline'></div>
                </div>
            </div>
            <div className='loginHeaderBody'>
                <img src={'data:image/png;base64,' + css?.banner}/>
            </div>
            <div className='login-logo-resta'>
                {Array.isArray(infoClientes) ?  (
                    infoClientes.map((item, index)=> 
                    <div key={index}>
                        <img src={'data:image/png;base64,' + item.FOTO} key={item.id} alt='Restaurante' className='img-restaurante-logo' />
                    </div>
                )) : null}                    
            </div>
        </div>
    )
}