import { useState, useEffect } from 'react'
import '../../../../Styles/StylePaginaUsuario.css'
import '../../../../Styles/Styles.css'
import { api } from '../../../../conecções/api';
import { signOut } from 'firebase/auth';
import { auth } from '../../LoginPage/Firebase/firebaseConfig';
import ModalUser from './ModalUser'
import firebase from 'firebase/compat/app';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';

export default function DadosUsuarios({ user, css, dadosConexao }){
    const [isOpenUserDados, setIsOpenUserDados] = useState(false);
    const [dadosCliente, setDadosCliente] = useState([]);
    const [item, setItem] = useState('');
    const [atualizarDadosCliente, setAtualizarDadosCliente] = useState(false);
    const {t} = useTranslation();

    const firebaseConfig = {
        apiKey: "AIzaSyCYWFJQHCOX0lGYXbMIffgwSpbee-rsBp8",
        authDomain: "cardapioweb-4190a.firebaseapp.com",
        projectId: "cardapioweb-4190a",
        storageBucket: "cardapioweb-4190a.appspot.com",
        messagingSenderId: "296774148999",
        appId: "1:296774148999:web:add883b730aa7327822922",
        measurementId: "G-46HFSFBDCE"
    };
    firebase.initializeApp(firebaseConfig);
    useEffect(() => {
        const handleSignInRedirect = async () => {
            try {
                const result = await firebase.auth().getRedirectResult();
                const user = result.user; 
                const ROTAinserirCliente = `http://${dadosConexao.api}:${dadosConexao.porta}/insereCliente`;
                const dados_conexao = encodeURIComponent(ROTAinserirCliente);
                if (user !== null) {
                    api
                      .post(`/inserirCliente/${dados_conexao}`, {
                        nome: user.displayName,
                        data_nascimento: '2023/08/24', 
                        email: user.email,
                        firebase_token: user.uid,
                        numero_telefone: user.phoneNumber
                      })
                      .then((response) => {
                        localStorage.setItem('idCliente', response.data);
                        localStorage.setItem('uidToken', user.uid);
                      })
                      .catch((error) => {
                        const mensagem = 'Erro 510'
                        const cor = 'vermelho'
                        JanelaMensagem(mensagem, cor)
                      });
                }
            } catch (error) {
                const mensagem = 'Erro 511'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            }
        };
        handleSignInRedirect();
    }, []);
    useEffect(()=>{
        const uidToken = user.uid
        if(uidToken !== undefined) {
            api
                .get(`/pegarDadosCliente/${uidToken}`)
                .then((getdata)=>{
                    setDadosCliente(getdata.data)
                })
                .catch((error) => {
                    const mensagem = 'Erro 512'
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
        }
    }, [user]);
    if(atualizarDadosCliente === true){
        const uidToken = user.uid
        api
            .get(`/pegarDadosCliente/${uidToken}`)
            .then((getdata)=>{
                setDadosCliente(getdata.data)
            })
            .catch((error) => {
                const mensagem = 'Erro 513'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
        setAtualizarDadosCliente(false)    
    }
    const EditarDados=(item)=>{
        setItem(item)
        setIsOpenUserDados(true)
    }
    const deslogar =()=>{
        signOut(auth)
            .then(() => {
            })
            .catch((error) => {
            });
    }
    function formataData(data) {
        const dataObj = new Date(`${data}`);
        const dia = dataObj.getUTCDate().toString().padStart(2, '0');
        const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
        const ano = dataObj.getUTCFullYear();
        return `${dia}/${mes}/${ano}`;
    }
    
    return(
        <div className='infos'>
                <div className='usuarioInfos' style={{ background: `${css?.bgNomeUser}`, color: `${css?.colorNomeUser}` }}>
                    <img src={user.photoURL} className='fotoPerfil' />
                    <div className='caixaiconeLogout'>
                        <img src={'data:image/png;base64,' + css?.iconeLogout} className='iconeLogout' onClick={()=> deslogar()}/>
                    </div>
                    <div className='itemInfos'  > {Array.isArray(dadosCliente) ? dadosCliente.map((item) => item.nome) : null}
                </div>
                </div>
                {Array.isArray(dadosCliente) ?
                    dadosCliente.map((item, index)=>
                        <div key={index} className='listaInfos' style={{ background: `${css?.bgInfosUser}`, color: `${css?.colorInfosUser}` }}>
                            <div className='linhaEditar'>
                                <div>
                                    <button className="Btn" onClick={()=> EditarDados(item)} style={{ background: `${css?.bgBtnEditInfoUser}`, color: `${css?.colorBtnEditInfoUser}` }} >
                                        <div className="sign">
                                            <img src={'data:image/png;base64,' + css?.iconeEditar} className='iconeEditar'/>
                                        </div>
                                        <div className="text"> {capitalizeFirstLetter(t('editar'))} </div>
                                    </button>
                                </div> 
                            </div>
                            <div className='caixaUsuario'>
                                <div> {capitalizeFirstLetter(t('email'))} </div>
                                <div> {item.email} </div>
                            </div>
                            <div className='caixaUsuario'>
                                <div> {capitalizeFirstLetter(t('celular'))} </div>
                                <div> {item.numero_telefone !== null ? item.numero_telefone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1') : null} </div>
                            </div>
                            <div className='caixaUsuario'>
                                <div> {t('dataNascimento')} </div>
                                <div> {formataData(item.data_nascimento)} </div>
                            </div>
                        </div>
                    )
                : null}
                <div>
                    {isOpenUserDados && <ModalUser dadosConexao={dadosConexao} user={user} item={item} setIsOpenUserDados={setIsOpenUserDados} setAtualizarDadosCliente={setAtualizarDadosCliente} css={css} />}
                </div>
        </div>
    )
}