import { useState, useEffect } from 'react';
import '../../Styles/StyleCarrinhoBarPagamento.css';
import '../../Styles/StyleImagens.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function BarraCarrinhoAtalho({ css }) {
  const [mainAtivo, setMainAtivo] = useState(false);
  const [carrinhoAtivo, setCarrinhoAtivo] = useState(false);
  const [cardapioAbreviado, setCardapioAbreviado] = useState('');
  const [usuarioAtivo, setUsuarioAtivo] = useState(false);
  const location = useLocation();
  const {t} = useTranslation();

  useEffect(()=>{
      const carrinhoAbreviado = localStorage.getItem('cardapioAbreviado');
        setCardapioAbreviado(carrinhoAbreviado)
        const path = location.pathname;
        const nome = path.substring(path.lastIndexOf('/') + 1);
          if(nome === "Main"){
            setMainAtivo(true)
          }
          if(nome === "Usuario"){
            setUsuarioAtivo(true)
          }
          if(nome === "Carrinho"){
            setCarrinhoAtivo(true)
          }
  }, [location.pathname])

  return (
    <>
    {cardapioAbreviado === "SIM" ? (null) : (
      <div className='atalhoCarrinho' style={{ background: `${css?.bgBarraAtalho}`, color: `${css?.colorBarraAtalho}` }}>
        <div className='mainPage'>
          <Link to='/Main' style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <div className='centralizarIcone'>
              <img src={'data:image/png;base64,' + (mainAtivo === false ? css?.iconeHomePageApagado : css?.iconeHomePageAcesso) } className='iconeBarraAtalho'/>
            </div>
            <div className='stiloParabarra'> {t('menu')} </div>
          </Link>
        </div>
        <div className='usuarioPage'>
          <Link to='/Usuario' style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <div className='centralizarIcone'>
              <img src={'data:image/png;base64,' + (usuarioAtivo === false ? css?.iconeUserApagado : css?.iconeUserAcesso )} className='iconeBarraAtalho'/>
            </div>
            <div className='stiloParabarra'> {t('usuario')} </div>
          </Link>
        </div>
        <div className='cartPage'>
          <Link to='/Carrinho' style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <div className='centralizarIcone'>
              <img src={'data:image/png;base64,' + (carrinhoAtivo === false ? css?.iconeCartApagado : css?.iconeCartAcesso)} className='iconeBarraAtalho'/>
            </div>
            <div className='stiloParabarra'> {t('carrinho')} </div>
          </Link>
        </div>
      </div>
      )}
    </>
  );
}
