import firebase from 'firebase/compat/app';
import 'firebase/auth';
import './loginapple.css';
import { useTranslation } from 'react-i18next';

export default function LoginApple() {
    const {t} = useTranslation();

    const firebaseConfig = {
        apiKey: "AIzaSyCYWFJQHCOX0lGYXbMIffgwSpbee-rsBp8",
        authDomain: "cardapioweb-4190a.firebaseapp.com",
        projectId: "cardapioweb-4190a",
        storageBucket: "cardapioweb-4190a.appspot.com",
        messagingSenderId: "296774148999",
        appId: "1:296774148999:web:add883b730aa7327822922",
        measurementId: "G-46HFSFBDCE"
    };
    
    firebase.initializeApp(firebaseConfig);

    const signInWithApple = () => {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        firebase.auth().signInWithRedirect(provider); 
    }
    
    
    return (
        <div className='centrarapple'>
            <button onClick={signInWithApple} className='apple-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button' style={{backgroundColor: '#000'}} >
                <span className='firebaseui-idp-icon-wrapper'>
                    <svg className='firebaseui-idp-icon' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 45 45">
                        <linearGradient id="hlWX3vESs0eSKhHV_ppbBa_bCu25JrJ39EB_gr1" x1=".081" x2="50.487" y1="2.176" y2="52.582" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#ffffff" stopOpacity="1"></stop><stop offset="1" stopColor="#ffffff" stopOpacity="1"></stop></linearGradient><path fill="url(#hlWX3vESs0eSKhHV_ppbBa_bCu25JrJ39EB_gr1)" d="M42.906,33.283c-1.034,2.289-1.529,3.313-2.857,5.336c-1.855,2.825-4.475,6.351-7.714,6.375	c-2.881,0.024-3.624-1.88-7.534-1.848c-3.91,0.019-4.724,1.883-7.608,1.854c-3.239-0.029-5.718-3.206-7.575-6.026	c-5.193-7.911-5.741-17.182-2.531-22.116c2.27-3.498,5.864-5.552,9.237-5.552c3.436,0,5.595,1.888,8.436,1.888	c2.756,0,4.435-1.893,8.409-1.893c3.005,0,6.183,1.637,8.454,4.463C34.195,19.834,35.401,30.444,42.906,33.283z"></path><linearGradient id="hlWX3vESs0eSKhHV_ppbBb_bCu25JrJ39EB_gr2" x1="17.19" x2="36.29" y1="-4.022" y2="15.078" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#ffffff" stopOpacity="1"></stop><stop offset="1" stopColor="#ffffff" stopOpacity="1"></stop></linearGradient><path fill="url(#hlWX3vESs0eSKhHV_ppbBb_bCu25JrJ39EB_gr2)" d="M29.801,8.127c1.428-1.833,2.511-4.418,2.118-7.055c-2.33,0.159-5.054,1.646-6.645,3.573	c-1.441,1.754-2.637,4.36-2.171,6.881C25.644,11.607,28.274,10.093,29.801,8.127z"></path>
                    </svg>
                </span>
                    <span className='firebaseui-idp-text firebaseui-idp-text-long' style={{color: '#ffffff'}}> {t('entrarApple')} </span>
                    <span className='firebaseui-idp-text firebaseui-idp-text-short'></span>
            </button>
        </div>
    );
}
