import { useState, useEffect } from 'react'
import '../../../../Styles/StylePaginaUsuario.css'
import '../../../../Styles/StyleImagens.css'
import { auth } from '../../LoginPage/Firebase/firebaseConfig';
import HeaderSimplificado from '../../../header/HeaderSimplificado';
import Endereços from './Endereços'
import LoginGoogle from '../../LoginPage/LoginMetodos/LoginGoogle'
import LoginApple from '../../LoginPage/LoginMetodos/LoginApple'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../../Tradutor'
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula'
import DadosUser from './DadosUser'

export default function Infos({ css, theme, dadosConexao }){
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const tipoComanda = localStorage.getItem('tipoComanda')
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem("i18nextLng");
        if (savedLanguage && savedLanguage !== i18n.language) {
          i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);
    const handleAlterarIdioma = (event) => {
            const selectedLanguage = event;
            i18n.changeLanguage(selectedLanguage);
            localStorage.setItem("i18nextLng", selectedLanguage);
    };
    useEffect(()=>{
        auth.onAuthStateChanged((user) => {
            setUser(user)
        });
    }, []);
    const MeusPedidos = ()=>{
        navigate('/MeusPedidos')
    }
    const Comanda =()=>{
        navigate('/Pedidos')
    }
    return(
        <>
            <div>
                <HeaderSimplificado
                    css={css}
                />
            </div>
            <div>
                {
                    user === null || user === "" ? (
                        <div className='marginLogin'>
                            <LoginGoogle/>
                            <LoginApple/>
                        </div>
                    ) : (
                        <DadosUser
                            user={user}
                            css={css}
                            dadosConexao={dadosConexao}
                        />
                    )
                }
            </div>
            <div>
                {user === null || user === "" ? (null) : (
                    <Endereços
                        user={user}
                        css={css}
                        theme={theme}
                        dadosConexao={dadosConexao}
                    />
                )}
            </div>
            <div>
                {user === null || user === "" ? null : (
                    <div className='caixabtnnotificacao'>
                        <button className='btnnotificacao btn' style={{ background: `${css?.bgBtnPedidos}`, color: `${css?.colorBtnPedidos}` }} onClick={()=> MeusPedidos(tipoComanda)}>
                            <div className='btnnotificacaotext'><div className='textbtnnotificacao'> {t('historicoPedidos')} </div></div>
                            <div className='btnnotificacaocaixaicone'>
                            <div className='caixaIconePedidos'>
                                <img src={'data:image/png;base64,' + css?.iconePedidos} className='iconePedidos'/>
                            </div>
                            </div>
                        </button>
                    </div>
                )}
            </div>
            <div>
                {tipoComanda === 'MESA' || tipoComanda === 'CARTAO' ? (
                    <div className='caixabtnnotificacao' >
                        <button className='btnnotificacao btn'  style={{ background: `${css?.bgBtnPedidos}`, color: `${css?.colorBtnPedidos}` }} onClick={()=> Comanda(tipoComanda)}>
                            <div className='btnnotificacaotext'><div className='textbtnnotificacao'> {capitalizeFirstLetter(t('comanda'))} </div></div>
                            <div className='btnnotificacaocaixaicone'>
                                <div className='caixaIconePedidos'>
                                    <img src={'data:image/png;base64,' + css?.iconePedidos} className='iconePedidos'/>
                                </div>
                            </div>
                        </button>
                    </div>
                ) : null} 
            </div>
            <div>
                <div className='caixabtnnotificacao'  >
                    <select 
                        style={{ background: `${css?.bgButtonLinguagem}`, color: `${css?.colorButtonLinguagem}` }}
                        className='btnnotificacao' 
                        defaultValue={`${i18n.language}`} onChange={(event) => handleAlterarIdioma(event.target.value)}
                    >
                        <option key={0} value={'pt-BR'}> {capitalizeFirstLetter(t('português'))} </option>
                        <option key={1} value={'es'}> {capitalizeFirstLetter(t('espanhõl'))} </option>
                        <option key={2} value={'en-US'}> {capitalizeFirstLetter(t('ingles'))} </option>
                    </select>
                </div>
            </div>
        </>
    )
}