import { useState, useEffect } from 'react'
import '../../Styles/Styles.css'
import { CarrinhoBarPagamento } from './CarrinhoFunções/CarrinhoBarPagamento'
import HeaderSimplificado from '../header/HeaderSimplificado'
import CartItem from './CarrinhoFunções/CartItems'
import Observacoes from './CarrinhoFunções/Observacoes'
import Localizacao from './CarrinhoFunções/Localização/Localizacao'
import Footer from '../Footer/Footer'
import BtnsFlutuantes from '../BarrasNavegacao/BtnsFlutuantes'
import FormasDePagamento from './CarrinhoFunções/FormasDePagamento/FormasDePagamento'
import { auth } from '../Usuarios/LoginPage/Firebase/firebaseConfig'
import EnderecoCart from './CarrinhoFunções/EndereçoCarrinho/EnderecoCart'

export default function CarrinhoMain({ css, dadosConexao }){
  const [observacoesCart, setObservacaoCart] = useState('');
  const tipoComanda = localStorage.getItem('tipoComanda');
  const [mesaSelecionada, setMesaSelecionada] = useState(null);
  const [pagamentoSelecionado, setPagamentoSelecionado] = useState('');
  const [enderecoSelecionado, setEnderecoSelecionado] = useState(null);
  const [taxaEntrega, setTaxaEntrega] = useState(0);
  const [user, setUser] = useState('');

  useEffect(()=>{
    auth.onAuthStateChanged((user) => {
      setUser(user)
    });
  }, [])
  
return(
  <>
    <BtnsFlutuantes
      css={css}
    />
      <div className='pagina' style={{ background: `${css?.bgCart}` }}>
        <div className='Main'>
          <div>
            <HeaderSimplificado
              css={css}
            />
          </div>
          <div>
            <CartItem
              css={css}
              dadosConexao={dadosConexao}
            />
          </div>
          <div>
              <Observacoes
                setObservacaoCart={setObservacaoCart}
                css={css}
              />
          </div>
          <div>
            <Localizacao
              tipoComanda={tipoComanda}
              setMesaSelecionada={setMesaSelecionada}
              css={css}
              dadosConexao={dadosConexao}
            />
          </div>
          {tipoComanda === "DELIVERY" ? (
            <div>   
              <FormasDePagamento
                setPagamentoSelecionado={setPagamentoSelecionado}
                css={css}
                dadosConexao={dadosConexao}
              /> 
            </div>
          ) : null }
          {tipoComanda === "DELIVERY" ? (
            <>
              {user !== null ?(
                <div>
                  <EnderecoCart 
                    user={user} 
                    setEnderecoSelecionado={setEnderecoSelecionado} 
                    setTaxaEntrega={setTaxaEntrega} 
                    css={css}
                    dadosConexao={dadosConexao}
                  />
                </div>      
              ) : null}
            </>
          ) : null}
          <div>
            <CarrinhoBarPagamento
              user={user} 
              tipoComanda={tipoComanda}
              observacoesCart={observacoesCart}
              mesaSelecionada={mesaSelecionada}
              pagamentoSelecionado={pagamentoSelecionado}
              enderecoSelecionado={enderecoSelecionado}
              taxaEntrega={taxaEntrega}
              css={css}
              dadosConexao={dadosConexao}
            />
          </div>
        </div>
        <div>
          <Footer
            css={css}
          />
        </div>
      </div>
  </>
  )
}

