import { useState, useEffect } from 'react';
import '../modalRecargaCartao/modalrecargacartao.css'
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { GerarPixPagarConta, consultarStatusPix, cancelarPixPagarMesa } from '../../../../auxiliares/metodosPagamento/Pix'
import PixCopiaECola from '../../../../auxiliares/metodosPagamento/Pix'
import { useTranslation } from 'react-i18next';

export default function modalPagarConta({ setModalPagarConta, dadosConexao, setAtualizarConta, setAtualizarCredito, totalPedido, css }){
    const [dadosPix, setDadospix] = useState('');
    const [status, setStatus] = useState('');
    const [pixCopiaECola, setPixCopiaECola] = useState('')
    const [pix, setPix] = useState('');
    const [pixGerado, setPixGerado] = useState(false);
    const rota = `http://${dadosConexao.api}:${dadosConexao.porta}/pagarConta`
    const numeroComanda = localStorage.getItem('numeroComanda')
    const tipoComanda = localStorage.getItem('tipoComanda')
    const total = totalPedido.toFixed(2)
    const {t} = useTranslation();
    
    useEffect(()=>{
        if(dadosConexao){
            handleDadosPix()
        }
    }, [dadosConexao])
    const handleDadosPix=()=>{
        const ROTAsubGrupo = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosPIX`
        const dados_conexao = encodeURIComponent(ROTAsubGrupo)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata) => {
                setDadospix(getdata.data[0])
            })
            .catch((error) => {
                const mensagem = 'Erro 563'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    useEffect(()=>{
        if(dadosPix){
            handleGerarPix()
        }
    }, [dadosPix])
    const handleGerarPix=()=>{
        GerarPixPagarConta(dadosPix, total, rota, tipoComanda, numeroComanda, setPix, setPixCopiaECola, setPixGerado)
    }
    useEffect(()=>{
        if(pixGerado === true){
            const intervalId = setInterval(() => {
                handleVerificarStatus()
              }, 2000); 
            return () => clearInterval(intervalId);
        }
    }, [pixGerado])
    const handleVerificarStatus=()=>{
        consultarStatusPix(dadosPix, pix.txid, setStatus)
    }
    useEffect(()=>{
        if(status === 'CONCLUIDA'){
            setPixGerado(false)
            handleFechar()
            const mensagem = t('contaPaga')
            const cor = 'verde'
            JanelaMensagem(mensagem, cor)
        }
    }, [status])
    const handleFechar=()=>{
        setModalPagarConta(false)
        setAtualizarConta(true)
        setAtualizarCredito(true)
    }
    const handleCancelarPix=()=>{
        cancelarPixPagarMesa(dadosPix, pix.txid, setModalPagarConta)
    }
    return(
    <>
        <div className='darkBG'/>
            <div className='centered' style={{ width: '90%', maxWidth: '400px' }}>
            <div className='modalPedidos' style={{ background: `${css?.bgModalPagamentoPix}` , color: `${css?.colorModalPagamentoPix}` }}>
            <div className='textoPedidoCartModal'>
            <div className='textoloadpix'> {t('aguardandoPagamento')} </div>
                <PixCopiaECola
                    pixCopiaECola={pixCopiaECola}
                    css={css}
                />
            </div>
            <div>
                <button className='btn btnCancelar' style={{ background: `${css?.bgBtnCancelar}`, color: `${css?.colorBtnCancelar}` }}  onClick={() => handleCancelarPix(false)}> {t('cancelar')} </button>
            </div>
            </div>
        </div>
    </>
  );
};