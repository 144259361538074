import { useState, useEffect } from 'react'
import '../../../../Styles/Styles.css'
import '../../../../Styles/StyleImagens.css'
import { api } from '../../../../conecções/api';
import MenuBar from '../../../navbar/menubar';
import SubGrupoList from './SubGrupoList';
import { useNavigate } from 'react-router-dom';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { pegarDadosEmpresa } from '../../../../funcoes/Funcoes';

export default function Grupo({ css, dadosConexao, theme }){
    const [grupos, setGrupos] = useState([]);
    const [novosgrupos, setNovosGrupos] = useState([]);
    const [tamanhosPizza, setTamanhosPizza] = useState([]);
    const [listaTamanhosAtivos, setListaTamanhosAtivos] = useState(null);
    const [infoClientes, setInfoClientes] = useState('');
    const tipoComanda = localStorage.getItem('tipoComanda')
    const IdTamanho ="1"
    const navigate = useNavigate();

    useEffect(() => {
        if(dadosConexao){
            handleEmpresa()
        }
    }, [dadosConexao]);
    useEffect(()=>{
        if(tamanhosPizza !== -1){
            setNovosGrupos(grupos)
        } else {
            setNovosGrupos(grupos.filter((item) => item.PIZZA_MISTA === 'NAO'))
        }
    }, [tamanhosPizza, grupos])
    const handleEmpresa= async()=>{
        const result = await pegarDadosEmpresa(localStorage.getItem('codigoEmpresa'))
            setInfoClientes(result);
                const dados = result;
            handleListaGrupos(dados)
            handleTamanhoPizza()            
            
    }
    const handleListaGrupos=(dados)=>{
        const ROTAgrupoList = `http://${dadosConexao.api}:${dadosConexao.porta}/listaGrupos/${tipoComanda}/${dados.ID_CARDAPIO}`
        const dados_conexao = encodeURIComponent(ROTAgrupoList)
            api
                .post(`/requisicaoDados/${dados_conexao}`)        
                .then((getdata) => {
                    if (Array.isArray(getdata.data)) {
                        const sortedData = getdata.data.sort((a, b) => a.numeration - b.numeration);
                        setGrupos(sortedData);
                    }
                })
                .catch((error) => {
                    const mensagem = 'Erro 537'
                    const cor = 'amarelo'
                    JanelaMensagem(mensagem, cor)
                });
    }   
    const handleTamanhoPizza=()=>{
        const ROTAlistaTamanhosPizza = `http://${dadosConexao.api}:${dadosConexao.porta}/listaTamanhosPizza/${tipoComanda}`
        const requicaoListaTamanhoPizza = encodeURIComponent(ROTAlistaTamanhosPizza)
            api
                .post(`/requisicaoDados/${requicaoListaTamanhoPizza}`)         
                    .then((getdata)=>{
                        setTamanhosPizza(getdata.data);
                })
                .catch((error) => {
                    const mensagem = 'Erro 538'
                    const cor = 'amarelo'
                    JanelaMensagem(mensagem, cor)
                });
    } 
    const handlePizzas=(data, PIZZA_MISTA)=>{
        navigate('/Pizzas', { state: { data, PIZZA_MISTA } });
    }
    const toggleListaTamanhos = (IdGrupo) => {
        if (listaTamanhosAtivos === IdGrupo) {
            setListaTamanhosAtivos(null);
        } else {
            setListaTamanhosAtivos(IdGrupo);
        }
    } 
    return(
    <div>
        <div>
            <MenuBar
                grupos={novosgrupos}
                css={css}
            />
        </div>
        <div className='Main-Cardapio'>
            {Array.isArray(novosgrupos) ? (
                novosgrupos.map((item, index)=>
                    <div className='GrupoList' id={item.ID_GRUPO} key={index}>
                       <div className='grupoTitulo' style={{ background: `${css?.bgGrupoTituloMain}`, color: `${css?.colorGrupoTituloMain}` }} onClick={() => {if (item.PIZZA_MISTA === "SIM") {toggleListaTamanhos(IdTamanho);}}} > {item.GRUPO}
                            {item.PIZZA_MISTA === "SIM" ? (
                                <div className='iconGrupoTamanho'>
                                    {listaTamanhosAtivos === IdTamanho ? 
                                        <img src={'data:image/png;base64,' + css?.iconeParaCima} className='iconeSetaGrupo'/> :
                                        <img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='iconeSetaGrupo'/>
                                    }
                                </div>
                            ) : null}
                        </div>
                            {item.PIZZA_MISTA === "SIM" ? (
                                <>
                                {listaTamanhosAtivos === "1" ? (
                                    <>
                                        {Array.isArray(tamanhosPizza) ? (
                                            tamanhosPizza.map((data, index)=>
                                            <div className={`cardProdutos ${theme === 'dark' ? '' : 'border'}`} style={{ background: `${css?.bgCardTamanhoPizzaMain}`, color: `${css?.colorCardTamanhoPizzaMain}` }} key={index} >
                                                <div className='boxProdutos' onClick={()=> handlePizzas(data, item.PIZZA_MISTA)}>
                                                    <div className='produtosInfo'>
                                                        <div className='produtoNome'>
                                                            <div className='itemNome itemNomeTamanhoPizza'> {data.TAMANHO} </div>
                                                        </div>
                                                    <div className='ValorTamanhoPizza'>
                                                        <div > {data.QTD_MAXIMO === 1 ? (data.QTD_MAXIMO + " " + "Sabor"):(data.QTD_MAXIMO + " " + "Sabores")} </div>
                                                    </div>
                                                    </div>
                                                    <div className='pizzaImg'>
                                                        <img src={'data:image/png;base64,' + css?.imagemPizza} className='pizzaPng'/>
                                                    </div>
                                                </div>
                                            </div>
                                            )    
                                        ) : null }
                                    </>
                                ) : null}
                            </>
                            ) : (
                                <div>
                                    <SubGrupoList
                                        grupo={item}
                                        infoClientes={infoClientes}
                                        css={css}
                                        theme={theme}
                                        dadosConexao={dadosConexao}
                                    />
                                </div>
                            )}
                    </div>
            )) : null} 
        </div>
        </div>
)}