import './Footer.css'
import { useEffect, useState } from 'react';

export default function Footer({ css }){
    const [aparelho, setAparelho] = useState('');
  
    useEffect(()=>{
        handleDetectarMobile()
    }, [])
    const handleDetectarMobile=()=> { 
      if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      ){
         setAparelho(true)
       }
      else {
         setAparelho(false)
       }
    }
    
    return(
        <div style={{ background: `${css?.bgFooter}`, color: `${css?.colorFooter}`, marginBottom: aparelho === true? '50px' : ''  }}  className='footer' id='footer' >
            <div className='footerBlocoTexto'>
                <div className='text-footer'> Garline </div>
                <div className='text-footer'> BeD Info Services </div>
                <div className='text-footer'> Rua Prefeito Arnaldo Facini, 499, Santo Antonio do Sudoeste - PR</div>
                    <a className='text-footer' style={{ color: 'inherit', textDecoration: 'inherit'}} href='http://bedinfoservices.com.br'> www.bedinfoservices.com.br </a>
                <div className='text-footer'> (46) 3563-1500 </div>
            </div>
        </div>
    )
}