import OneSignal from 'react-onesignal';

export default async function RunNotification() {
    
    await OneSignal.init({ 
                appId: '2d6b03ef-0bdc-454b-9f2f-7ca915de5fe0',
                safari_web_id: "web.onesignal.auto.2a0b8f88-f61f-4cbf-954f-aff96911a546",
                welcomeNotification: {
                    title: 'Bem-vindo!',
                    message: 'Agora você recebera avisos sobre o andamento do seu pedido!',
                    textColor: '#0000FF', // Cor do texto da notificação
                    backgroundColor: '#FFFF00' // Cor de fundo da notificação
                },
                promptOptions: {
                    slidedown: {
                        prompts: [
                            {
                                type: 'push',
                                autoPrompt: true,
                                text: {
                                    actionMessage: 'Deseja receber atualições sobre seu pedido?',
                                    acceptButton: 'Aceito',
                                    cancelButton: 'Não, obrigado'
                                }
                            }
                        ],
                        from: 'top', 
                        align: 'center'
                    }
                },
                notifyButton: {
                    enable: true,
                    size: 'small',
                    position: 'bottom-right',
                    offset:{
                        left: '0',
                        right: '15px',
                        bottom: '50px'
                    },
                    text: {
                        'tip.state.unsubscribed': 'Inscreva-se para receber atualizações sobres seu pedido',
                        'tip.state.subscribed': 'Notificações Ativas',
                        'tip.state.blocked': 'notificações bloqueadas',
                        'message.prenotify': 'Clique para habilitar as notificações',
                        'message.action.subscribed': 'Obrigado por se inscrever!',
                        'message.action.resubscribed': 'Você habilitou as notificações',
                        'message.action.unsubscribed': 'Você desabilitou as notificações',
                        'dialog.main.title': 'Gerenciar Notificações do Site',
                        'dialog.main.button.subscribe': 'Habilitar Notificações!',
                        'dialog.main.button.unsubscribe': 'Desativar Notificações!',
                        'dialog.blocked.title': 'Desbloquear Notificações',
                        'dialog.blocked.message': 'Siga estas instruções para desbloquear notificações:',
                    }
                }
            })
            .then((response)=>{
                OneSignal.Slidedown.promptPush();
            })
            .catch((error)=>{
                console.log(error.message)
            })
}