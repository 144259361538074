import { useState, useEffect } from 'react'
import { api } from '../../../../../../conecções/api';
import { useQueryClient } from '@tanstack/react-query';
import ListaAdicionaisPizzaBusca from './ListaAdicionaisPizzaBusca';
import Decimal from 'decimal.js'; 
import '../../../../../../Styles/StyleImagens.css'
import { JanelaMensagem } from '../../../../../../auxiliares/janelaFlutuante/JanelaMensagem'
import { useTranslation } from 'react-i18next';

export default function GrupoAdicionais({ tamanhoAlterado, dadosConexao, setTamanhoAlterado, setObrigatorioOpcional, tamanhoParaAdd, setValorTotalItem, setValorTotalCusto, adicionalSelecionado, setAdicionalSelecionado,  setID_GRUPO_OPCOES, css }){
    const [listaGrupoOpcionais, setGruposAdicionais] = useState([]);
    const [listaAdicionais, setListaAdicionais] = useState([]);
    const [listaAdicionaisAtivo, setListaAdicionaisAtivo] = useState(null);
    const [quantidadeTotalGrupos, setQuantidadeTotalGrupos] = useState({});
    const data = tamanhoParaAdd !== "" ? tamanhoParaAdd : ""
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    useEffect(()=>{
      if(tamanhoAlterado === true){
        setTamanhoAlterado(false)
        setAdicionalSelecionado([])
        setListaAdicionaisAtivo(null)
      }
    }, [tamanhoAlterado])  
    useEffect(() => {
      let obrigatorio = false;
      if (Array.isArray(listaGrupoOpcionais)) {
        listaGrupoOpcionais.forEach((itemGrupoAdd) => {
          if (itemGrupoAdd.MINIMO > 0) {
            const totalQuantidade = adicionalSelecionado.reduce((accumulator, item) => {
              if (item.ID_GRUPO_OPCOES === itemGrupoAdd.ID_GRUPO_OPCOES) {
                return accumulator + item.quantidade;
              }
              return accumulator;
            }, 0);
            if (totalQuantidade < itemGrupoAdd.MINIMO) {
              obrigatorio = true;
            }
          }
        });
      } 
      setObrigatorioOpcional(obrigatorio);
    }, [listaGrupoOpcionais, adicionalSelecionado]);
    useEffect(()=>{
      if(data !== ""){
        if(dadosConexao){
          handleListaGrupoOpcionais(data)
        }
      }
    }, [data, dadosConexao]);
    const handleListaGrupoOpcionais=(data)=>{
      const ROTAadicionaisTamanho = `http://${dadosConexao.api}:${dadosConexao.porta}/listaGrupoOpcionais/${'MISTA'}/${0}/${data.ID}`
      const dados_conexao = encodeURIComponent(ROTAadicionaisTamanho)
        api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata) =>{
            const grupoOpcionais = Array.isArray(getdata.data) ? getdata.data : ''
              setGruposAdicionais(grupoOpcionais);
          })
          .catch((error) => {
            const mensagem = t('frase.RotaGenerico')
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
          });
    }
    const toggleListaAdicionais = (itemGrupoAdd) => {
      if (listaAdicionaisAtivo === itemGrupoAdd.ID_GRUPO_OPCOES) {
        setListaAdicionaisAtivo(null);
        setQuantidadeTotalGrupos((prevQuantidades) => ({
          ...prevQuantidades,
          [itemGrupoAdd.ID_GRUPO_OPCOES]: listaAdicionais.reduce(
            (accumulator, item) => (item.ID_GRUPO_OPCOES === itemGrupoAdd.ID_GRUPO_OPCOES ? accumulator + item.quantidade : accumulator),
            0
          ),
        }));
      } else {
        setListaAdicionaisAtivo(itemGrupoAdd.ID_GRUPO_OPCOES);
        selecionarListaOpcionais(itemGrupoAdd);
      }
    };
    const selecionarListaOpcionais = (itemGrupoAdd) => {
      const cachedData = queryClient.getQueryData(['listaAdicionais', itemGrupoAdd.ID_GRUPO_OPCOES, tamanhoParaAdd.ID]);
      if (cachedData) {
        setListaAdicionais(cachedData);
        setID_GRUPO_OPCOES(itemGrupoAdd.ID_GRUPO_OPCOES);
        setQuantidadeTotalGrupos((prevQuantidades) => ({
          ...prevQuantidades,
          [itemGrupoAdd.ID_GRUPO_OPCOES]: prevQuantidades[itemGrupoAdd.ID_GRUPO_OPCOES] || 0,
        }));
      } else {
          if(itemGrupoAdd.TABELA === 'TAMANHOS'){
            const ROTAListaOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaOpcionais/${itemGrupoAdd.ID_GRUPO_OPCOES}/${tamanhoParaAdd.ID}`
            handleListaOpções(ROTAListaOpções, itemGrupoAdd)
          } else {
            const ROTAListaOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaOpcionais/${itemGrupoAdd.ID_GRUPO_OPCOES}/${'SEM_TAMANHO'}`
            handleListaOpções(ROTAListaOpções, itemGrupoAdd)
          }
      }
    };
    const handleListaOpções=(ROTAListaOpções, itemGrupoAdd)=>{
      const dados_conexao = encodeURIComponent(ROTAListaOpções)
      api
        .post(`/requisicaoDados/${dados_conexao}`)
        .then((getdata) => {
            const data = getdata.data.map((item) => ({
                ...item,
                quantidade: 0,
            }));
              setListaAdicionais(data);
              queryClient.setQueryData(['listaAdicionais', itemGrupoAdd.ID_GRUPO_OPCOES, tamanhoParaAdd.ID], data);
              setID_GRUPO_OPCOES(itemGrupoAdd.ID_GRUPO_OPCOES);
              setQuantidadeTotalGrupos((prevQuantidades) => ({
                ...prevQuantidades,
                [itemGrupoAdd.ID_GRUPO_OPCOES]: prevQuantidades[itemGrupoAdd.ID_GRUPO_OPCOES] || 0,
              }));
        }); 
    }    
    useEffect(() => {
        listaAdicionais.forEach((item) => {
            if (item.quantidade > 0) {
              const itemIndex = adicionalSelecionado.findIndex((adicionalSelecionado) => adicionalSelecionado.ID === item.ID);
              if (itemIndex === -1) {
                setAdicionalSelecionado((prevSelecionados) => [...prevSelecionados, { ...item }]);
              } else {
                setAdicionalSelecionado((prevSelecionados) => {
                  const updatedSelecionados = [...prevSelecionados];
                  if (updatedSelecionados[itemIndex]) {
                    updatedSelecionados[itemIndex].quantidade = item.quantidade;
                  }
                  return updatedSelecionados;
                });
              }
            } else {
              setAdicionalSelecionado((prevSelecionados) => prevSelecionados.filter((adicionalSelecionado) => adicionalSelecionado.ID !== item.ID));
            }
          })
    }, [listaAdicionais]);
    useEffect(() => {
      let totalItem = new Decimal(0);
      let totalCusto = new Decimal(0);
    
      adicionalSelecionado.forEach((item) => {
        if (item.DIVIDIR === 'NAO') {
          totalItem = totalItem.plus(new Decimal(item.VALOR_VENDA).times(item.quantidade));
          totalCusto = totalCusto.plus(new Decimal(item.VALOR_CUSTO).times(item.quantidade));
        } else if (item.DIVIDIR === 'SIM') {
          totalItem = totalItem.plus(
            new Decimal(item.VALOR_VENDA).times(item.quantidade).dividedBy(quantidadeTotalGrupos[item.ID_GRUPO_OPCOES])
          );
          totalCusto = totalCusto.plus(
            new Decimal(item.VALOR_CUSTO).times(item.quantidade).dividedBy(quantidadeTotalGrupos[item.ID_GRUPO_OPCOES])
          );
        }
      });
    
      setValorTotalItem(totalItem.toNumber().toFixed(2));
      setValorTotalCusto(totalCusto.toNumber().toFixed(2));
    }, [adicionalSelecionado, quantidadeTotalGrupos]);
    useEffect(() => {
      if (Array.isArray(listaAdicionais)) {
        const total = listaAdicionais.reduce(
          (accumulator, item) =>
            item.ID_GRUPO_OPCOES === listaAdicionaisAtivo ? accumulator + item.quantidade : accumulator,
          0
        );
        setQuantidadeTotalGrupos((prevQuantidades) => ({
          ...prevQuantidades,
          [listaAdicionaisAtivo]: total,
        }));
      }
    }, [listaAdicionais, listaAdicionaisAtivo]);
    
    return(
    <div className='listaGrupoOpcionais'>
        <div>
          {
            Array.isArray(listaGrupoOpcionais) ? (
              <div className='grupoTitulo' style={{ background: `${css?.bgTituloGrupoAdicionais}`, color: `${css?.colorTituloGrupoAdicionais}` }} > {t('adicionais').toUpperCase()} </div>
            ) : (
              <div></div>
            )
          }
        </div>
        <div>
            {Array.isArray(listaGrupoOpcionais) ? (
                listaGrupoOpcionais.map((itemGrupoAdd, index) => 
                  <div key={index} >
                        <div className='cardGrupoOpcionais font16 ' style={{ background: `${css?.bgTituloAdicionaisBusca}`, color: `${css?.colorTituloAdicionaisBusca}` }} onClick={() => toggleListaAdicionais(itemGrupoAdd)}>
                          <div style={{ width: '100%' }}>
                            <div className='adicionaisTitulo'> {itemGrupoAdd.DESCRICAO} </div>
                            <div className='boxQuantidades'>
                              {itemGrupoAdd.MINIMO > 0
                                ?(<div className='caixaQuantidades' >
                                    <div className='obrigatorio' > {t('obrigatorio')} </div>
                                  </div>)
                                : ('')
                              }
                              <div className='caixaQuantidades'>
                                <div className='maximo' > {t('maximo')}: {itemGrupoAdd.MAXIMO} </div>
                              </div>
                              <div className='caixaQuantidades'>
                                <div className='escolhido' style={{ background: `${quantidadeTotalGrupos[itemGrupoAdd.ID_GRUPO_OPCOES] === itemGrupoAdd.MAXIMO ? '' : ''}`,
                                                                    color: `${quantidadeTotalGrupos[itemGrupoAdd.ID_GRUPO_OPCOES] === itemGrupoAdd.MAXIMO ? '' : ''}`
                                                                  }}>
                                     {t('escolhidos')}: {quantidadeTotalGrupos[itemGrupoAdd.ID_GRUPO_OPCOES]}
                                </div>
                              </div>
                            </div>
                          </div> 
                            <div className='iconeAdicionais'>
                              <div >
                                  {listaAdicionaisAtivo === itemGrupoAdd.ID_GRUPO_OPCOES 
                                    ? <img src={'data:image/png;base64,' + css?.iconeParaCima} className='tamanhoIcone'/>  
                                    : <img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='tamanhoIcone'/> 
                                  }
                              </div>
                            </div>
                        </div>
                        <div>
                          {listaAdicionaisAtivo === itemGrupoAdd.ID_GRUPO_OPCOES && (
                              <ListaAdicionaisPizzaBusca
                                  Maximo={itemGrupoAdd.MAXIMO}
                                  listaAdicionais={listaAdicionais}
                                  setListaAdicionais={setListaAdicionais}
                                  quantidadeTotalGrupos={quantidadeTotalGrupos} 
                                  listaGrupoOpcionais={listaGrupoOpcionais}
                                  itemGrupoAdd={itemGrupoAdd}
                                  css={css}
                                  dadosConexao={dadosConexao}
                              />
                          )} 
                        </div>
                  </div>
        )) : null}
                
    </div>
        </div>
    )
}