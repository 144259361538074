import { useState, useEffect } from 'react';
import './modalrecargacartao.css'
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { GerarPix, consultarStatusPix, cancelarPixRecargaCartao } from '../../../../auxiliares/metodosPagamento/Pix'
import PixCopiaECola from '../../../../auxiliares/metodosPagamento/Pix'
import { useTranslation } from 'react-i18next';

export default function ModalRecargaCartao({ setModalRecargaCartao, setAtualizarCredito, dadosConexao, css }){
    const [dadosPix, setDadospix] = useState('');
    const [total, setValor] = useState(0);
    const [status, setStatus] = useState('');
    const [pixCopiaECola, setPixCopiaECola] = useState('')
    const [infosPix, setInfosPix] = useState('');
    const [pixGerado, setPixGerado] = useState(false);
    const rota = `http://${dadosConexao.api}:${dadosConexao.porta}/inserirCredito`
    const numeroComanda = localStorage.getItem('numeroComanda')
    const {t} = useTranslation();
    const txid = infosPix !== '' ? infosPix.txid : '' ;

    useEffect(()=>{
        handleDadosPix()
        setNumeroComanda()
    }, [])
    const handleDadosPix=()=>{
        const ROTADadosPix = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosPIX`
        const dados_conexao = encodeURIComponent(ROTADadosPix)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata) => {
                setDadospix(getdata.data[0])
            })
            .catch((error) => {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const handleValor = (event) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        const formattedValue = (inputValue / 100).toFixed(2);     
            setValor(formattedValue);
    };
    const handleCriarPix =()=>{
        if(total <= 0.00){
            const mensagem = t('semValorPagamento')
            const cor = 'amarelo'
            JanelaMensagem(mensagem, cor)
        } else {
            GerarPix(dadosPix, total, rota, numeroComanda, setInfosPix, setPixCopiaECola, setPixGerado)
        }
    }
    useEffect(()=>{
        if(pixGerado === true){
            const intervalId = setInterval(() => {
                handleVerificarStatus()
              }, 2000); 
            return () => clearInterval(intervalId);
        }
    }, [pixGerado])
    const handleVerificarStatus=()=>{
        consultarStatusPix(dadosPix, txid, setStatus)
    }
    useEffect(()=>{
        if(status === 'CONCLUIDA'){
            setPixGerado(false)
            handleFechar()
            const mensagem = t('recargaConcluida')
            const cor = 'verde'
            JanelaMensagem(mensagem, cor)
        }
    }, [status])
    const handleCancelarPix=()=>{
        cancelarPixRecargaCartao(dadosPix, txid, setModalRecargaCartao)
    }
    const handleFechar=()=>{
        setModalRecargaCartao(false)
        setAtualizarCredito(true)
    }
    return(
    <>
        <div className='darkBG'/>
            <div className='centered'>
            <div className='modalPedidos'  style={{ background: `${css?.bgModalPagamentoPix}` , color: `${css?.colorModalPagamentoPix}` }}>
            <div className='modalPedidosContent'> 
                <div className='tituloModalPedidoCart'> 
                    <div className='Calculo' >
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> {t('Valor')} </label>
                            <input
                                type='number'
                                placeholder='Digite seus Dados...'
                                name='dinheiro'
                                className='input'
                                value={total}
                                onChange= {handleValor}
                            />
                        </div>
                    </div>
                    <div className='caixabtngerarpix'>
                        <button className='btnGerarPix' onClick={()=> handleCriarPix()}> {t('gerarPix')} </button>
                    </div>
                </div>
            </div>
            <div className='textoPedidoCartModal'>
                <div className='textoloadpix'> {t('aguardandoPagamento')} </div>
                   <div className='areaPix' >
                    <PixCopiaECola
                            pixCopiaECola={pixCopiaECola}
                            css={css}
                        />
                   </div>
            </div>
            <div>
                <button className='btn btnCancelar' style={{ background: `${css?.bgBtnCancelar}`, color: `${css?.colorBtnCancelar}` }}  onClick={() => handleCancelarPix(false)}> {t('cancelar')} </button>
            </div>
            </div>
        </div>
    </>
  );
};