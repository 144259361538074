import { useEffect, useState } from 'react'
import { formCurrency } from '../../../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../../../auxiliares/utilidades/FormataUSD';
import Decimal from 'decimal.js';
import '../../../../../../Styles/Styles.css'
import '../../../../../../Styles/StyleImagens.css'
import '../../../../../../Styles/StylePizzas.css'
import { useTranslation } from 'react-i18next';


export default function ListaSaboresPizza({ quantidadeTotal, dadosConexao, setQuantidadeTotal, listaSaboresPizzas, setListaSaboresPizzas, Min, Max, setSaboresSelecionados, SaboresSelecionados, css }) {
  const [listaSalgadasAtiva, setListaSalgadasAtiva] = useState(null);
  const [listaDocesAtiva, setListaDocesAtiva] = useState(null);
  const Idsalgadas = '1'
  const IdDoces = '2'
  const { t } = useTranslation();
  const moeda = dadosConexao?.moeda

  const AbrirListaSalgadas = (Idsalgadas) => {
    if (listaSalgadasAtiva === Idsalgadas) {
        setListaSalgadasAtiva(null);
    } else {
        setListaSalgadasAtiva(Idsalgadas);
    }
  }  
  const AbrirListaDoces = (IdDoces) => {
    if (listaDocesAtiva === IdDoces) {
        setListaDocesAtiva(null);
    } else {
        setListaDocesAtiva(IdDoces);
    }
  } 
  const aumentarQuantidade = (index) => {
    const updatedListaOpcionais = [...listaSaboresPizzas];
    const quantidade = new Decimal(updatedListaOpcionais[index].quantidade);
    updatedListaOpcionais[index].quantidade = quantidade.plus(1).toNumber();
      setListaSaboresPizzas(updatedListaOpcionais);
  };
  const diminuirQuantidade = (index) => {
    const updatedListaOpcionais = [...listaSaboresPizzas];
    const quantidade = new Decimal(updatedListaOpcionais[index].quantidade);
    if (quantidade.gt(0)) {
      updatedListaOpcionais[index].quantidade = quantidade.minus(1).toNumber();
      setListaSaboresPizzas(updatedListaOpcionais);
    }
  }
  useEffect(() => {
    const totalQuantity = SaboresSelecionados.reduce((accumulator, item) => accumulator + item.quantidade, 0);
      setQuantidadeTotal(totalQuantity)
  }, [SaboresSelecionados]);
  const Faltam =()=>{
    const Faltam = Max - quantidadeTotal
    return Faltam
  } 
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
 }
  useEffect(() => {
    listaSaboresPizzas.forEach((item) => {
      if (item.quantidade > 0) {
        const itemIndex = SaboresSelecionados.findIndex((selectedItem) => selectedItem.PRODUTO === item.PRODUTO);
        if (itemIndex === -1) {
          setSaboresSelecionados((prevSelecionados) => [...prevSelecionados, { ...item }]);
        } else {
          setSaboresSelecionados((prevSelecionados) => {
            const updatedSelecionados = prevSelecionados.map((selectedItem) => {
              if (selectedItem.PRODUTO === item.PRODUTO) {
                return {
                  ...item,
                  quantidade: item.quantidade,
                };
              }
              return selectedItem;
            });
            return updatedSelecionados;
          });
        }
      } else {
        setSaboresSelecionados((prevSelecionados) =>
          prevSelecionados.filter((selectedItem) => selectedItem.PRODUTO !== item.PRODUTO)
        );
      }
    });
  }, [listaSaboresPizzas]);

  return(
        <div>
          <div>
              <div className='tituloSaboresPizza'  style={{ background: `${css?.bgTituloSaboresPizza}`, color: `${css?.colorTituloSaboresPizza}` }} > {t('sabores')} </div>
          </div>
          <div className='saboresQuantidades' style={{ background: `${css?.bgSaboresQuantidades}`, color: `${css?.colorSaboresQuantidades}` }}> 
            <div className='QTD'  > {t('minimo')}: {Min} </div>
            <div className='maximo' > {t('maximo')} : {Max} </div>
            <div className='escolhido' > {t('faltam')} : {Faltam()} </div>
          </div>
          <div>
              <div className='grupoSabores' style={{ background: `${css?.bgGrupoSabores}`, color: `${css?.colorGrupoSabores}`, marginTop: '5px' }}> 
                <div className='textSabores' onClick={() => AbrirListaSalgadas(Idsalgadas)}> {t('salgadas')} </div>
                <div className='iconSabores' onClick={() => AbrirListaSalgadas(Idsalgadas)}>
                  {listaSalgadasAtiva === Idsalgadas 
                    ?<img src={'data:image/png;base64,' + css?.iconeParaCima} className='iconeSetaGrupo'/> 
                    :<img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='iconeSetaGrupo'/>
                  }
                </div>
              </div>
              <div className='pizzaListaSabores'  style={{ background: `${css?.bgListaSaboresPizza}` }}>
                  {Array.isArray(listaSaboresPizzas) ? (
                    listaSaboresPizzas.map((itemSabor, index) => {
                      if (listaSalgadasAtiva === Idsalgadas && itemSabor.TIPO_PIZZA === "SALGADA") {
                        return (
                          <div  key={index}>
                            <div className='pizzaCard' style={{ background: `${css?.bgPizzaCard}`, color: `${css?.colorPizzaCard}` }}>
                                <div className='pizzaInfo'>
                                  <div className='pizzaSabor'> {capitalizeFirstLetter(itemSabor.PRODUTO.toLowerCase())} </div>
                                  <div className='pizzaCaixa'>
                                    <div className='pizzaFicha'> {itemSabor.FICHA_TECNICA !== null 
                                            ? <div> {itemSabor.FICHA_TECNICA.toLowerCase()} </div>
                                            : <div></div> 
                                          } 
                                    </div>
                                  </div>
                                </div>
                                <div className='pizzaInput'> 
                                    <div className='pizzaValor'>
                                      <div> 
                                        {
                                          moeda === 'BRL' ? formCurrency.format(itemSabor.VALOR_VENDA) :
                                          moeda === 'ARS' ? formCurrencyArgentina.format(itemSabor.VALOR_VENDA) : 
                                          moeda === 'USD' ? formCurrencyUSD.format(itemSabor.VALOR_VENDA) : null
                                        }
                                      </div>
                                    </div>
                                  <div className='Card-Adicionais-Botoes'>
                                    <div className='btn-quantia-adicionais'>
                                      <button className='btn' onClick={() => diminuirQuantidade(index, itemSabor)}>
                                        <img src={'data:image/png;base64,' + css?.iconeMenos} className='iconeCart'/>
                                      </button>
                                    </div>
                                      <div className='quantia-adicionais'>{itemSabor.quantidade}</div>
                                    <div className='btn-quantia-adicionais'>
                                      <button className='btn'onClick={() => aumentarQuantidade(index, itemSabor)}
                                        disabled={quantidadeTotal === Max}
                                      >
                                        <img src={'data:image/png;base64,' + css?.iconeMais} className='iconeCart'/>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        );
                      }
                      return null;
                    })
                  ) : null}
          </div>
          </div>
          <div>
            <div className='grupoSabores' style={{ background: `${css?.bgGrupoSabores}`, color: `${css?.colorGrupoSabores}` }} > 
              <div className='textSabores' onClick={() => AbrirListaDoces(IdDoces)}> {t('doces')} </div>
              <div className='iconSabores' onClick={() => AbrirListaDoces(IdDoces)}>
                  {listaDocesAtiva === IdDoces 
                    ?<img src={'data:image/png;base64,' + css?.iconeParaCima} className='iconeSetaGrupo'/> 
                    :<img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='iconeSetaGrupo'/>
                  }
              </div>
            </div>
            <div className='pizzaListaSabores' >
              {Array.isArray(listaSaboresPizzas) ? (
                listaSaboresPizzas.map((itemSabor, index) => {
                  if (listaDocesAtiva === IdDoces && itemSabor.TIPO_PIZZA === "DOCE") {
                    return (
                      <div key={index}>
                        <div className='pizzaCard' style={{ background: `${css?.bgPizzaCard}`, color: `${css?.colorPizzaCard}` }}>
                            <div className='pizzaInfo'>
                              <div className='pizzaSabor'> {itemSabor.PRODUTO.toLowerCase()} </div>
                              <div className='pizzaCaixa'>
                                <div className='pizzaFicha'> {itemSabor.FICHA_TECNICA !== null 
                                        ? <div> {itemSabor.FICHA_TECNICA.toLowerCase()} </div>
                                        : <div></div> 
                                      } 
                                </div>
                              </div>
                            </div>
                            <div className='pizzaInput'> 
                              <div className='pizzaValor'>
                                  <div>
                                    {
                                      moeda === 'BRL' ? formCurrency.format(itemSabor.VALOR_VENDA) :
                                      moeda === 'ARS' ? formCurrencyArgentina.format(itemSabor.VALOR_VENDA) : 
                                      moeda === 'USD' ? formCurrencyUSD.format(itemSabor.VALOR_VENDA) : null
                                    } 
                                  </div>
                                </div>
                              <div className='Card-Adicionais-Botoes'>
                                <div className='btn-quantia-adicionais'>
                                  <button className='btn' onClick={() => diminuirQuantidade(index, itemSabor)}>
                                    <img src={'data:image/png;base64,' + css?.iconeMenos} className='iconeCart'/>
                                  </button>
                                </div>
                                  <div className='quantia-adicionais'>{itemSabor.quantidade}</div>
                                <div className='btn-quantia-adicionais'>
                                  <button className='btn'onClick={() => aumentarQuantidade(index, itemSabor)}
                                    disabled={quantidadeTotal === Max}
                                  >
                                    <img src={'data:image/png;base64,' + css?.iconeMais} className='iconeCart'/>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    );
                  }
                  return null;
                })
              ) : null}
          </div>
          </div>          
        </div>
    )
}