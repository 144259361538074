import { useState, useEffect } from 'react';
import { api } from '../../../../../../conecções/api';
import '../../../../../../Styles/StyleForAdicionais.css'
import ListaSaboresPizzas from './ListaSaboresPizzas'
import Decimal from 'decimal.js';
import { JanelaMensagem } from '../../../../../../auxiliares/janelaFlutuante/JanelaMensagem'
import { useTranslation } from 'react-i18next';

export default function GruposSabores({ data, listaSaboresPizzas, dadosConexao, setListaSaboresPizzas, quantidadeTotal, setQuantidadeTotal, setValorTotalCustoPizza, setValorTotalSabores, setSaboresSelecionados, SaboresSelecionados, css}){
    const {t} = useTranslation();

    useEffect(() => {
      if (data !== "") {
        if(dadosConexao){
          handleDadosEmpresa()
        }
      }
    }, [data, dadosConexao]);
    const handleDadosEmpresa=()=>{
      const ROTAdadosEmpresa = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosEmpresa/${localStorage.getItem('codigoEmpresa')}`
      const dados_conexao2 = encodeURIComponent(ROTAdadosEmpresa)
        api
          .post(`/requisicaoDados/${dados_conexao2}`)
          .then((getdata) => {
              const idCardapio = getdata.data[0].ID_CARDAPIO
              handleListaSaboresPizza(idCardapio)
          })
          .catch((error) =>{
            const mensagem = t('frase.RotaGenerico')
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
          })
    }
    const handleListaSaboresPizza=(idCardapio)=>{
      const ROTAsaboresPizza = `http://${dadosConexao.api}:${dadosConexao.porta}/listaSaboresPizza/${data.ID}/${idCardapio}`;
      const dados_conexao = encodeURIComponent(ROTAsaboresPizza);
        api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata) => {
            const updatedData = getdata.data.map((item) => ({
              ...item,
              quantidade: 0, 
          }));
            setListaSaboresPizzas(updatedData);
          })
          .catch((error) => {
            const mensagem = t('frase.RotaGenerico')
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
          });
    }       
    useEffect(() => {
        let totalCusto = new Decimal(0);
        SaboresSelecionados.forEach(item => {
          if (item.VALOR_MAIOR_MISTA === "NAO") {
            totalCusto = totalCusto.plus(new Decimal(item.VALOR_CUSTO).times(item.quantidade));
          } else if (item.VALOR_MAIOR_MISTA === "SIM") {
            totalCusto = totalCusto.plus(new Decimal(item.VALOR_CUSTO).times(item.quantidade).dividedBy(quantidadeTotal));
          }
        });
        setValorTotalCustoPizza(totalCusto);
    }, [SaboresSelecionados, quantidadeTotal]);
    useEffect(() => {
        if(data.VALOR_MAIOR_MISTA === "NAO"){
          const totalItem = SaboresSelecionados.reduce((acc, item) => {
            const multipliedValue = new Decimal(item.VALOR_VENDA).times(item.quantidade);
            const dividedValue = multipliedValue.dividedBy(quantidadeTotal);
            return acc.plus(dividedValue);
          }, new Decimal(0));
            setValorTotalSabores(totalItem);
        } else if (data.VALOR_MAIOR_MISTA === "SIM"){
          const totalItem = SaboresSelecionados.reduce((acc, item) => {
            const multipliedValue = new Decimal(item.VALOR_VENDA).times(item.quantidade);
            return acc.plus(multipliedValue);
          }, new Decimal(0));
            setValorTotalSabores(totalItem);
        }
    }, [SaboresSelecionados, quantidadeTotal, data]);
        
return( 
    <div>
        <ListaSaboresPizzas
            listaSaboresPizzas={listaSaboresPizzas}
            setListaSaboresPizzas={setListaSaboresPizzas}
            Min={data.QTD_MINIMO}
            Max={data.QTD_MAXIMO}

            setSaboresSelecionados={setSaboresSelecionados}
            SaboresSelecionados={SaboresSelecionados}

            quantidadeTotal={quantidadeTotal}
            setQuantidadeTotal={setQuantidadeTotal}
            css={css}
            dadosConexao={dadosConexao}
        />
    </div>
)
}