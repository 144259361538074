import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCYWFJQHCOX0lGYXbMIffgwSpbee-rsBp8",
  authDomain: "cardapioweb-4190a.firebaseapp.com",
  projectId: "cardapioweb-4190a",
  storageBucket: "cardapioweb-4190a.appspot.com",
  messagingSenderId: "296774148999",
  appId: "1:296774148999:web:add883b730aa7327822922",
  measurementId: "G-46HFSFBDCE"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);