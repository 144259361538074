import { useState, useEffect } from 'react'
import '../../../../Styles/StyleEndereco.css'
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';

export default function modal({ dadosConexao, user, item, setIsOpenUserDados, setAtualizarDadosCliente, css}){
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [dataNascimento, setDataNascimento] = useState('') 
    const {t} = useTranslation();
    
    useEffect(() => {
        setNome(item.nome);
        setTelefone(item.numero_telefone);
        const dataObj = new Date(item.data_nascimento);
        const dataFormatada = `${dataObj.getUTCFullYear()}-${(dataObj.getUTCMonth() + 1).toString().padStart(2, '0')}-${dataObj.getUTCDate().toString().padStart(2, '0')}`;
        setDataNascimento(dataFormatada);
    }, [])
    const Salvar = (user) => {
        const ROTAsaboresPizza = `http://${dadosConexao.api}:${dadosConexao.porta}/alterarDadosCliente`
        const dados_conexao = encodeURIComponent(ROTAsaboresPizza)
            if(nome !== ""){
            api
                .post(`/editarDadosCliente/${dados_conexao}`, {
                    firebase_token: user.uid,
                    nome: nome,
                    numero_telefone: telefone,
                    data_nascimento: dataNascimento
                })
                .then((response)=>{
                    const mensagem = 'Erro 533'
                    const cor = 'verde'
                        JanelaMensagem(mensagem, cor)
                        setIsOpenUserDados(false)
                        setAtualizarDadosCliente(true)
                })
                .catch((error) => {
                    const mensagem = 'Erro 534'
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
            } else {
                const mensagem = t('preenchaNome')
                const cor = 'amarelo'
                JanelaMensagem(mensagem, cor)
            }
    }
    const handleTelefone = (event) => {
        const numeros = event.replace(/\D/g, ''); 
        if (numeros.length <= 11) {
          setTelefone(numeros);
        }
    }

    return(
    <>
        <div className='modalUser' onClick={() => setIsOpenUserDados(false)} />
            <div className='centeredModal'>
            <div className='modalUsuario' style={{ background: `${css?.bgModalEditInfoUser}`, color: `${css?.colorModalEditInfoUser}` }} >
            <button className='closeBtn' onClick={() => setIsOpenUserDados(false)}> <div className='iconeBtnCloseModal'></div> </button>
                <div className='modalEditContent'> 
                    <div className='modalTitulo'> {capitalizeFirstLetter(t('editar'))} </div>
                    <div className='itensModal'>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> {t('nome')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditInfoUser}`, color: `${css?.colorInputEditInfoUser}` }}
                                type='text'
                                placeholder={t('nome')}
                                name='input'
                                className='input'
                                value={nome}
                                onChange={(e)=> setNome(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> {t('celular')} {t('exemplonumero')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditInfoUser}`, color: `${css?.colorInputEditInfoUser}` }}
                                type='text'
                                placeholder={t('exemplonumero')}
                                name='input'
                                className='input'
                                pattern="[0-9]+$"   
                                value={telefone === "null" ? "não informado" : (telefone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{4})\d+?$/, '$1'))}
                                onChange={(e)=> handleTelefone(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> {capitalizeFirstLetter(t('dataNascimento'))} xx/xx/xxxx </label>
                            <input
                                style={{ background: `${css?.bgInputEditInfoUser}`, color: `${css?.colorInputEditInfoUser}` }}
                                type='date'
                                placeholder='00/00/0000'
                                name='input'
                                className='input'
                                value={dataNascimento}
                                onChange={(e)=> setDataNascimento(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='caixaBtnSalvarEnredereco'>
                        <button className='btnSalvar' onClick={()=> Salvar(user)} style={{ background: `${css?.bgBtnSalvarModalEditinfoUser}`, color: `${css?.colorBtnSalvarModalEditInfoUser}` }} > {t('salvar')} </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};