import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addToCart } from '../../../../redux/cartSlice'
import { useQueryClient } from '@tanstack/react-query';
import { formCurrency } from '../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../auxiliares/utilidades/FormataUSD';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function BtnCarrinho({ obrigatorioopcional, dadosConexao, totalCusto, Produto, PIZZA_MISTA, SaboresSelecionados, adicionalSelecionado, observacoes, totalCompra, quantidadeTotal, ID_GRUPO_OPCOES, css }){
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda

    const item ={
        produto: Produto,
        adicionalSelecionado: adicionalSelecionado,
        SaboresSelecionados:SaboresSelecionados,
        observacoes: observacoes,
        totalCompra: totalCompra,
        tipo: PIZZA_MISTA,
        custoTotal: totalCusto
    }
    const handleCarrinho=(item, ID_GRUPO_OPCOES)=>{
      const cor = 'amarelo'
      if(obrigatorioopcional === true){
        const mensagem = t('escolheAdicional')
          JanelaMensagem(mensagem, cor)
      } else if (quantidadeTotal === 0){
        const mensagem = t('escolheSabor')
          JanelaMensagem(mensagem, cor)
      } else {
        dispatch(addToCart(item))
          const cachedData = queryClient.clear(['listaAdicionais', ID_GRUPO_OPCOES])
        navigate('/Carrinho')
      }
    }

    return(
        <button className='btnPagarAdicionais' onClick={()=> handleCarrinho(item, ID_GRUPO_OPCOES)}>
        <div className='barraPagarAdicionais' style={{ background: `${css?.bgBtnInserirCart}`, color: `${css?.colorBtnInserirCart}` }} >
          <div className='pagarAdicionaisText'>
            <div className='btnAdicionarAdicionais'> {t('adicionar').toUpperCase()} </div>
          </div>
          <div className='pagarAdicionaisValor'>
            {
              moeda === 'BRL' ? formCurrency.format(totalCompra) :
              moeda === 'ARS' ? formCurrencyArgentina.format(totalCompra) : 
              moeda === 'USD' ? formCurrencyUSD.format(totalCompra) : null
            }
        </div>   
        </div>
      </button>
    )
}