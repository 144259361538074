import { useState, useEffect } from 'react'
import './formasdepagamento.css'
import '../../../../Styles/StyleImagens.css'
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula'
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function FormasDePagamento({ setPagamentoSelecionado, dadosConexao, css }){
    const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
    const [metodosPagamento, setMetodosPagamento] = useState([]);
    const {t} = useTranslation();
    
    const RadioPagamento = (item, index) => {
        setSelectedRadioIndex(index);
        setPagamentoSelecionado(item)
    };
    useEffect(()=>{
        if(dadosConexao){
            handlePagamentos()
        }
    }, [dadosConexao])
    const handlePagamentos=()=>{
        const ROTApagamentos = `http://${dadosConexao.api}:${dadosConexao.porta}/pagamentos`
        const dados_conexao = encodeURIComponent(ROTApagamentos)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setMetodosPagamento(getdata.data)
            })
            .catch((error) => {
                const mensagem = 'Erro 551'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    
    return(
        <div>
            <div className='tituloPagamentos' style={{ background: `${css?.bgTituloPagamentos}`, color: `${css?.colorTituloPagamentos}` }}>
                <div className='iconePagamentosBox'> 
                    <img src={'data:image/png;base64,' + css?.iconePagamentos} className='iconePagamentos'/>
                </div>
                <div className='tituloPagamentosTexto'> {capitalizeFirstLetter(t('pagamento'))} </div>
            </div>
            <div className='listaMetodosPagamentos' style={{ background: `${css?.bgListaPagamentos}`}}>
                {Array.isArray(metodosPagamento) ? (
                        metodosPagamento.map((item, index)=>
                            <div 
                                style={{ background: `${ selectedRadioIndex === index ? `${css?.bgChecked}` : `${css?.bgCardPagamentos}` }`, color: `${css?.colorCardPagamentos}` }}
                                className='cardPagamento' 
                                key={index}  
                                onClick={()=> RadioPagamento(item,index)}>
                                <div className='pagamentoNome '> {capitalizeFirstLetter(item.DESCRICAO.toLowerCase())} </div>
                                <div style={{ marginRight: '10px' }}>
                                    <input type='radio' name='tamanhos' checked={selectedRadioIndex === index} onChange={() => {}}/>
                                        {selectedRadioIndex === index ? (
                                            <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                                        )
                                        : (
                                            <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                                        ) 
                                        }
                                </div>
                            </div>
                )):null}
            </div>
        </div>
    )
}