import { useEffect, useState } from 'react'
import '../../../Styles/StyleCarrinho.css'
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../auxiliares/utilidades/PrimeiraMaiuscula';


export default function Observacoes({ setObservacaoCart, css }){
    const tipoComanda = localStorage.getItem('tipoComanda')
    const {t} = useTranslation();

    return(
        <>
        {tipoComanda === "DELIVERY" ?(
            <div className='observacoesMain'>
                <div className='observacoesTitulo' style={{ background: `${css?.bgTituloObsCart}`, color: `${css?.colorTituloObsCart}` }} >
                    <img src={'data:image/png;base64,' + css?.iconeObservacoes} className='observacoesIcone'/>
                    <div className='observacoesNome'> {capitalizeFirstLetter(t('observacoes'))} </div>
                </div>
                <div className='observacoesCart' style={{ background: `${css?.bgObsCart}`, color: `${css?.colorObsCart}` }} >
                    <textarea className='observacoesCart-text' style={{ color: `${css?.colorObsCart}` }} placeholder={t('observacoes') + "..."} onChange={e => setObservacaoCart(e.target.value)}></textarea>
                </div>
            </div>
        ) :null}
        </>
    )
} 