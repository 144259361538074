import { useState, useEffect } from 'react'
import '../../../../Styles/StyleEndereco.css'
import '../../../../Styles/StyleImagens.css'
import { api } from '../../../../conecções/api';
import ModalEndereco from './ModalEndereco'
import ModalCadastrarEndereco from './ModalCadastrarEndereco'
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function Endereços ({ user, css, theme, dadosConexao }){
    const [endereco, setEndereco] = useState([]);
    const [isOpenModalEndereco, setIsOpenModalEndereco] = useState(false);
    const [enderecoMudar, setEnderecoEditar] = useState('');
    const [isOpenCadastrarEndereco, setIsOpenCadastrarEndereco] = useState(false);
    const [listaTamanhosAtivos, setListaTamanhosAtivos] = useState(null);
    const [atualizarEnderecos, setAtualizarEnderecos] = useState(false);
    const {t} = useTranslation();
    const uidToken = user?.uid

    useEffect(()=>{
        if(uidToken){
            handleEnderecos()
        }
        if(atualizarEnderecos === true){
           handleEnderecos() 
        }
    }, [atualizarEnderecos, uidToken]);
    const handleEnderecos=()=>{
        const ROTAenderecos = `http://${dadosConexao.api}:${dadosConexao.porta}/enderecos/${uidToken}`
        const dados_conexao = encodeURIComponent(ROTAenderecos)
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((getdata)=>{
                    setEndereco(getdata.data)
                })
                .catch((error) => {
                    const mensagem = 'Erro 514'
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const editarEndereco=(item)=>{
        setIsOpenModalEndereco(true)
        setEnderecoEditar(item)
    }
    const toggleListaTamanhos = (IdEndereco) => {
        if (listaTamanhosAtivos === IdEndereco) {
            setListaTamanhosAtivos(null);
        } else {
            setListaTamanhosAtivos(IdEndereco);
        }
    }
    function capitalizeFirstLetter(str) {
        const parts = str.split('|');
        if (parts.length === 2) {
          return parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
        } else {
          return str; 
        }
    }

    return(
        <div className='endereços'>
            <div className='tituloEnderecos flex between' style={{ background: `${css?.bgUserTituloEndereco}`, color: `${css?.colorUserTituloEndereco}`  }}>
                <div className='flex'>
                    <img src={'data:image/png;base64,' + css?.iconeLocalizacao} className='iconeLocalizacao'/>
                    <div className='nomeEndereco'> {capitalizeFirstLetter(t('enderecos'))} </div>
                </div>
                <div style={{ marginRight: '10px' }}>
                    <button className='Btn' onClick={()=> setIsOpenCadastrarEndereco(true)} style={{ background: `${css?.bgBtnUserAddEndereco}`, color: `${css?.colorBtnUserAddEndereco}` }}> 
                        <div className='sign'>
                            <img src={'data:image/png;base64,' + css?.iconeAdd} className='iconeSoma'/>
                        </div>
                        <div className='text' style={{ fontSize: '13px'}}> {t('novoEndereco')} </div>
                    </button>
                </div>
            </div>
            <div className='endereco'>
                {Array.isArray(endereco) ?( 
                    endereco.map((item, index)=>
                    <div key={index} className='descricaoendereco' style={{ background: `${css?.bgCardUserEndereco}`, color: `${css?.colorCardUserEndereco}` }} >
                        <div className='enderecoApelido'> 
                            <div className='flex between width90' onClick={() => toggleListaTamanhos(item.ID)}>
                                <div className='textoApelido'> {capitalizeFirstLetter(item.APELIDO.toLowerCase())} </div>
                                <div className='caixaBtnExpandir'>
                                    {listaTamanhosAtivos === item.ID 
                                        ? <img src={'data:image/png;base64,' + css?.iconeParaCima} className='tamanhoIcone'/>  
                                        : <img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='tamanhoIcone'/> 
                                    }
                                </div>
                            </div>
                            <div className='width10' >
                                <div className='btneditarendereco'>
                                    <button className='BtnEditar' onClick={()=> editarEndereco(item)}>
                                        <img src={'data:image/png;base64,' + css?.iconeEditar} className='iconeEditar'/>
                                    </button>
                                </div>
                            </div>
                        </div>
                            {listaTamanhosAtivos === item.ID ? (
                                <div>
                                    <div className='caixaEndereco' >
                                <div className='enderecoRua' >
                                    <div className='tituloRua' style={{ background: theme === 'dark' ? '#444444' : 'white' }} > {t('rua')} </div>
                                    <div className='textoRua' > {capitalizeFirstLetter(item.RUA.toLowerCase())} </div>
                                </div>
                                <div className='enderecoNumero'> 
                                    <div className='tituloRua' style={{ background: theme === 'dark' ? '#444444' : 'white' }}> {t('numero')} </div>
                                    <div className='textoRua' > {item.NUMERO} </div>
                                </div>
                            </div>
                            <div>
                                <div className='tituloRua' style={{ background: theme === 'dark' ? '#444444' : 'white' }}> {t('referencia')} </div>
                                <div className='textoRua' > {capitalizeFirstLetter(item.REFERENCIA.toLowerCase())} </div>
                            </div>
                            <div>
                                <div className='tituloRua' style={{ background: theme === 'dark' ? '#444444' : 'white' }}> {t('bairro')} </div>
                                <div className='textoRua' > {capitalizeFirstLetter(item.BAIRRO.toLowerCase())} </div>
                            </div>
                            <div>
                                <div className='tituloRua' style={{ background: theme === 'dark' ? '#444444' : 'white' }}> {t('cidade')} </div>
                                <div className='textoRua' > {capitalizeFirstLetter(item.CIDADE.toLowerCase())} </div>
                            </div>
                                </div>
                            ) : null}
                    </div>
                    )
                ) : null}
            </div>
                <div>
                    {isOpenModalEndereco && <ModalEndereco dadosConexao={dadosConexao} user={user} item={enderecoMudar} setIsOpenModalEndereco={setIsOpenModalEndereco} setAtualizarEnderecos={setAtualizarEnderecos} css={css} />}
                </div>
                <div>
                    {isOpenCadastrarEndereco && <ModalCadastrarEndereco dadosConexao={dadosConexao} user={user} setIsOpenCadastrarEndereco={setIsOpenCadastrarEndereco} setAtualizarEnderecos={setAtualizarEnderecos} css={css} />}
                </div>
        </div>
    )
}