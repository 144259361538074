import './janelamensagem.css'

export const JanelaMensagem = (mensagem, cor) => {
    const notification = document.createElement('div');
    notification.className = `janela + ${cor}`;
    notification.textContent = mensagem;
    
    document.body.appendChild(notification);
    
    setTimeout(() => {
      notification.style.opacity = '1';
    }, 10); 
    
    setTimeout(() => {
      notification.style.opacity = '0';
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 300); 
    }, 5000); 
  };