import { useState, useEffect } from 'react'
import TopoHeaderBar from '../../../header/TopoHeaderBar'
import Observacoes from '../Adicionais/AdicionaisFuncoes/Observacoes'
import PizzasInfo from './PizzasInfo'
import { useLocation } from 'react-router-dom';
import BtnCarrinho from './BtnCarrinhoPizza'
import Footer from '../../../Footer/Footer'
import '../../../../Styles/StylePizzas.css'
import '../../../../Styles/Styles.css'
import Topo from '../../../../auxiliares/utilidades/Topo'
import GrupoAdicionais from '../Adicionais/AdicionaisFuncoes/GrupoAdicionais'
import GrupoSaboresTamanho from './SaboresPizza/PizzasPeloTamanho/GrupoSaboresTamanho'

import GrupoDeSabores from './SaboresPizza/PizzasPorSabor/GrupoDeSabores'
import GrupoAdicionaisBusca from './SaboresPizza/PizzasPorSabor/GrupoAdicionaisBusca'
import BtnCarrinhoPizzaBusca from './BtnCarrinhoPizzaBusca'
import BtnsFlutuantes from '../../../BarrasNavegacao/BtnsFlutuantes';

export default function MainPizza({ css, dadosConexao }){
    const { state } = useLocation();
    //Info Pizza
    const { PIZZA_MISTA } = state;
    const { data } = state;
    const { grupo } = state;
    //saboresPizza
    const [listaSaboresPizzas, setListaSaboresPizzas] = useState([]);
    const [SaboresSelecionados, setSaboresSelecionados] = useState([]);
    const [valorTotalSabores, setValorTotalSabores] = useState('');
    const [valorTotalCustoPizza, setValorTotalCustoPizza] = useState(0)
    const [quantidadeTotal, setQuantidadeTotal] = useState(0)
    //Adicionais
    const [adicionalSelecionado, setAdicionalSelecionado] = useState([]);
    const [valorTotalItem, setValorTotalItem] = useState(0);
    const [valorTotalCusto, setValorTotalCusto] = useState(0);
    const [ID_GRUPO_OPCOES, setID_GRUPO_OPCOES] = useState('');
    //observaçoes
    const [observacoes, setObservacao] = useState('');

    const [totalCompra, setTotalCompra] = useState('');
    const [custoCompra, setCustoCompra] = useState('');

    const [saborOuTamanho, setSaborOuTamanho] = useState('');
    const [tamanhoParaAdd, setTamanhoParaAdd] = useState('');

    const cardapioAbreviado = localStorage.getItem('cardapio')
    const [obrigatorioopcional, setObrigatorioOpcional] = useState(false);

    const [tamanhoAlterado, setTamanhoAlterado] = useState('');
    useEffect(()=>{
        if(data.hasOwnProperty('TAMANHO')){
            setSaborOuTamanho('TAMANHO')
        } else if (data.hasOwnProperty('PRODUTO')) {
            setSaborOuTamanho('PRODUTO')
        }
    }, [data])
    return(
        <div className='pagina'>
            <BtnsFlutuantes
                css={css}
            />
        <div className='Main'>
            <div>
                <TopoHeaderBar
                    css={css}
                />
            </div>
            <div>
                <PizzasInfo
                    //Info Pizzas
                        Produto={data}
                        data={data}
                        PIZZA_MISTA={PIZZA_MISTA}
                        tamanhoParaAdd={tamanhoParaAdd}
                    //info Sabores
                        SaboresSelecionados={SaboresSelecionados}
                        valorTotalSabores={valorTotalSabores}
                        valorTotalCustoPizza={valorTotalCustoPizza}
                        quantidadeTotal={quantidadeTotal}
                    //Info Adicionais
                        ID_GRUPO_OPCOES={ID_GRUPO_OPCOES}
                        adicionalSelecionado={adicionalSelecionado}
                        valorTotalItem={valorTotalItem}
                        valorTotalCusto={valorTotalCusto}
                    //info observacoes
                        observacoes={observacoes}
                        setTotalCompra={setTotalCompra}
                        setCustoCompra={setCustoCompra}

                        listaSaboresPizzas={listaSaboresPizzas}
                        setListaSaboresPizzas={setListaSaboresPizzas}
                        css={css}
                        dadosConexao={dadosConexao}
                />
            </div>
            <div>
                {saborOuTamanho === "PRODUTO" ? (
                        <GrupoDeSabores
                                SaborBusca={data}
                                setValorTotalSabores={setValorTotalSabores}
                                setSaboresSelecionados={setSaboresSelecionados}
                                SaboresSelecionados={SaboresSelecionados}
                                listaSaboresPizzas={listaSaboresPizzas}
                                setListaSaboresPizzas={setListaSaboresPizzas}
                                setTamanhoParaAdd={setTamanhoParaAdd}
                                setTamanhoAlterado={setTamanhoAlterado}
                                
                                quantidadeTotal={quantidadeTotal}
                                setQuantidadeTotal={setQuantidadeTotal}
                                setValorTotalCustoPizza={setValorTotalCustoPizza}
                                css={css}
                                dadosConexao={dadosConexao}
                        />
                ) : null }
            </div>
            <div>
                {saborOuTamanho === "TAMANHO" ? (
                    <GrupoSaboresTamanho
                        data={data}
                        setValorTotalSabores={setValorTotalSabores}
                        setSaboresSelecionados={setSaboresSelecionados}
                        SaboresSelecionados={SaboresSelecionados}
                        listaSaboresPizzas={listaSaboresPizzas}
                        setListaSaboresPizzas={setListaSaboresPizzas}
                        
                        quantidadeTotal={quantidadeTotal}
                        setQuantidadeTotal={setQuantidadeTotal}
                        setValorTotalCustoPizza={setValorTotalCustoPizza}
                        css={css}
                        dadosConexao={dadosConexao}
                    />
                ) : null}
            </div>
            <div>
                {saborOuTamanho === "PRODUTO" ? (
                    <GrupoAdicionaisBusca
                        tamanhoParaAdd={tamanhoParaAdd}
                        adicionalSelecionado={adicionalSelecionado} 
                        setAdicionalSelecionado={setAdicionalSelecionado}
                        setID_GRUPO_OPCOES={setID_GRUPO_OPCOES}
                        setValorTotalCusto={setValorTotalCusto}
                        setValorTotalItem={setValorTotalItem}
                        setObrigatorioOpcional={setObrigatorioOpcional}
                        tamanhoAlterado={tamanhoAlterado}
                        setTamanhoAlterado={setTamanhoAlterado}
                        css={css}
                        dadosConexao={dadosConexao}
                    />
                ) : saborOuTamanho === "TAMANHO" ? (
                    <GrupoAdicionais
                        adicionalSelecionado={adicionalSelecionado} 
                        setAdicionalSelecionado={setAdicionalSelecionado}
                        setID_GRUPO_OPCOES={setID_GRUPO_OPCOES}
                        setValorTotalCusto={setValorTotalCusto}
                        setValorTotalItem={setValorTotalItem}
                        setObrigatorioOpcional={setObrigatorioOpcional}
                        css={css}
                        dadosConexao={dadosConexao}
                    />
                ) : null}
            </div>
            {cardapioAbreviado === "" ? (null) : (
                <>
                {cardapioAbreviado === "SIM" ? (null) : (
                <>
                <div>
                    <Observacoes
                        setObservacao={setObservacao}
                        css={css}
                    />
                </div>
            <div>
                {saborOuTamanho === "PRODUTO" ? (
                    <BtnCarrinhoPizzaBusca
                        Produto={tamanhoParaAdd}
                        SaboresSelecionados={SaboresSelecionados}
                        adicionalSelecionado={adicionalSelecionado}
                        observacoes={observacoes}
                        totalCompra={totalCompra}
                        totalCusto={custoCompra}
                        PIZZA_MISTA={grupo.PIZZA_MISTA}
                        quantidadeTotal={quantidadeTotal}
                        ID_GRUPO_OPCOES={ID_GRUPO_OPCOES}
                        obrigatorioopcional={obrigatorioopcional}
                        css={css}
                        dadosConexao={dadosConexao}
                    />
                ) : (
                    <BtnCarrinho
                        Produto={data}
                        SaboresSelecionados={SaboresSelecionados}
                        adicionalSelecionado={adicionalSelecionado}
                        observacoes={observacoes}
                        totalCompra={totalCompra}
                        totalCusto={custoCompra}
                        PIZZA_MISTA={PIZZA_MISTA}
                        quantidadeTotal={quantidadeTotal}
                        ID_GRUPO_OPCOES={ID_GRUPO_OPCOES}
                        obrigatorioopcional={obrigatorioopcional}
                        css={css}
                        dadosConexao={dadosConexao}
                    />
                )}
            </div>
                </>
            )}
                </>
            )}
            <div>
                <Topo
                    css={css}
                />
            </div>
        </div>
            <div>
                <Footer
                    css={css}
                />
            </div>
        </div>
    )
}