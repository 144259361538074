import { useState, useEffect} from 'react'
import '../../../../Styles/StyleEndereco.css'
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';

export default function modal({ user, item, dadosConexao, setIsOpenModalEndereco, setAtualizarEnderecos, css}){
    const [apelido, setApelido] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [referencia, setReferencia] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [idBairro, setIdBairro] = useState('');
    const [idCidades, setIdCidades] = useState('');
    const [cidadesAceitas, setCidadesAceitas] = useState('');
    const [bairrosAceitos, setBairrosAceitos] = useState('');
    const {t} = useTranslation();

    useEffect(()=>{
        setApelido(item.APELIDO)
        setRua(item.RUA)
        setReferencia(item.REFERENCIA)
        setNumero(item.NUMERO)
        setBairro(item.BAIRRO)
        setCidade(item.CIDADE)
        setIdBairro(item.ID_BAIRRO)
        setIdCidades(item.ID_CIDADES)
    }, [])
    useEffect(()=>{
        if(dadosConexao){
            handleCidades()
        }
    }, [dadosConexao])
    const handleCidades=()=>{
        const ROTAcidades = `http://${dadosConexao.api}:${dadosConexao.porta}/cidades`
        const dados_conexao = encodeURIComponent(ROTAcidades)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setCidadesAceitas(getdata.data)
            })
            .catch((error) => {
                const mensagem = 'Erro 517'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const handleCidade=(event)=>{
        setCidade(event)
        pegarBairro(event);
    }
    const pegarBairro=(event)=>{
        const ROTAbairros = `http://${dadosConexao.api}:${dadosConexao.porta}/bairros/${idCidades}`
        const dados_conexao2 = encodeURIComponent(ROTAbairros)
        api
            .post(`/requisicaoDados/${dados_conexao2}`)
            .then((response) => {
               setBairrosAceitos(response.data);
            })
            .catch((error) => {
                const mensagem = 'Erro 518'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const handlebairro=(event)=>{
        const [id, nome] = event.split('|')
        setBairro(nome)
        setIdBairro(id)
    }
    const excluirEndereco=(item)=>{
        const ROTAexcluirEndereco = `http://${dadosConexao.api}:${dadosConexao.porta}/excluirEndereco`
        const dados_conexao = encodeURIComponent(ROTAexcluirEndereco)
        api
            .post(`/requisicaoDadosPost/${dados_conexao}`, {
                id: item.ID
            })
            .then((response)=>{
                const mensagem = t('enderecoExcluido')
                const cor = "verde"
                    JanelaMensagem(mensagem, cor)
                    setAtualizarEnderecos(true)
                    setIsOpenModalEndereco(false)
            })
            .catch((error) => {
                const mensagem = 'Erro 529'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }   
    const Salvar = () => {
        const ROTAalterarEndereco = `http://${dadosConexao.api}:${dadosConexao.porta}/alterarEndereco`
        const dados_conexao2 = encodeURIComponent(ROTAalterarEndereco)
        if(idBairro !== "" && apelido !== "" && rua !== "" && numero !== ""){
        api
            .post(`/requisicaoDadosPost/${dados_conexao2}`, {
                firebase_token: user.uid,
                apelido: apelido,
                rua: rua,
                numero: numero,
                referencia: referencia,
                id_cidade: idCidades,
                id_bairro: idBairro,
                bairro: bairro,
                id_garline: item.ID
            })
            .then((response)=>{
                if(response.data === -500){
                    const mensagem = 'Erro 530'
                    const cor = "vermelho"
                    JanelaMensagem(mensagem, cor)
                } else if (response.data === 200){
                    const mensagem = t('EnderecoAtualizado')
                    const cor = "verde"
                        JanelaMensagem(mensagem, cor)
                        setIsOpenModalEndereco(false)
                        setAtualizarEnderecos(true)
                }
            })
            .catch((error) => {
                const mensagem = 'Erro 532'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
        } else {
            const mensagem = t('todosCampos')
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        }
    };

    return(
    <>
        <div className='modalUser' onClick={() => setIsOpenModalEndereco(false)} />
            <div className='centeredModal'>
            <div className='modalUsuario' style={{ background: `${css?.bgModalEditEndereco}`, color: `${css?.colorModalEditEndereco}` }} >
            <button className='closeBtn' onClick={() => setIsOpenModalEndereco(false)}> <div className='iconeBtnCloseModal'></div> </button>
                <div className='modalEditContent'> 
                    <div className='modalTitulo'> {capitalizeFirstLetter(t('editar'))} </div>
                    <div className='avisoobrigatório'> {capitalizeFirstLetter(t('todos'))} {t('camposObrigatorios')} {capitalizeFirstLetter(t('obrigatorios'))} </div>
                    <div className='itensModal'>
                        <div className="coolinput">
                            <label htmlFor="input" className="text" > * {t('apelido')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}` }}
                                type='text'
                                placeholder={t('exemploApelido')}
                                name='input'
                                className='input'
                                value={apelido}
                                onChange={(e)=> setApelido(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text" > * {t('rua')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}` }}
                                type='text'
                                placeholder={t('exemploRua')}
                                name='input'
                                className='input'
                                value={rua}
                                onChange={(e)=> setRua(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> * {t('numero')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}` }}
                                type='text'
                                placeholder='1000'
                                name='input'
                                className='input'
                                value={numero}
                                onChange={(e)=> setNumero(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text" > {t('referencia')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}` }}
                                type='text'
                                placeholder={t('exemploReferencia')}
                                name='input'
                                className='input'
                                value={referencia}
                                onChange={(e)=> setReferencia(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text" > * {t('cidade')} </label>
                            <select id="escolha" name="escolha" className='select' style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}` }} onChange={(event) => handleCidade(event.target.value)}>
                                <option key={item.ID_CIDADES} value={`${item.ID_CIDADES}`}> {item.CIDADE} </option>
                                {Array.isArray(cidadesAceitas) ? (
                                    cidadesAceitas.map((item, index) => (
                                        <option key={index} value={`${item.ID}`}> {item.NOME} </option>
                                    ))
                                ) : null}
                            </select>
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text" > * {t('bairro')} </label>
                            <select id="escolha" name="escolha" className='select' style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}` }} onChange={(event) => handlebairro(event.target.value)}>
                                <option key={item.ID_BAIRRO} value={`${item.ID_BAIRRO}`}> {item.BAIRRO} </option>
                                    {Array.isArray(bairrosAceitos) ? (
                                        bairrosAceitos.map((item, index) => (
                                            <option key={index} value={`${item.ID}|${item.DESCRICAO}`}> {item.DESCRICAO} </option>
                                        ))
                                    ) : null}
                                </select>
                            </div>
                        <div className='caixaBtnSalvarEnredereco'>
                            <button className='enderecobtn' onClick={()=> Salvar()} style={{ background: `${css?.bgBtnSalvarEndereco}`, color: `${css?.colorBtnSalvarEndereco}` }} > {t('salvar')} </button>
                        </div>
                        <div className='caixaBtnSalvarEnredereco'>
                            <button className='btnExcluirEndereco' onClick={()=> excluirEndereco(item)} style={{ background: `${css?.bgBtnExcluirEndereco}`, color: `${css?.colorBtnExcluirEndereco}` }} > {capitalizeFirstLetter(t('excluirEndereco'))} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};