import { useEffect, useState } from "react";
import { api } from "../../conecções/api";
import './modalhorario.css'
import '../../Styles/StyleImagens.css'
import { useTranslation } from "react-i18next";
import { JanelaMensagem } from '../../auxiliares/janelaFlutuante/JanelaMensagem'

export default function ModalHorarios({setModalHorarios, dadosConexao, css}){
    const [listaHorariosAtendimento, setListaHorariosAtendimento] = useState('');
    const {t} = useTranslation();

    useEffect(()=>{
        const ROTAexcluirEndereco = `http://${dadosConexao.api}:${dadosConexao.porta}/horariosAtendimento`
        const dados_conexao = encodeURIComponent(ROTAexcluirEndereco)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setListaHorariosAtendimento(getdata.data)
            })
            .catch((error) => {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }, [])

    return(
        <div>
            <div className='modalhorarios' onClick={() => setModalHorarios(false)} />
                <div className='centermodal'>
                    <div className='modalhorario' style={{ background: `${css?.bgModalHorario}`, color: `${css?.colorModalHorario}` }}>
                        <button className='closebtnmodal' onClick={() => setModalHorarios(false)}> 
                            <img src={'data:image/png;base64,' + css?.iconeClose} className='iconeClose'/>
                        </button>
                        <div className='modalcorpohorario'>
                            <div className="itensModalHORARIO titulos">
                                <div className="dia"> {t('dia')} </div>
                                <div> {t('abre')} </div>
                                <div> {t('fecha')} </div>
                            </div>
                        {Array.isArray(listaHorariosAtendimento) ? (
                                listaHorariosAtendimento.map((item, index)=>
                                    <div className="itensModalHORARIO" key={index}>
                                        <div className="dia">
                                            { item.DIA_SEMANA === '0' ? <div> {t('domingo')} </div>
                                            : item.DIA_SEMANA === '1' ? <div> {t('segunda')} </div>
                                            : item.DIA_SEMANA === '2' ? <div> {t('terça')} </div>
                                            : item.DIA_SEMANA === '3' ? <div> {t('quarta')} </div>
                                            : item.DIA_SEMANA === '4' ? <div> {t('quinta')} </div>
                                            : item.DIA_SEMANA === '5' ? <div> {t('sexta')} </div>
                                            : item.DIA_SEMANA === '6' ? <div> {t('sabado')} </div>
                                            : null } 
                                        </div>
                                        <div className="abre"> {item.ABRE.slice(0, 5)} </div>
                                        <div className="fecha"> {item.FECHA.slice(0, 5)} </div>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>
        </div>
    )
}