import { useState, useEffect, useMemo, useRef } from 'react';
import '../../../../Styles/Styles.css';
import '../../../../Styles/StyleImagens.css';
import { api } from '../../../../conecções/api';
import ProdutoList from './ProdutosList';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function SubGrupoList({ grupo, dadosConexao, infoClientes, css, theme }) {
  const [subGrupo, setSubGrupo] = useState([]);
  const [subGrupoAtivo, setSubGrupoAtivo] = useState(null);
  const [produtoCache, setProdutoCache] = useState({});
  const activeListRef = useRef(null);
  const tipoComanda = localStorage.getItem('tipoComanda')
  const {t} = useTranslation();
  
  useEffect(() => { 
    if(dadosConexao){
      handleListaSubGrupos();
    }
  }, [dadosConexao]);
  const handleListaSubGrupos=()=>{
    const ROTAsubGrupo = `http://${dadosConexao.api}:${dadosConexao.porta}/listaSubGrupos/${grupo.ID_GRUPO}/${tipoComanda}/${infoClientes.ID_CARDAPIO}`
    const dados_conexao = encodeURIComponent(ROTAsubGrupo)
      api
        .post(`/requisicaoDados/${dados_conexao}`)
        .then((getdata) => {
          setSubGrupo(getdata.data);
        })
        .catch((error) => {
          const mensagem = 'Erro 539'
          const cor = 'amarelo'
          JanelaMensagem(mensagem, cor)
        });
  }
  const toggleLista = (idSubGrupo) => {
    if (subGrupoAtivo === idSubGrupo) {
      setSubGrupoAtivo(null);
    } else {
      setSubGrupoAtivo(idSubGrupo);
      selecionarProdutos(idSubGrupo);
    }
  };
  const selecionarProdutos = (idSubGrupo) => {
    const novao = `http://${dadosConexao.api}:${dadosConexao.porta}/listaProdutos/${idSubGrupo}/${tipoComanda}/${infoClientes.ID_CARDAPIO}`
    const dados_conexao = encodeURIComponent(novao)
    if (!produtoCache[idSubGrupo]) {
      api
            .post(`/requisicaoDados/${dados_conexao}`) 
            .then((getdata) => {
              setProdutoCache((prevProdutoCache) => ({
                ...prevProdutoCache,
                [idSubGrupo]: getdata.data,
              }));
              if (activeListRef.current) {
                const barraFixaHeight = 90; 
                const scrollTop = activeListRef.current.getBoundingClientRect().top + window.pageYOffset - barraFixaHeight;
                window.scrollTo({ top: scrollTop, behavior: 'smooth' });
              }
            })
          .catch((error) => {
            const mensagem = 'Erro 540'
            const cor = 'amarelo'
            JanelaMensagem(mensagem, cor)
          });
    }
  };
  const produtos = useMemo(() => produtoCache[subGrupoAtivo] || [], [
    produtoCache,
    subGrupoAtivo,
  ]);
  const isSubGrupoAtivo = (idSubGrupo) => subGrupoAtivo === idSubGrupo;

  return (
    <div >
      {Array.isArray(subGrupo) ? (
        subGrupo.map((item, index) => (
          <div
            className='boxSubgrupos'
            key={index}
            id={item.ID_SUBGRUPO}
            ref={isSubGrupoAtivo(item.ID_SUBGRUPO) ? activeListRef : null}
            onClick={() => toggleLista(item.ID_SUBGRUPO)}
          >
            <div className='subgrupos' style={{ background: `${css?.bgSubGrupoTitulo}`, color: `${css?.colorSubGrupoTitulo}` }} >
              <div className='subgrupoTitulo'>{item.SUBGRUPO}</div>
              <div className='box-subgrupo-icon'>
                <div
                  className='subgrupo-icon' 
                >
                  {isSubGrupoAtivo(item.ID_SUBGRUPO)
                   ? <img src={'data:image/png;base64,' + css?.iconeParaCima} className='tamanhoIcone'/>  
                   : <img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='tamanhoIcone'/>
                  }
                </div>
              </div>
            </div>
            {isSubGrupoAtivo(item.ID_SUBGRUPO) && (
              <div className='subgrupolist-produto'>
                <ProdutoList produto={produtos} grupo={grupo} subGrupo={item} css={css} dadosConexao={dadosConexao} theme={theme} />
              </div>
            )}
          </div>
        ))
      ) : (
        <div> {t('semSubGrupos')} </div>
      )}
    </div>
    
  );
}
