import { useState, useEffect } from 'react'
import './buscaproduto.css'
import { api } from '../../../../../conecções/api'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../../../auxiliares/utilidades/PrimeiraMaiuscula'
import { formCurrency } from '../../../../../auxiliares/utilidades/FormataBRL'
import { formCurrencyArgentina } from '../../../../../auxiliares/utilidades/FormataARG'
import { formCurrencyUSD } from '../../../../../auxiliares/utilidades/FormataUSD'
import { useTranslation } from 'react-i18next'
import { JanelaMensagem } from '../../../../../auxiliares/janelaFlutuante/JanelaMensagem'

export default function buscaproduto({ css, theme, dadosConexao }){
    const [listaProdutos, setListaProdutos] = useState('');
    const { state } = useLocation();
    const { textBusca } = state;
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda
    const tipoComanda = localStorage.getItem('tipoComanda')

    useEffect(()=>{
        setSearchQuery(textBusca)
        if(dadosConexao){
            handleDadosEmpresa()
        }
    }, [textBusca, dadosConexao])
    const handleDadosEmpresa=()=>{
        const ROTAdadosEmpresa = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosEmpresa/${localStorage.getItem('codigoEmpresa')}`
        const dados_conexao2 = encodeURIComponent(ROTAdadosEmpresa)
        api
            .post(`/requisicaoDados/${dados_conexao2}`)
            .then((getdata) => {
                const idCardapio = getdata.data[0].ID_CARDAPIO
                handleProdutosFull(idCardapio)
            })
            .catch((error =>{
                const mensagem = t('451')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            }))
    }
    const handleProdutosFull=(idCardapio)=>{
        const ROTAlistaprodutos = `http://${dadosConexao.api}:${dadosConexao.porta}/listaProdutosFull/${tipoComanda}/${idCardapio}`
        const dados_conexao = encodeURIComponent(ROTAlistaprodutos)
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((getdata)=>{
                    setListaProdutos(getdata.data)
                })
                .catch((error)=>{
                    const mensagem = t('452')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                })
    }
    const filteredData = Array.isArray(listaProdutos) ? listaProdutos.filter((item) =>
        (item.PRODUTO && item.PRODUTO.toLowerCase().includes(searchQuery.toLowerCase()))
    ) : null
    const filtro = filteredData 
    const Adicionais = (data) => {
        if (data.PIZZA_MISTA === "SIM"){
            const grupo = {
                GRUPO: data.GRUPO,
                ID_GRUPO: data.ID_GRUPOS,
                PIZZA_MISTA: "SIM"
            }
            navigate ('/Pizzas', { state: { data, grupo } });
        } else if (data.PIZZA_MISTA === "NAO"){
            const grupo = {
                GRUPO: data.GRUPO,
                ID_GRUPO: data.ID_GRUPOS,
                PIZZA_MISTA: "NAO"
            }
            const ROTAadicionaisTamanho = `http://${dadosConexao.api}:${dadosConexao.porta}/infoProduto/${data.ID_PRODUTO}`;
            const dados_conexao = encodeURIComponent(ROTAadicionaisTamanho);
                api
                    .post(`/requisicaoDados/${dados_conexao}`)
                    .then((getdata)=>{
                        const data = getdata.data[0]
                        navigate ('/Adicionais', { state: { data, grupo } });
                    })
        }
    };

    return(
        <div>
             <div className='caixaPesquisaProdutos' style={{ background: `${css?.bgCaixaBusca}` }}>
                <input type='text' className='pesquisaproduto2'  style={{ background: `${css?.bgInputBusca}`, color: `${css?.colorInputBusca}` }} placeholder={capitalizeFirstLetter(t('busca')) + "..."} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
            </div>
            <div className='listaProcura'>
            {Array.isArray(filtro) ? (
                filtro.map((data, index)=>
                <div className={`cardProdutos ${theme === 'dark' ? '' : 'border'}`} style={{ background: `${css?.bgCardProdutoBusca}`, color: `${css?.colorCardProdutoBusca}` }} key={index}>
                <div className='boxProdutos' onClick={() => Adicionais(data)}>
                    <div className='produtosInfo'>
                        <div className='produtoNome'>
                            <div className='itemNome'>{capitalizeFirstLetter(data.PRODUTO.toLowerCase())}</div>
                        </div>
                        <div className='produtoValor'>
                            <div className='boxValor'>
                                    {data.VALOR_MINIMO > 0 ? (
                                        <div>
                                            <div>
                                                {
                                                    moeda === 'BRL' ? formCurrency.format(data.VALOR_MINIMO) :
                                                    moeda === 'ARS' ? formCurrencyArgentina.format(data.VALOR_MINIMO) : 
                                                    moeda === 'USD' ? formCurrencyUSD.format(data.VALOR_MINIMO) : null
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div>
                                                {
                                                    moeda === 'BRL' ? formCurrency.format(data.VALOR_VENDA) :
                                                    moeda === 'ARS' ? formCurrencyArgentina.format(data.VALOR_VENDA) : 
                                                    moeda === 'USD' ? formCurrencyUSD.format(data.VALOR_VENDA) : null
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
                )
            ) : null}
            </div>
        </div>
    )
}