import { useState } from 'react'
import './buscaproduto.css'
import { useNavigate  } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../../../auxiliares/utilidades/PrimeiraMaiuscula';
import { useTranslation } from 'react-i18next';


export default function barradebusca({ css }){
    const [textBusca, setTextBusca] = useState('');
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleBuscar =(textBusca)=>{
        navigate ('/BuscaProduto', { state: { textBusca } });
    }

    return(
        <div className='caixaPesquisaProdutos' style={{ background: `${css?.bgCaixaBusca}` }} >
            <input type='text' className='pesquisaproduto' style={{ background: `${css?.bgInputBusca}`, color: `${css?.colorInputBusca}` }} placeholder={capitalizeFirstLetter(t('busca')) + "..."} value={textBusca} onChange={(e) => setTextBusca(e.target.value)}/>
            <button className='btnPesquisa' onClick={()=> handleBuscar(textBusca)} style={{ background: `${css?.bgBtnBusca}` }}> 
                <img src={'data:image/png;base64,' + css?.iconeBusca} className='icone30P'/>
            </button>
        </div>
    )
}