import React, { useEffect, useState } from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './app.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setInitialTheme, getInitialTheme, themes } from './Styles/styleGeral';
import { retornaDadosConexao } from './funcoes/Funcoes';

const queryClient = new QueryClient();

const RootComponent = () => {
  const storedTheme = localStorage.getItem('initialTheme');
  const [theme, setTheme] = useState(storedTheme || getInitialTheme());
  const [css, setCss] = useState(theme === 'dark' ? themes.dark : themes.light); 

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigoEmpresa = urlParams.get('17011d0b'); 
    handleDadosConexao(codigoEmpresa);
  }, []);

  const handleDadosConexao = async (codigoEmpresa) => {
    const dados = await retornaDadosConexao(codigoEmpresa);
    setInitialTheme(dados);
    if (dados?.theme) { 
      if (theme !== dados.theme) { 
        setTheme(dados.theme); 
        setCss(dados.theme === 'dark' ? themes.dark : themes.light); 
        localStorage.setItem('initialTheme', dados.theme);
      }
    }
  };
  

  const toggleTheme = (e) => {
    const newTheme = e.target.checked ? 'dark' : 'light';
    if (theme !== newTheme) {
      setTheme(newTheme);
      setCss(newTheme === 'dark' ? themes.dark : themes.light);
      localStorage.setItem('initialTheme', newTheme);
    }
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <div className="app" style={{ background: css?.backGroundPagina }}>
          <App 
            css={css}
            toggleTheme={toggleTheme}
            theme={theme}
          />
        </div>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
