import { useDispatch } from 'react-redux'
import '../../../Styles/StyleCarrinho.css'
import '../../../Styles/StyleImagens.css'
import { formCurrency } from '../../../auxiliares/utilidades/FormataBRL';
import { incrementQuantity, decrementQuantity } from '../../../redux/cartSlice';
import Decimal from 'decimal.js';
import { formCurrencyArgentina } from '../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../auxiliares/utilidades/FormataUSD';
import { useState } from 'react';

export function TotalItem({ itemquantity, itemid, itemvalor, css, dadosConexao }){
  const dispatch = useDispatch()
  const [dados, setDados] = useState(JSON.parse(localStorage.getItem('dados')));
  const moeda = dadosConexao?.moeda

    const TotalItem=()=>{
      let valoritem = itemvalor || 0 
        const totalitem = new Decimal(valoritem)*(itemquantity) || 0 
      return totalitem.toFixed(2)
    }

  return(
    <div className='CartBox-valor' >
      <div className='cartTotal-valor'>
        <div>
          {
            moeda === 'BRL' ? formCurrency.format(TotalItem()) :
            moeda === 'ARS' ? formCurrencyArgentina.format(TotalItem()) : 
            moeda === 'USD' ? formCurrencyUSD.format(TotalItem()) : null
          }
        </div>
      </div>
      <div className='boxFuncao-cart'>
        <div className='cart-btn-quantia'>
          <button className='btn' onClick={() => dispatch(decrementQuantity(itemid))}>
            <img src={'data:image/png;base64,' + css?.iconeMenos} className='iconeCart'/>
          </button>
        </div>
          <div className='cart-item-quantia'>{itemquantity}</div>
        <div className='cart-btn-quantia'>
          <button className='btn cartMais' onClick={() => dispatch(incrementQuantity(itemid))}>
            <img src={'data:image/png;base64,' + css?.iconeMais} className='iconeCart'/>
          </button>
        </div>
      </div>
    </div>
  )
}



