import { useState, useEffect } from 'react'
import { api } from '../../../conecções/api';
import './pedidoscorpo.css'
import '../../../Styles/StyleImagens.css'
import { formCurrency } from '../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../auxiliares/utilidades/FormataUSD';
import Decimal from 'decimal.js';
import ModalRecargaCartao from './modalRecargaCartao/ModalRecargaCartao'
import ModalPagarConta from './ModalPagarConta/ModalPagarConta'
import { JanelaMensagem } from '../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function PedidosCorpo({ css, dadosConexao }){
    const [dadosCompraPedido, setDadosCompraPedido] = useState([]);
    const [totalPedido, setTotalPedido] = useState(0);
    const [infoComanda, setInfoComanda] = useState('');
    const [modalRecargaCartao, setModalRecargaCartao] = useState(false);
    const [atualizarCredito, setAtualizarCredito] = useState(false);
    const [modalPagarConta, setModalPagarConta] = useState(false);
    const [atualizarConta, setAtualizarConta] = useState(false);
    const [usaPix, setUsaPix] = useState('');
    const [caixaAberto, setCaixaAberto] = useState('');
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda
    const tipoComanda = localStorage.getItem('tipoComanda');
    const numeroComanda = localStorage.getItem('numeroComanda');
    console.log(totalPedido)

    useEffect(()=>{
        if(dadosConexao){    
            handleListaItensPedido()
            handleDadosPix()
            if(tipoComanda === "CARTAO"){
                handleInfoComanda()        
            }
            handleCaixa()
        }
    }, [dadosConexao])
    useEffect(()=>{
        if(atualizarCredito === true){
            handleInfoComanda()
            setAtualizarCredito(false)
        }
    }, [atualizarCredito])
    useEffect(()=>{
        if(atualizarConta === true){
            handleListaItensPedido()
            setAtualizarConta(false)
        }
    }, [atualizarConta])
    const handleDadosPix=()=>{
        const ROTAsubGrupo = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosPIX`
        const dados_conexao = encodeURIComponent(ROTAsubGrupo)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata) => {
                const checkpix = Array.isArray(getdata.data) ? true : false
                    setUsaPix(checkpix)
            })
            .catch((error) => {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const handleListaItensPedido=()=>{
        const dados_conexao = encodeURIComponent(`http://${dadosConexao.api}:${dadosConexao.porta}/listaItensPedido/${tipoComanda}/${numeroComanda}`)
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((getdata)=>{
                    const dadosCompra = Array.isArray(getdata.data) ? getdata.data : ''
                    setDadosCompraPedido(dadosCompra);
                })
                .catch((error) => {
                    const mensagem = t('Erro 561')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const handleInfoComanda=()=>{
        const ROTAinfoComanda = `http://${dadosConexao.api}:${dadosConexao.porta}/infoComanda/${numeroComanda}`
        const dados_conexao2 = encodeURIComponent(ROTAinfoComanda)
            api
                .post(`/requisicaoDados/${dados_conexao2}`)
                .then((getdata)=>{
                    const infoComanda = Array.isArray(getdata.data) ? getdata.data[0] : ''
                        setInfoComanda(infoComanda)
                })
                .catch((error) => {
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const handleCaixa=()=>{
        const dados_conexao3 = encodeURIComponent(`http://${dadosConexao.api}:${dadosConexao.porta}/verificarCaixa`)
            api
                .post(`/requisicaoDados/${dados_conexao3}`)
                .then((getdata)=>{
                    setCaixaAberto(getdata.data)
                })
                .catch((error)=>{
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                })
    }
    useEffect(() => {
        if (Array.isArray(dadosCompraPedido)) {
          let total = new Decimal(0);
          dadosCompraPedido.forEach(item => {
            total = total.plus(new Decimal(item.TOTAL || 0));
        });
          setTotalPedido(0.01);
        } 
    }, [dadosCompraPedido]);
    const handleRecarregar =()=>{
        if(caixaAberto > 1){
            setModalRecargaCartao(true)
        } else if (caixaAberto === 0){
            const mensagem = 'Erro 561'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        } else if (caixaAberto === -1){
            const mensagem = 'Erro 562'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        }
    }
    const handlePagarConta =()=>{
        if(caixaAberto > 1){
            if(totalPedido <= 0.00 ){
                const mensagem = t('semValorPagamento')
                const cor = 'amarelo'
                JanelaMensagem(mensagem, cor)
            } else {
                setModalPagarConta(true)
            }
        } else if (caixaAberto === 0){
            const mensagem = 'Erro 561'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        } else if (caixaAberto === -1){
            const mensagem = 'Erro 562'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        }
    }
    return(
        <div>
            <div className='pedidosClienteCard' style={{ background: `${css?.bgPedidosClientCard}`, color: `${css?.colorPedidosClientCard}` }} >
                <div className='clienteCardTipo'>
                    <div className='cardMargin'> {tipoComanda} </div>
                    <div className='cardMargin'> n° {numeroComanda}  </div>
                </div>
            </div>
            <div className='quadroDetalhesPedido' style={{ background: `${css?.bgPedidoClientLista}`, color: `${css?.colorPedidosClientLista}` }} >
                <div className='detalhesPedidoTitulo'> {t('items')} </div>
                <div className='detalhesPedidoBox'> 
                    <div className='detalhesPedidoQtd'> {t('qtd')} </div>
                    <div className='detalhesPedidoDesc'> {t('descrição')} </div>
                    <div className='detalhesPedidoValor'> {t('valor')} </div>
                </div>
                <div>
                    {Array.isArray(dadosCompraPedido) ? (
                        dadosCompraPedido.map((item, index)=>
                        <div key={index} className='pedidoItemCard' >
                            <div className='itemCardLinha'>
                                <div className='itemPedidoQTD'> {item.QTDE_COM} </div>
                                <div className='itemPedidoDesc'> {item.DESCRICAO} </div>
                                <div className='itemValor'>
                                    {
                                        moeda === 'BRL' ? formCurrency.format(item.TOTAL) :
                                        moeda === 'ARS' ? formCurrencyArgentina.format(item.TOTAL) : 
                                        moeda === 'USD' ? formCurrencyUSD.format(item.TOTAL) : null
                                    }  
                                </div>
                            </div>
                            {item.SABORES !== null || item.OPCOES !== null ? (
                                <div className='itemCardLinha textOpcional'>
                                    <div className='itemCardIcone'>
                                        <img src={'data:image/png;base64,' + css?.iconeNota} className='iconeItemCardIcone'/>
                                    </div>
                                    <div className='itemCardOpcoes'>
                                        {item.SABORES !== null ? (
                                            <div className='itemSabores'> {item.SABORES !== null ? (item.SABORES.toLowerCase()) : null} </div>
                                        ) : null}
                                        <div className='itemOpcionais'>
                                            <div className='nomeOpcional'> {item.OPCOES !== null ? (item.OPCOES.toLowerCase()) : null} </div>
                                        </div>
                                        <div className='itemObservacoes'> {item.OBSERVACOES} </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )
                    ) : null}
                </div>
            </div>
            <div className='TotalValorPedidos' style={{ background: `${css?.bgPedidoClientTotal}`, color: `${css?.colorPedidosClientTotal}` }} >
                <div> {t('Total')} </div>
                <div>
                    {
                        moeda === 'BRL' ? formCurrency.format(totalPedido) :
                        moeda === 'ARS' ? formCurrencyArgentina.format(totalPedido) : 
                        moeda === 'USD' ? formCurrencyUSD.format(totalPedido) : null
                    } 
                </div>
            </div>
            {tipoComanda === "CARTAO" ? (
                <div>
                    <div className='caixaDetalhesCartao' style={{ background: `${css?.bgPedidoClientDetalhesCartao}`, color: `${css?.colorPedidosClientDetalhesCartao}` }} >
                        <div className='caixaCartao'>
                            <div className='nametext'> {t('cartao')} </div>
                            <div className='font16'>{infoComanda.TIPO}</div>
                        </div>
                        <div className='caixaCartao'>
                            <div className='nametext'> {t('debitoAtual')} </div>
                            <div className='font16'>
                                {
                                    moeda === 'BRL' ? formCurrency.format(infoComanda.DEBITO_ATUAL) :
                                    moeda === 'ARS' ? formCurrencyArgentina.format(infoComanda.DEBITO_ATUAL) : 
                                    moeda === 'USD' ? formCurrencyUSD.format(infoComanda.DEBITO_ATUAL) : null
                                }
                            </div>
                        </div>
                        <div className='caixaCartao'>
                            <div className='nametext'> {t('limiteCredito')} </div>
                            <div className='font16'>
                                {
                                    moeda === 'BRL' ? formCurrency.format(infoComanda.LIMITE_CREDITO) :
                                    moeda === 'ARS' ? formCurrencyArgentina.format(infoComanda.LIMITE_CREDITO) : 
                                    moeda === 'USD' ? formCurrencyUSD.format(infoComanda.LIMITE_CREDITO) : null
                                } 
                            </div>
                        </div>
                        <div className='caixaCartao'>
                            <div className='nametext'> {t('saldoIndisponivel')} </div>
                            <div className='font16'>
                                {
                                    moeda === 'BRL' ? formCurrency.format(infoComanda.SALDO_DISPONIVEL) :
                                    moeda === 'ARS' ? formCurrencyArgentina.format(infoComanda.SALDO_DISPONIVEL) : 
                                    moeda === 'USD' ? formCurrencyUSD.format(infoComanda.SALDO_DISPONIVEL) : null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        usaPix === false ? (
                            <div></div>
                        ) : (
                            infoComanda.TIPO === "1 - PÓS-PAGO" ? (
                                <div className='caixaCarregarC'>
                                    <button className='btnCarregarC' style={{ background: `${css?.bgPedidoClientPagar}`, color: `${css?.colorPedidosClientPagar}` }} onClick={handlePagarConta}> {t('pagarConta')} </button>
                                </div>
                            ) : infoComanda.TIPO === "2 - PRÉ-PAGO" ? (
                                <div className='caixaCarregarC'>
                                    <button className='btnCarregarC' style={{ background: `${css?.bgPedidoClientPagar}`, color: `${css?.colorPedidosClientPagar}` }} onClick={handleRecarregar}> {t('inserirComPix')} </button>
                                </div>
                            ) : null 
                        )
                    }
                </div>
            ) : (
                <div className='caixaCarregarC'>
                    <button className='btnCarregarC'  style={{ background: `${css?.bgPedidoClientPagar}`, color: `${css?.colorPedidosClientPagar}` }}  onClick={handlePagarConta}> {t('pagarConta')} </button>
                </div>
            )}
            {modalRecargaCartao && <ModalRecargaCartao dadosConexao={dadosConexao} setModalRecargaCartao={setModalRecargaCartao} setAtualizarCredito={setAtualizarCredito} css={css} />}
            {modalPagarConta && <ModalPagarConta dadosConexao={dadosConexao} setModalPagarConta={setModalPagarConta} setAtualizarConta={setAtualizarConta} setAtualizarCredito={setAtualizarCredito} totalPedido={totalPedido} css={css} />}
        </div>
    )
}