import { useState, React } from 'react';
import './StyleHeaders.css'
import '../../Styles/StyleImagens.css'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from '../Usuarios/LoginPage/Firebase/firebaseConfig';
import { api } from '../../conecções/api';
import { JanelaMensagem } from '../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../auxiliares/utilidades/PrimeiraMaiuscula'

export default function cartHeader({ css }){
    const navigate = useNavigate();
    const [pagina, setPagina] = useState('')
    const location = useLocation();
    const [aparelho, setAparelho] = useState('');
    const [dadosUser, setDadosUser] = useState('');
    const pagamento = localStorage.getItem('pagamento')
    const cardapaioAbreviado = localStorage.getItem('cardapio')
    const {t} = useTranslation();

    useEffect(()=>{
        handleDetectarMobile()
        handleUser()
    }, [])
    const handleDetectarMobile=()=> { 
      if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      ){
         setAparelho(true)
       }
      else {
         setAparelho(false)
       }
    }
    useEffect(()=>{
        const path = location.pathname;
        const nome = path.substring(path.lastIndexOf('/') + 1);
            setPagina(nome);
    }, [location.pathname])
    const Voltar =()=>{
        navigate(-1)
    }
    const handleUser=()=>{
        auth.onAuthStateChanged((user) => {
            if(user !== '' && user !== null){
                const uidToken = user.uid
                api
                    .get(`/pegarDadosCliente/${uidToken}`)
                    .then((getdata)=>{
                        setDadosUser(getdata.data[0])
                    })
                    .catch((error) => {
                        const mensagem = t('Erro 509')
                        const cor = 'vermelho'
                        JanelaMensagem(mensagem, cor)
                    });
            }
        });
    } 
    const handlePaginaUser=()=>{
        navigate('/Usuario')
    }
    const navegacao=()=>{
        navigate('/Main')
    }
    return(
        <div className='cartHeaderTopo' style={{ background: `${css?.bgBarTopo}`, color: `${css?.colorBarTopo}` }}>
            {
                pagamento === 'SIM' ? (
                    <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
                        <div className='caixaPaginaNome'> {capitalizeFirstLetter(t('pagamento'))} </div>
                    </div>
                ) : (
                    <div className='cartHeaderTopoEsquerda'>
                        <div className='caixaSeta'>
                            {
                                <div  onClick={Voltar}>
                                    <img src={'data:image/png;base64,' + css?.iconeVoltar} className='icone25P'/>
                                </div>
                            }
                        </div>
                        <div className='caixaPaginaNome'>
                            {
                                pagina === 'Pedidos' ? capitalizeFirstLetter(t('pedidos')) :     
                                pagina === 'Carrinho' ? capitalizeFirstLetter(t('carrinho')) :     
                                pagina === 'Adicionais' ? capitalizeFirstLetter(t('adicionais')) :   
                                pagina === 'Pizzas' ? capitalizeFirstLetter(t('pizza')) :   
                                pagina === 'BuscaProduto' ? t('buscaproduto') :   
                                pagina === 'Usuario' ? capitalizeFirstLetter(t('usuario')) :   
                                pagina === 'MeusPedidos' ? t('meuspedidos') :   
                                pagina === 'DetalhesPedido' ? t('detalhespedido') :   
                                pagina === 'Areapix' ? t('areapix') :   
                                pagina === 'loginAdm' ? t('loginadm') :   
                                pagina
                            }
                        </div>
                    </div>
                )
            }
            {
                aparelho === true ? (
                    <div className='logo-garline-header' onClick={()=> navegacao()}>
                        <img src={'data:image/png;base64,' + css?.imagemLogoHeader} className='logoGarline'/>
                    </div>
                ) : (
                    pagamento === 'SIM' || cardapaioAbreviado === 'SIM' ? null : (
                        <div style={{ marginRight: '10px' }} className='flex'>
                            <div style={{ display: 'grid', placeItems: 'center', marginRight: '20px', fontSize: '18px'}} className='' >
                                {dadosUser !== '' && dadosUser !== null ? dadosUser.nome : t('loginHeader') }
                            </div>
                            <button className='Btn' style={{ height: '30px', marginTop: '5px', marginBottom: '5px', background: `${css?.bgIconeUser}`, color: `${css?.colorIconeUser}` }} onClick={()=> handlePaginaUser()}> 
                            <div className='sign'>
                                <img src={'data:image/png;base64,' + css?.iconeUser} className='iconeUser'/>
                            </div>
                            <div className='text'> {t('usuario')} </div>
                            </button>
                        </div>
                    )
                )
            }
        </div>
    )
}