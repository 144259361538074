import Header from '../header/Header';
import BarraDeBusca from './Produtos/BuscaProduto/paginas/BarraDeBusca';
import GrupoList from './Produtos/ProdutosLista/GrupoList';
import Footer from '../Footer/Footer'
import '../../Styles/Styles.css'
import BtnsFlutuantes from '../BarrasNavegacao/BtnsFlutuantes';

export default function Main({ css, dadosConexao, toggleTheme, theme }) {
    
  return ( 
    <div className='teste'>
      <div className='Main'>
      <BtnsFlutuantes
        css={css}
        dadosConexao={dadosConexao}
      />
        <div>
          <Header
            css={css}
            toggleTheme={toggleTheme}
            theme={theme}
            dadosConexao={dadosConexao}
          />
        </div>
        <div>
          <BarraDeBusca
            css={css}
            dadosConexao={dadosConexao}
          />
        </div>
        <div className='lista-Main-Page' >
          <GrupoList
            css={css}
            theme={theme}
            dadosConexao={dadosConexao}
          />
        </div>
      </div>
        <div >
          <Footer
            css={css}
            dadosConexao={dadosConexao}
          />
        </div>
    </div>
  );
}