import { useEffect, useState } from 'react';
import '../../../Styles/StyleCarrinho.css';
import '../../../Styles/StyleImagens.css';
import { useSelector, useDispatch } from 'react-redux';
import { api } from '../../../conecções/api';
import Decimal from 'decimal.js';
import { formCurrency } from '../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../auxiliares/utilidades/FormataUSD';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../../redux/cartSlice';
import { JanelaMensagem } from '../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../auxiliares/utilidades/PrimeiraMaiuscula';
import OneSignal from 'react-onesignal';
import ModalPagamentoPix from './ModalPagamentoPix'
import FormasDePagamento from './FormasDePagamento/FormasDePagamento';

export function CarrinhoBarPagamento({ user, dadosConexao, mesaSelecionada, observacoesCart, tipoComanda, pagamentoSelecionado, enderecoSelecionado, taxaEntrega, css }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [compra, setCompra] = useState([]);
  const [totalCart, setTotalCart] = useState('');
  const [idNotificacao, setIdNotificacao] = useState('');
  const [idClienteRestaurante, setIdClienteRestaurante] = useState(null);
  const [Pedido, setPedido] = useState([]);
  const [infoClientes, setInfoClientes] = useState(null)
  const [caixaAberto, setCaixaAberto] = useState('');
  const [modalPagamentoPix, setModalPagamentoPix] = useState(false);
  const [dadosCompraPix, setDadosCompraPix] = useState('');
  const moeda = dadosConexao?.moeda
  const numeroComanda = localStorage.getItem('numeroComanda');
  const cart = useSelector(state => state.cart)
  const items_pedido = compra
  const horaAbertura = infoClientes? infoClientes.ABERTURA : 0
  const horaFechamento = infoClientes? infoClientes.FECHAMENTO : 0
  const { t } = useTranslation();
  const uidToken = user? user.uid : ''

  useEffect(()=>{
    handleNotificacao()
  }, [])
  useEffect(()=>{
    if(dadosConexao){
      if(cart.length === 0){
        setCompra(null)
      }
      if(uidToken === null || uidToken === undefined || uidToken === ""){
        setIdClienteRestaurante(null)
      } else {
        handlePegarIdCliente()
      }
        setPedido(cart)
        handleDadosEmpresa()
        handleVerificarCaixa()
    }
  }, [cart, uidToken])
  const handleVerificarCaixa=()=>{
    const ROTACaixa = `http://${dadosConexao.api}:${dadosConexao.porta}/verificarCaixa`
    const dados_conexao3 = encodeURIComponent(ROTACaixa)
    api
        .post(`/requisicaoDados/${dados_conexao3}`)
        .then((getdata)=>{
            setCaixaAberto(getdata.data)
        })
        .catch((error)=>{
          const mensagem = t('caixaFechado')
          const cor = 'vermelho'
          JanelaMensagem(mensagem, cor)
        })
  }
  const handlePegarIdCliente=()=>{
      const ROTAidCliente = `http://${dadosConexao.api}:${dadosConexao.porta}/retornaIdCliente/${uidToken}`
      const dados_conexao = encodeURIComponent(ROTAidCliente)
        api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata)=>{
            setIdClienteRestaurante(getdata.data)
          })
          .catch((error)=>{
            const mensagem = t('clienteNaoEncontrado')
            const cor = 'amarelo'
            JanelaMensagem(mensagem, cor)
          })
  }
  const handleDadosEmpresa=()=>{
    const ROTAdadosEmpresa = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosEmpresa/${dadosConexao.codigoEmpresa}`
    const dados_conexao22 = encodeURIComponent(ROTAdadosEmpresa)
      api
        .post(`/requisicaoDados/${dados_conexao22}`)
        .then((getdata) => {
          setInfoClientes(getdata.data[0]);
        })
        .catch((error) => {
          const mensagem = 'Erro 548'
          const cor = 'vermelho'
          JanelaMensagem(mensagem, cor)
        });
  }
  const handleNotificacao=()=>{
   const notificacao = OneSignal.User.PushSubscription.optedIn;
   if(notificacao === true){
    const userid = OneSignal.User.PushSubscription.id;
    setIdNotificacao(userid)
   }
   else {
    setIdNotificacao('')
   }
  } 
  useEffect(() => {
    if (cart && Array.isArray(cart)) {
      let total = new Decimal(0);
      cart.forEach(item => {
        total = total.plus(new Decimal(item.quantity || 0).times(item.totalCompra || 0));
      });
      total = total.plus(new Decimal(taxaEntrega));
      setTotalCart(total.toFixed(2));
    }
  }, [cart, taxaEntrega]);
  const chamadaPedido=()=>{
    const cor = 'amarelo'
    if (tipoComanda === "DELIVERY"){
      if(user === null){
        const mensagem = t('necessarioLogin') 
          JanelaMensagem(mensagem, cor)
          navigate('/Usuario')
      } else if (enderecoSelecionado === null){
        const mensagem = t('escolhaEndereco') 
          JanelaMensagem(mensagem, cor)
      } else if(pagamentoSelecionado === ""){
        const mensagem = t('escolhaPagamento') 
          JanelaMensagem(mensagem, cor)
      } else if( cart.length === 0){
        const mensagem = t('adicionePedido')
          JanelaMensagem(mensagem, cor)
      } else {
        EnviarPedidoAPI()
      } 
    } 
    else if (tipoComanda === "CARTAO"){
      const cor = 'amarelo'
        if(mesaSelecionada === null){
          const mensagem = t('selecioneLocalizacao')
            JanelaMensagem(mensagem, cor)
        } else if (cart.length === 0){
          const mensagem = t('adicionePedido')
            JanelaMensagem(mensagem, cor)
        } else {
          EnviarPedidoAPI()
        }
    }
    else if (tipoComanda === "MESA"){
      const cor = 'amarelo'
      if (cart.length === 0){
        const mensagem = t('adicionePedido')
          JanelaMensagem(mensagem, cor)
      } else {
        EnviarPedidoAPI()
      }
    }
  }
  useEffect(() => {
    if (Pedido && Array.isArray(Pedido)) {
    const updatedCompra = Pedido.map((item) => {
      const itemExistente = compra.find((compraItem) => compraItem.id === item.id);
      if (!itemExistente) {
        let novoItemPedido = {
          id: item.id,
          id_produto: "",
          id_grade: "",
          id_tamanho: "",
          id_unidade: "",
          valor_custo: item.custoTotal,
          valor_venda: "",
          //retirados: item.fichaTecnica.filter((item) => item.retirado === true).map((item) => item.ID),
          pizza_mista: item.tipo,
          quantidade: item.quantity,
          observacao: item.observacoes,
          opcional: item.adicionalSelecionado.map((item)=>({
            id_opcional: item.ID,
            descricao: item.DESCRICAO,
            valor_venda: item.VALOR_VENDA,
            quantidade: item.quantidade,
            dividir: item.DIVIDIR 
          })),
          sabores: []
        };
        if (item.tipo === "NAO") {
          novoItemPedido = {
            ...novoItemPedido,
            id_unidade: item.produto.ID_UNIDADE,
            id_produto: item.produto.ID_PRODUTO,
            valor_venda: item.totalCompra,
            id_grade: item.tamanhoEscolhido !== null ? item.tamanhoEscolhido.ID_GRADE : "",
            id_tamanho: item.tamanhoEscolhido !== null ? item.tamanhoEscolhido.ID : "",
          };
        } else if (item.tipo === "SIM") {
          novoItemPedido = {
            ...novoItemPedido,
            id_produto: item.produto.ID_PRODUTO,
            id_unidade: item.produto.ID_UNIDADE,
            valor_venda: item.totalCompra,
            sabores: item.SaboresSelecionados.map((sabor) => ({
              sabor: sabor.PRODUTO,
              id_grade: sabor.ID_GRADE,
              valor_venda: sabor.VALOR_VENDA,
              quantidade: sabor.quantidade
            }))
          };
        }
        return novoItemPedido;
      }
       else {
        return {
          ...itemExistente,
          quantidade: item.quantity
        };
      }
    });
      setCompra(updatedCompra);
    }
  }, [Pedido, setCompra]);  
  const pixInfoSem = {
  }
  const EnviarPedidoAPI =()=>{
    if(caixaAberto > 1){
      if(pagamentoSelecionado.DESCRICAO === "PIX"){
        const dadoscompra = {
          token_notificacao: idNotificacao,
          pagamento: pagamentoSelecionado? pagamentoSelecionado.ID : "",
          pagamento_descricao: pagamentoSelecionado? pagamentoSelecionado.DESCRICAO : "",
          id_endereco: enderecoSelecionado? enderecoSelecionado.ID : '',
          id_garcom: "",
          id_cliente: idClienteRestaurante? idClienteRestaurante : "",
          numero_comanda: numeroComanda,
          tipo_comanda: tipoComanda,
          localizacao: "",
          total: 0.01,
          taxa_entrega: taxaEntrega,
          observacoes_pedido: tipoComanda === "DELIVERY" ? observacoesCart : "",
          items_pedido: items_pedido
        }
        setDadosCompraPix(dadoscompra)
        setModalPagamentoPix(true)
      } else {
        const ROTAinserirPedido = `http://${dadosConexao.api}:${dadosConexao.porta}/inserirPedido`
        const dados_conexao = encodeURIComponent(ROTAinserirPedido)
        const ROTAdadosEmpresa = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosEmpresa/${localStorage.getItem('codigoEmpresa')}`
        const dados_conexao22 = encodeURIComponent(ROTAdadosEmpresa)
          api
            .post(`/requisicaoDados/${dados_conexao22}`)
            .then((getdata) => {
              const dados22 = getdata.data[0]
              if(dados22.ABERTO_FECHADO === "ABERTO"){
                handleFazerPedido(dados_conexao)
              } else {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'amarelo'
                  JanelaMensagem(mensagem, cor)
              }
            })
            .catch((error) => {
              const mensagem = t('frase.RotaGenerico')
              const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
      }
    } 
    else if (caixaAberto === 0){
        const mensagem = 'Erro 580'
        const cor = 'amarelo'
        JanelaMensagem(mensagem, cor)
    } else if (caixaAberto === -1){
        const mensagem = t('caixaFechado')
        const cor = 'amarelo'
        JanelaMensagem(mensagem, cor)
    }
  }
  const handleFazerPedido=(dados_conexao)=>{
    api
        .post(`/requisicaoDadosPost/${dados_conexao}`, {
          token_notificacao: idNotificacao? idNotificacao : '',
          dados_pagamento: pixInfoSem,
          pagamento_antecipado: "NÃO",
          pagamento: pagamentoSelecionado? pagamentoSelecionado.ID : "",
          pagamento_descricao: pagamentoSelecionado? pagamentoSelecionado.DESCRICAO : "",
          id_endereco: enderecoSelecionado? enderecoSelecionado.ID : '',
          id_garcom: "",
          id_cliente: idClienteRestaurante? idClienteRestaurante : "",
          numero_comanda: numeroComanda,
          tipo_comanda: tipoComanda,
          localizacao: "",
          total: totalCart,
          taxa_entrega: taxaEntrega,
          observacoes_pedido: tipoComanda === "DELIVERY" ? observacoesCart : "",
          items_pedido: items_pedido, 
        })
        .then((response)=>{
          if(response.status === 200){
            if(response.data === -100) {
              const msgerro = t('frase.cartaoBloqueado')
              handleJanelaErro(msgerro)
            }
            else if(response.data === -101){
              const msgerro = t('frase.cartaoVencido')
              handleJanelaErro(msgerro)
            } 
            else if(response.data === -102){
              const msgerro = t('frase.semLimite')
              handleJanelaErro(msgerro)
            }
            else if(response.data === -103){
              const msgerro = t('frase.semSaldo')
              handleJanelaErro(msgerro)
            } 
            else if(response.data === -200){
              const msgerro = t('frase.cartaoErro')
              handleJanelaErro(msgerro)
            }
            else if(response.data === -300){
              const msgerro = t('frase.mesaErro')
              handleJanelaErro(msgerro)
            }
            else if(response.data === -301){
              const msgerro = t('frase.mesaBloqueada')
              handleJanelaErro(msgerro)
            }
            else if (response.data === -500){
              const msgerro = t('frase.RotaGenerico')
              handleJanelaErro(msgerro)
            }
            else {
              localStorage.setItem('numeroPedido', response.data)
              const mensagem = t('frase.pedidoFeito')
              const cor = 'verde'
                JanelaMensagem(mensagem, cor)
                dispatch(clearCart());
                  if(tipoComanda === "MESA" || tipoComanda === "CARTAO"){
                    navigate('/Main')
                  } else if (tipoComanda === "DELIVERY") {
                    navigate('/MeusPedidos')
                  }
           }
          } else {
            const msgerro = t('frase.RotaGenerico')
            handleJanelaErro(msgerro)
        }})
        .catch((error) => {
          const mensagem = t('frase.RotaGenerico')
          const cor = 'vermelho'
          JanelaMensagem(mensagem, cor)
      });
  }
  const handleCotinuar = () => {
    navigate('/Main');
  };

  return (
    <div>
      <div>
        {tipoComanda === "DELIVERY" ? (
          <div className='barraEntrega' style={{ background: `${css?.bgBarraTaxaEntrega}`, color: `${css?.colorBarraTaxaEntrega}` }}> 
          <div className='entrega'>
            <div className='iconePagamentosBox'>
              <img src={'data:image/png;base64,' + css?.iconEntrega} className='iconeDelivery'/>
            </div> 
            <div className='tituloPagamentosTexto'> {capitalizeFirstLetter(t('taxaEntrega'))} </div>
          </div>
        <div className='totalCartList'> 
          {
            moeda === 'BRL' ? formCurrency.format(taxaEntrega) :
            moeda === 'ARS' ? formCurrencyArgentina.format(taxaEntrega) : 
            moeda === 'USD' ? formCurrencyUSD.format(taxaEntrega) : null
          }
        </div>
      </div>
        ) : null}
      </div>
      <div className='caixaBarPagar' >
        {infoClientes !== null ? (infoClientes.ABERTO_FECHADO === "ABERTO"  ? (
          <button className='cartBarPagar' onClick={()=> chamadaPedido()} style={{ background: `${css?.bgCartButtonBarPagar}`, color: `${css?.colorCartButtonBarPagar}` }}> 
            <div className='PagarTexto'> {t('confirmar').toUpperCase()} </div>
            <div className='pagarValor'> 
              {
                moeda === 'BRL' ? formCurrency.format(totalCart) :
                moeda === 'ARS' ? formCurrencyArgentina.format(totalCart) : 
                moeda === 'USD' ? formCurrencyUSD.format(totalCart) : null
              }
            </div>
          </button>
        ) : (
          <div className='caixaRestauranteFechado'>
            <div className='caixainterna' style={{ background: `${css?.bgRestauranteFechado}`, color: `${css?.colorRestauranteFechado}` }}>
              <div className='linharestauranteFechado'> {capitalizeFirstLetter(t('restaurante'))} {capitalizeFirstLetter(t('fechado'))} </div>
              <div className='linharestauranteFechado'> {capitalizeFirstLetter(t('horarioFuncionamento'))} </div>
              <div className='linharestauranteFechado'> {horaAbertura} - {horaFechamento} </div>
            </div>
          </div>
        )) : null}        
      </div>
        <div className='cartBarContinuar' style={{ background: `${css?.bgCartButtonContinuar}`, color: `${css?.colorCartButtonContinuar}` }} onClick={handleCotinuar}> {t('continuarComprando').toUpperCase()} </div>
        {modalPagamentoPix && <ModalPagamentoPix dadosConexao={dadosConexao} setModalPagamentoPix={setModalPagamentoPix} dadosCompraPix={dadosCompraPix} css={css} />}
    </div>
  );
}