import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addToCart } from '../../../../redux/cartSlice'
import { useQueryClient } from '@tanstack/react-query';
import '../../../../Styles/StyleForAdicionais.css'
import { formCurrency } from '../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../auxiliares/utilidades/FormataUSD';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function BtnCarrinho({ fichaTecnica, dadosConexao, obrigatorioopcional, totalCusto, PIZZA_MISTA, Produto, adicionalSelecionado, tamanhoEscolhido, observacoes, totalCompra, ID_GRUPO_OPCOES, existeTamanho, css }){
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [btnDesabilitado, setBtnDesabilitado] = useState(true)
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda
    
    const item ={
        produto: Produto,
        adicionalSelecionado: adicionalSelecionado,
        tamanhoEscolhido: tamanhoEscolhido,
        observacoes: observacoes,
        totalCompra: totalCompra,
        tipo: PIZZA_MISTA,
        custoTotal: totalCusto,
        fichaTecnica: fichaTecnica
    }
    const handleCarrinho=(item, ID_GRUPO_OPCOES)=>{
      const cor = 'amarelo'
      if(btnDesabilitado === true){
        if(existeTamanho === true){
          if(tamanhoEscolhido === null){
            const mensagem = t('escolheTamanho')
              JanelaMensagem(mensagem, cor)
          }
        } 
      if (obrigatorioopcional === true){
          const mensagem = t('escolheAdicional')
          JanelaMensagem(mensagem, cor)
      }
      } else {
        dispatch(addToCart(item))
            const cachedData = queryClient.clear(['listaAdicionais', ID_GRUPO_OPCOES])
        navigate('/Carrinho')
      }
    }
    useEffect(() => {
      if (existeTamanho === true) {
        if (tamanhoEscolhido !== null) {
          setBtnDesabilitado(false);
        } else {
          setBtnDesabilitado(true);
        }
      } else {
        setBtnDesabilitado(false);
      }
      if (obrigatorioopcional === true) {
        setBtnDesabilitado(true);
      }
    }, [existeTamanho, tamanhoEscolhido, obrigatorioopcional]);

    return(
      <button className='btnPagarAdicionais' onClick={()=> handleCarrinho(item, ID_GRUPO_OPCOES)}>
        <div className='barraPagarAdicionais' style={{ background: `${css?.bgBtnInserirCart}`, color: `${css?.colorBtnInserirCart}` }}>
          <div className='pagarAdicionaisText'>
            <div className='btnAdicionarAdicionais'> {t('adicionar').toUpperCase()} </div>
          </div>
          <div className='pagarAdicionaisValor'>
            {
              moeda === 'BRL' ? formCurrency.format(totalCompra) :
              moeda === 'ARS' ? formCurrencyArgentina.format(totalCompra) : 
              moeda === 'USD' ? formCurrencyUSD.format(totalCompra) : null
            }
          </div>   
        </div>
      </button>
    )
}