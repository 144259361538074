import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { api } from '../../../../../conecções/api';
import { useQueryClient } from '@tanstack/react-query';
import ListaAdicionais from './ListaAdicionais';
import Decimal from 'decimal.js'; 
import '../../../../../Styles/StyleImagens.css'
import '../../../../../Styles/Styles.css'
import { JanelaMensagem } from '../../../../../auxiliares/janelaFlutuante/JanelaMensagem'
import { useTranslation } from 'react-i18next';

export default function GrupoAdicionais({ tamanhoEscolhido, dadosConexao, setObrigatorioOpcional, setValorTotalItem, setValorTotalCusto, adicionalSelecionado, setAdicionalSelecionado,  setID_GRUPO_OPCOES, css, theme }){
    const [listaGrupoOpcionais, setGruposAdicionais] = useState('');
    const [listaAdicionais, setListaAdicionais] = useState([])
    const [listaAdicionaisAtivo, setListaAdicionaisAtivo] = useState(null);
    const [quantidadeTotalGrupos, setQuantidadeTotalGrupos] = useState({});
    const [possuiTamanho, setPossuiTamanho] = useState('');
    const tipoComanda = localStorage.getItem('tipoComanda');
    const { state } = useLocation();
    const { data } = state;
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    
    useEffect(() => {
      if (Array.isArray(listaGrupoOpcionais)) {
        let obrigatorio = false;
        listaGrupoOpcionais.forEach((itemGrupoAdd) => {
          if (itemGrupoAdd.MINIMO > 0) {
            const totalQuantidade = adicionalSelecionado.reduce((accumulator, item) => {
              if (item.ID_GRUPO_OPCOES === itemGrupoAdd.ID_GRUPO_OPCOES) {
                return accumulator + item.quantidade;
              }
              return accumulator;
            }, 0);
    
            if (totalQuantidade < itemGrupoAdd.MINIMO) {
              obrigatorio = true;
            }
          }
        });
        setObrigatorioOpcional(obrigatorio);
      }
    }, [listaGrupoOpcionais, adicionalSelecionado]);       
    useEffect(()=>{
      if(dadosConexao){
        if(data.hasOwnProperty('TAMANHO')){
          const ROTAListaGrupoOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaGrupoOpcionais/${'MISTA'}/${0}/${data.ID}`
            handleListaGrupoOpções(ROTAListaGrupoOpções)
        } else if (data.hasOwnProperty('PRODUTO')) {
          const ROTAadicionaisTamanho = `http://${dadosConexao.api}:${dadosConexao.porta}/listaTamanhos/${data.ID_PRODUTO}/${tipoComanda}`
          const dados_conexao = encodeURIComponent(ROTAadicionaisTamanho)
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((getdata)=>{
                    const lista = getdata.data
                    if(lista !== -1){
                      setPossuiTamanho('SIM')
                    }
                    else {
                      setPossuiTamanho('NAO')
                    }
                })
                .catch((error) => {
                  const mensagem = 'Erro 544'
                  const cor = 'vermelho'
                  JanelaMensagem(mensagem, cor)
              });
        }
      }
    }, [dadosConexao]);
    useEffect(()=>{
      if(dadosConexao){
        if(possuiTamanho === 'SIM'){
          if(tamanhoEscolhido !== null){
            const ROTAListaGrupoOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaGrupoOpcionais/${'PRODUTO'}/${data.ID_PRODUTO}/${tamanhoEscolhido.ID}`
            handleListaGrupoOpções(ROTAListaGrupoOpções)
          }
        } 
        else if (possuiTamanho === 'NAO') {
          const ROTAListaGrupoOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaGrupoOpcionais/${'PRODUTO'}/${data.ID_PRODUTO}/${0}`
          handleListaGrupoOpções(ROTAListaGrupoOpções)
        }
      }
    }, [possuiTamanho, tamanhoEscolhido, dadosConexao])
    const handleListaGrupoOpções=(ROTAListaGrupoOpções)=>{
      const dados_conexao = encodeURIComponent(ROTAListaGrupoOpções)
        api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata) =>{
            setGruposAdicionais(getdata.data);
          })
          .catch((error) => {
            const mensagem = 'Erro 545'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
          });
    }
    const toggleListaAdicionais = (itemGrupoAdd) => {
        if (listaAdicionaisAtivo === itemGrupoAdd.ID_GRUPO_OPCOES) {
          setListaAdicionaisAtivo(null);
          setQuantidadeTotalGrupos((prevQuantidades) => ({
            ...prevQuantidades,
            [itemGrupoAdd.ID_GRUPO_OPCOES]: listaAdicionais.reduce(
              (accumulator, item) => (item.ID_GRUPO_OPCOES === itemGrupoAdd.ID_GRUPO_OPCOES ? accumulator + item.quantidade : accumulator),
              0
            ),
          }));
        } else {
          setListaAdicionaisAtivo(itemGrupoAdd.ID_GRUPO_OPCOES);
          selecionarListaOpcionais(itemGrupoAdd);
        }
    };
    const selecionarListaOpcionais = (itemGrupoAdd) => {
        const cachedData = queryClient.getQueryData(['listaAdicionais', itemGrupoAdd.ID_GRUPO_OPCOES]);
        if (cachedData) {
          setListaAdicionais(cachedData);
          setID_GRUPO_OPCOES(itemGrupoAdd.ID_GRUPO_OPCOES);
          setQuantidadeTotalGrupos((prevQuantidades) => ({
            ...prevQuantidades,
            [itemGrupoAdd.ID_GRUPO_OPCOES]: prevQuantidades[itemGrupoAdd.ID_GRUPO_OPCOES] || 0,
          }));
        } else {
          if(data.hasOwnProperty('TAMANHO')){
            if(itemGrupoAdd.TABELA === 'TAMANHOS'){
              const ROTAListaOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaOpcionais/${itemGrupoAdd.ID_GRUPO_OPCOES}/${data.ID}`
              handleListaOpções(ROTAListaOpções, itemGrupoAdd)
            } else {
              const ROTAListaOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaOpcionais/${itemGrupoAdd.ID_GRUPO_OPCOES}/${'SEM_TAMANHO'}`
              handleListaOpções(ROTAListaOpções, itemGrupoAdd)
            }
          } else if (data.hasOwnProperty('PRODUTO')) {
            const ROTAListaOpções = `http://${dadosConexao.api}:${dadosConexao.porta}/listaOpcionais/${itemGrupoAdd.ID_GRUPO_OPCOES}/${'SEM_TAMANHO'}`
            handleListaOpções(ROTAListaOpções, itemGrupoAdd)
          }
        }
    };
    const handleListaOpções=(ROTAListaOpções, itemGrupoAdd)=>{
        const dados_conexao = encodeURIComponent(ROTAListaOpções)
        api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata) => {
                const data = getdata.data.map((item) => ({
                  ...item,
                  quantidade: 0,
                }));
                setListaAdicionais(data);
                queryClient.setQueryData(['listaAdicionais', itemGrupoAdd.ID_GRUPO_OPCOES], data);
                setID_GRUPO_OPCOES(itemGrupoAdd.ID_GRUPO_OPCOES);
                setQuantidadeTotalGrupos((prevQuantidades) => ({
                  ...prevQuantidades,
                  [itemGrupoAdd.ID_GRUPO_OPCOES]: prevQuantidades[itemGrupoAdd.ID_GRUPO_OPCOES] || 0,
                }));
          });
    }     
    useEffect(() => {
        listaAdicionais.forEach((item) => {
            if (item.quantidade > 0) {
              const itemIndex = adicionalSelecionado.findIndex((adicionalSelecionado) => adicionalSelecionado.ID === item.ID);
              if (itemIndex === -1) {
                setAdicionalSelecionado((prevSelecionados) => [...prevSelecionados, { ...item }]);
              } else {
                setAdicionalSelecionado((prevSelecionados) => {
                  const updatedSelecionados = [...prevSelecionados];
                  if (updatedSelecionados[itemIndex]) {
                    updatedSelecionados[itemIndex].quantidade = item.quantidade;
                  }
                  return updatedSelecionados;
                });
              }
            } else {
              setAdicionalSelecionado((prevSelecionados) => prevSelecionados.filter((adicionalSelecionado) => adicionalSelecionado.ID !== item.ID));
            }
          })
    }, [listaAdicionais]);
    useEffect(() => {
      let totalItem = new Decimal(0);
      let totalCusto = new Decimal(0);
      adicionalSelecionado.forEach((item) => {
        if (item.DIVIDIR === 'NAO') {
          totalItem = totalItem.plus(new Decimal(item.VALOR_VENDA).times(item.quantidade));
          totalCusto = totalCusto.plus(new Decimal(item.VALOR_CUSTO).times(item.quantidade));
        } else if (item.DIVIDIR === 'SIM') {
          totalItem = totalItem.plus(
            new Decimal(item.VALOR_VENDA).times(item.quantidade).dividedBy(quantidadeTotalGrupos[item.ID_GRUPO_OPCOES])
          );
          totalCusto = totalCusto.plus(
            new Decimal(item.VALOR_CUSTO).times(item.quantidade).dividedBy(quantidadeTotalGrupos[item.ID_GRUPO_OPCOES])
          );
        }
      });
      setValorTotalItem(totalItem.toNumber().toFixed(2));
      setValorTotalCusto(totalCusto.toNumber().toFixed(2));
    }, [adicionalSelecionado, quantidadeTotalGrupos]);
    useEffect(() => {
      if (Array.isArray(listaAdicionais)) {
        const total = listaAdicionais.reduce(
          (accumulator, item) =>
            item.ID_GRUPO_OPCOES === listaAdicionaisAtivo ? accumulator + item.quantidade : accumulator,
          0
        );
        setQuantidadeTotalGrupos((prevQuantidades) => ({
          ...prevQuantidades,
          [listaAdicionaisAtivo]: total,
        }));
      }
    }, [listaAdicionais, listaAdicionaisAtivo]);
    
    return(
      <div>
      <div className='listaGrupoOpcionais'>
      <div>
          <div> { listaGrupoOpcionais.length > 0 ? (
          <div className='grupoTitulo' style={{ background: `${css?.bgTituloGrupoAdicionais}`, color: `${css?.colorTituloGrupoAdicionais}` }} > {t('adicionais').toUpperCase()} </div>
              ) : (
          <div></div>
              )}
          </div>
          <div>
              {Array.isArray(listaGrupoOpcionais) ? (
                  listaGrupoOpcionais.map((itemGrupoAdd, index) => 
                  <div  key={index}>
                      <div>
                          <div className='cardGrupoOpcionais font16' style={{ background: `${css?.bgCardGrupoOpcionais}`, color: `${css?.colorCardGrupoOpcionais}` }} onClick={() => toggleListaAdicionais(itemGrupoAdd)}>
                              <div style={{ width: '100%' }}>
                                <div className='adicionaisTitulo'> {itemGrupoAdd.DESCRICAO} </div>
                                <div className='boxQuantidades font14'>
                                    {
                                        itemGrupoAdd.MINIMO > 0 ? (
                                            <div className='caixaQuantidades'> {t('minimo')} : {itemGrupoAdd.MINIMO} </div>
                                        ) : null
                                    }
                                    <div className='caixaQuantidades'> {t('maximo')} : {itemGrupoAdd.MAXIMO} </div>
                                    <div className='caixaQuantidades'> {t('escolhidos')} : {quantidadeTotalGrupos[itemGrupoAdd.ID_GRUPO_OPCOES]} </div>
                                </div> 
                              </div>  
                              <div className='iconeAdicionais'>
                                      {
                                          listaAdicionaisAtivo === itemGrupoAdd.ID_GRUPO_OPCOES 
                                          ? <img src={'data:image/png;base64,' + css?.iconeParaCima} className='iconeSetaGrupo'/>
                                          : <img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='iconeSetaGrupo'/>
                                      }
                              </div>                             
                          </div>  
                      </div>
                      <div>
                        {listaAdicionaisAtivo === itemGrupoAdd.ID_GRUPO_OPCOES && (
                            <ListaAdicionais
                                Maximo={itemGrupoAdd.MAXIMO}
                                listaAdicionais={listaAdicionais}
                                setListaAdicionais={setListaAdicionais}
                                quantidadeTotalGrupos={quantidadeTotalGrupos} 
                                listaGrupoOpcionais={listaGrupoOpcionais}
                                itemGrupoAdd={itemGrupoAdd}
                                css={css}
                                dadosConexao={dadosConexao}
                            />
                        )} 
                      </div>
                  </div>
              )) : null}    
          </div>
      </div>
      </div>
  </div>
    )
}