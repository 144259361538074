import { useState } from "react";
import './style.css'
import { QRCodeSVG } from 'qrcode.react';
import { JanelaMensagem } from "../janelaFlutuante/JanelaMensagem";
import { api } from '../../conecções/api'

export const GerarPix = (dadosPix, total, rota, numeroComanda, setInfosPix, setPixCopiaECola, setPixGerado) => {
  api
    .post(`/GerarPixComanda`, {
      dadosPix: dadosPix,
      total: total,
      rota: rota, 
      numeroComanda: numeroComanda
    })
    .then(response => {
      setPixGerado(true)
      setInfosPix(response.data)
      if (response.data.brcode !== null && response.data.brcode !== undefined) {
        setPixCopiaECola(response.data.brcode);
      } else {
        setPixCopiaECola(response.data.pixCopiaECola);
      }
    })
    .catch((error => {
      console.log(error.message)
      const mensagem = 'Erro 561'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    }));
}

export const GerarPixPagarConta = (dadosPix, total, rota, tipoComanda, numeroComanda, setPix, setPixCopiaECola, setPixGerado) => {
  api
    .post(`/GerarPixPagarConta`, {
      dadosPix: dadosPix,
      total: total,
      rota: rota, 
      numeroComanda: numeroComanda,
      tipoComanda: tipoComanda
    })
    .then(response => {
      setPixGerado(true)
      setPix(response.data)
      if (response.data.brcode) {
        setPixCopiaECola(response.data.brcode);
      } else {
        setPixCopiaECola(response.data.pixCopiaECola);
      }
    })
    .catch((error => {
      console.log(error.message)
      const mensagem = 'Erro 561'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    }));
}

export const GerarPixPagarDelivery = (dadosPix, rota, dadosCompraPix, setPix, setPixCopiaECola, setPixGerado) => {
  api
    .post(`/GerarPixPagarDelivery`, {
      dadospix: dadosPix,
      rota: rota,
      descricao: 'cobrança compra pelo delivery',
      total: dadosCompraPix.total,
      dadosCompraPix: dadosCompraPix
    })
    .then(response => {
      setPixGerado(true)
      setPix(response.data)
      if (response.data.brcode) {
        setPixCopiaECola(response.data.brcode);
      } else {
        setPixCopiaECola(response.data.pixCopiaECola);
      }
    })
    .catch((error => {
      console.log(error.message)
      const mensagem = 'Erro 561'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    }));
}

export const consultarStatusPix=(dadosPix, txid, setStatus)=>{
  api
    .post(`/consultarStatusPix`, {
      dadosPix: dadosPix,
      txid: txid
    })
    .then((response) => {
        setStatus(response.data);
    })
    .catch((error) => {
      const mensagem = 'Ocorreu um problema ao Consultar o pagamento do pix!'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    })
}

export default function PixCopiaECola({ pixCopiaECola, css }){
  const [copied, setCopied] = useState('');

  const handleCopyClick = () => { 
    const textArea = document.createElement('textarea');
    textArea.value = pixCopiaECola;
    document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
      setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
    } catch (err) {
        console.error('Falha ao copiar: ', err);
      }
      document.body.removeChild(textArea);
  };

  return(
    <div className='gridcenter' style={{ justifyContent: 'center', alignContent: 'center' }}>
      <div className='caixaloadGif' >
        {pixCopiaECola !== "" ? (
          <QRCodeSVG
            className="qrcode"
            value={pixCopiaECola}
            size={220}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"M"}
            includeMargin={false}
            imageSettings={{
              src: 'data:image/png;base64,' + 'iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACrklEQVR4nO1aS24TQRB9F0CENVnAipAPIAFLfks2gYsAByAhkl1lm0OgSOE2kLABjsDHASciRIIIpIe6Pf4lPbG7p3s8RmmpJMse17xX3V1dr2aAs/GfDTZxgYoXVOxQcEjFTwq2KVjnBuZQ5cE67lPxjQo6TbBrrkEVBwUPKfidC35gRxQ8woyCZ+VI0B98dUgwHPyAhOLxrIKfHomI4Fk6iQTgWRqJhOBZHomXOMc67lLxioK/EUD/sb4Ud4zvpOBzTt5OAfAdKu6lBdnAaja13ZsK9ijYYgvz9nfBjVPLh3zbp+CW9dHCPAWvre/h5dTAalzwwyb4RMWCva6J614kukHoglcsUPE5d0+Ekphww7ZZw5IXCQO+iZv2PzVcyQIR98T2zDZtKpazaF4bU436gqc3icBUOSBRw1UKvjjA7xqCffD5y4bBJLKs4l7z45fGVyoWnSQM+BpWsllazK4NyVpHuXoiU1Ih2WSURG9PKJYpeG9tMDtLBcCzHwyXsstkYLhjB4kR/zHAa9/WXGv/XSTnhsQBFc9Yx2Vr5nP3u1j+t10EDqPdILUJDlwE4kUovf1w7YGdyEvoCZu4ZJeQ4GnkAL11zcD6zGxiwfOTBDYwlx02MdPoRyo+RE2jijZbOH+CQPKDrHcKFz3IdEz5bUsJxS/vqAwi766HwusghtVDk5OYvCIto5jzKuqG17xPOd3TArVE5bS3oPFVZccFjeSSSKLKOlZSCi72wQu+B2zIfTZwe0hSbh3T18XBjyWneFBY1NdLbrebHGxSGRWbUdoqYn1sWp95+X2mGluSuGOdtLUoJbXbkzR3peRnBVHb6zKlBx2BZUc1wEcgMX3wBUhUB3wAieqBH9ETp4uidvJ2etFhlZ1izehW+5pB91WDN0YGJj9pzwbKH/8AoZ4Z+r4XhM8AAAAASUVORK5CYII=',
            x: undefined,
            y: undefined,
            height: 40,
            width: 40,
            excavate: true,
            }}
          /> 
        ) : null}     
      </div>
            {pixCopiaECola? (
              <div>
                <div className='gridcenter marginT20 marginB10' style={{ display: 'grid', placeItems: 'center' }}> 
                  <button className="Btn" onClick={()=> handleCopyClick()}>
                        <div className="sign">
                          <img src={'data:image/png;base64,' + css?.iconeCopiar} className='iconecopiaecola'/>
                        </div>
                        <div className="text robotoB"> {`${copied === true ? 'Copiado' : 'Copiar'}`} </div>
                    </button>
                </div>
                <div>
                  <div className='gridcenter pad5 font18 robotoL' style={{ textAlign: 'center', color: 'black', wordBreak: 'break-all'}}> {pixCopiaECola} </div>
                </div>
              </div>
            ) : null}
      </div>
  )
}

export const cancelarPixPagarMesa=( dadosPix, txid, setModalPagarConta)=>{
  api
    .post(`/cancelarPix`, {
      dadosPix: dadosPix,
      txid: txid
    })
    .then(response => {
      setModalPagarConta(false)
      const mensagem = 'Pix Cancelado'
      const cor = 'verde'
      JanelaMensagem(mensagem, cor)
    })
    .catch((error => {
      const mensagem = 'Ocorreu um problema ao Criar o pix!'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    }))
}
export const cancelarPixRecargaCartao=( dadosPix, txid, setModalRecargaCartao)=>{
  api
    .post(`/cancelarPix`, {
      dadosPix: dadosPix,
      txid: txid
    })
    .then(response => {
      setModalRecargaCartao(false)
      const mensagem = 'Pix Cancelado'
      const cor = 'verde'
      JanelaMensagem(mensagem, cor)
    })
    .catch((error => {
      const mensagem = 'Ocorreu um problema ao Criar o pix!'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    }))
}
export const cancelarPixPagamento=( dadosPix, txid, setModalPagamentoPix)=>{
  api
    .post(`/cancelarPix`, {
      dadosPix: dadosPix,
      txid: txid
    })
    .then(response => {
      setModalPagamentoPix(false)
      const mensagem = 'Pix Cancelado'
      const cor = 'verde'
      JanelaMensagem(mensagem, cor)
    })
    .catch((error => {
      const mensagem = 'Ocorreu um problema ao Criar o pix!'
      const cor = 'vermelho'
      JanelaMensagem(mensagem, cor);
    }))
}