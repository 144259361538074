import { useEffect, useState } from 'react'
import Decimal from 'decimal.js';
import '../../../../../Styles/Styles.css'
import '../../../../../Styles/StyleForAdicionais.css'
import { formCurrency } from '../../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../../auxiliares/utilidades/FormataUSD';
import { api } from '../../../../../conecções/api';
import { capitalizeFirstLetter } from '../../../../../auxiliares/utilidades/PrimeiraMaiuscula'
import { JanelaMensagem } from '../../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function AdicionaisInfo({ dadosConexao, fichaTecnica, setFichaTecnica, existeTamanho, totalCompra, setTotalCompra, setCustoCompra, valorTotalItem, valorTotalCusto, Produto, tamanhoEscolhido, css }){
    const [dadosModoPreparo, setDadosModoPreparo] = useState('');
    const [possuiTamanho, setPossuiTamanho] = useState('');
    const { t } = useTranslation();
    const moeda = dadosConexao?.moeda
    const tipoComanda = localStorage.getItem('tipoComanda');

    useEffect(() => {
        if(existeTamanho === false){
            const valorVenda = new Decimal(Produto.VALOR_VENDA);
            const valorCusto = new Decimal(Produto.VALOR_CUSTO);
        
            const newTotalCompra = valorVenda.plus(valorTotalItem);
            const newCustoCompra = valorCusto.plus(valorTotalCusto);
        
            setTotalCompra(newTotalCompra.toNumber().toFixed(2));
            setCustoCompra(newCustoCompra.toNumber().toFixed(2));
        } else if (existeTamanho === true){
            const valorVenda = new Decimal(tamanhoEscolhido !== null ? (tamanhoEscolhido.VALOR_VENDA) : (0));
            const valorCusto = new Decimal(tamanhoEscolhido !== null ? (tamanhoEscolhido.VALOR_CUSTO) : (0));
        
            const newTotalCompra = valorVenda.plus(valorTotalItem);
            const newCustoCompra = valorCusto.plus(valorTotalCusto);
        
            setTotalCompra(newTotalCompra.toNumber().toFixed(2));
            setCustoCompra(newCustoCompra.toNumber().toFixed(2));
        }
    }, [valorTotalItem, valorTotalCusto, tamanhoEscolhido, existeTamanho]);
    useEffect(()=>{
        if(dadosConexao){
            const idProduto = Produto.ID_PRODUTO
            const IdtamanhoEscolhido = tamanhoEscolhido? tamanhoEscolhido.ID_GRADE : ''
                handleListaTamanhos(idProduto)
            if(Produto?.FICHA_TECNICA_WEB === 0){
                handleModoPreparo(idProduto)
                    if(possuiTamanho === -1){
                        handleFichaTecnicaSemTamanho(idProduto)
                } else if (possuiTamanho !== -1){
                    if(tamanhoEscolhido !== null){
                        handleFichaTecnicaComTamanho(idProduto, IdtamanhoEscolhido)
                    } else {
                        setFichaTecnica(null)
                    }
                }
            }
        }
    }, [Produto, tamanhoEscolhido, existeTamanho, dadosConexao])
    const handleListaTamanhos=(idProduto)=>{
        const ROTAadicionaisTamanho = `http://${dadosConexao.api}:${dadosConexao.porta}/listaTamanhos/${idProduto}/${tipoComanda}`
        const dados_conexao = encodeURIComponent(ROTAadicionaisTamanho)
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((getdata)=>{
                    const lista = getdata.data
                    if(lista !== -1){
                        setPossuiTamanho('SIM')
                    }
                    else {
                        setPossuiTamanho('NAO')
                    }
                })
                .catch((error) => {
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
    }
    const handleModoPreparo=(idProduto)=>{
        const ROTAmodopreparoproduto = `http://${dadosConexao.api}:${dadosConexao.porta}/modoPreparoProduto/${idProduto}`
        const dados_conexao = encodeURIComponent(ROTAmodopreparoproduto)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setDadosModoPreparo(getdata.data[0])
            })
            .catch((error)=>{
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            })
    }
    const handleFichaTecnicaSemTamanho=(idProduto)=>{
        const ROTAficheTecnica = `http://${dadosConexao.api}:${dadosConexao.porta}/fichaTecnica/${idProduto}/${"N"}`
        const dados_conexao2 = encodeURIComponent(ROTAficheTecnica)
            api
                .post(`/requisicaoDados/${dados_conexao2}`)
                .then((getdata)=>{
                    const lista = Array.isArray(getdata.data) ? getdata.data : []
                        const listaFiltro = lista.map((item) => ({
                            ...item,
                            retirado: false     
                        }))
                        setFichaTecnica(listaFiltro)
                })
                .catch((error)=>{
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                })
    }
    const handleFichaTecnicaComTamanho=(idProduto, IdtamanhoEscolhido)=>{
        const ROTAficheTecnica = `http://${dadosConexao.api}:${dadosConexao.porta}/fichaTecnica/${idProduto}/${IdtamanhoEscolhido}`
        const dados_conexao2 = encodeURIComponent(ROTAficheTecnica)
            api
                .post(`/requisicaoDados/${dados_conexao2}`)
                .then((getdata)=>{
                    const lista = Array.isArray(getdata.data) ? getdata.data : []
                        const listaFiltro = lista.map((item) => ({
                            ...item,
                            retirado: false
                        }))
                        setFichaTecnica(listaFiltro)
                })
                .catch((error)=>{
                    const mensagem = t('frase.RotaGenerico')
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                })
    }
    return(
        <div className='adicionaisInfo' style={{ background: `${css?.bgInfoAdicionais}` }}>
            <div className='cardFichatecnica' style={{ background: `${css?.bgCardInfoAdicionais}`, color: `${css?.colorCardInfoAdicionais}` }}>
                <div className='quadrofichatecnica'>
                    <div className='boxProdutos'>
                        <div className='produtosInfo'>
                            <div className='produtoNome'>
                                <div className='itemNome'>{Produto.PRODUTO}</div>
                            </div>
                            <div className='produtoValor'>
                                <div className='boxValor'>
                                    {
                                        moeda === 'BRL' ? formCurrency.format(totalCompra) :
                                        moeda === 'ARS' ? formCurrencyArgentina.format(totalCompra) : 
                                        moeda === 'USD' ? formCurrencyUSD.format(totalCompra) : null
                                    }  
                                </div>
                            </div>
                        </div>
                        <div className='produtos-img'>
                            {Produto?.IMAGEM_WEB? (
                                <>
                                    <img src={'data:image/png;base64,' + Produto.IMAGEM_WEB} key={Produto.ID_PRODUTO} alt='Restaurante' className='img-produto limiteimg'/>
                                </>
                                ) : null }
                        </div>
                    </div>
                </div>
            </div>
            {dadosModoPreparo? (
                <div>
                    {dadosModoPreparo.MODO_PREPARO !== null ? (
                        <div className='cardProdutos' >
                            <div className='modoPreparo'>{dadosModoPreparo.MODO_PREPARO}</div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {Produto?.FICHA_TECNICA_WEB === 1 ? (
                <div className='cardFichatecnica' style={{ background: `${css?.bgCardInfoAdicionais}`, color: `${css?.colorCardInfoAdicionais}` }}> 
                    <div className='quadrofichatecnica'> {capitalizeFirstLetter(Produto.FICHA_TECNICA.toLowerCase())} </div>
                </div>
            ) : Produto?.FICHA_TECNICA_WEB === 0 ? (
                <div>
                    {Array.isArray(fichaTecnica) ? (
                        <div className='cardFichatecnica' style={{ background: `${css?.bgCardInfoAdicionais}`, color: `${css?.colorCardInfoAdicionais}` }}>
                            <div className='quadrofichatecnica'>
                                {Array.isArray(fichaTecnica) ? (
                                    fichaTecnica.map((item)=>
                                        <div className='linhaitemficha padding' key={item.DESCRICAO}>
                                            <div className='qtditemficha' key={item.DESCRICAO}>( {item.QUANTIDADE} - {item.UNIDADE} ) - {capitalizeFirstLetter(item.DESCRICAO.toLowerCase())} </div>
                                        </div>
                                )) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
        
    )
}