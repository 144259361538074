import { useTranslation } from 'react-i18next';
import '../PedidosCartaoMesa/modalRecargaCartao/modalrecargacartao.css'
import { useEffect, useState } from 'react'; 
import PixCopiaECola from '../../../auxiliares/metodosPagamento/Pix'
import { GerarPixPagarDelivery, consultarStatusPix, cancelarPixPagamento } from '../../../auxiliares/metodosPagamento/Pix'
import { api } from '../../../conecções/api';
import { JanelaMensagem } from '../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useNavigate } from 'react-router-dom';

export default function ModalPagamentoPix({ dadosConexao, setModalPagamentoPix, dadosCompraPix, css }){
    const [dadosPix, setDadospix] = useState('');
    const [status, setStatus] = useState('');
    const [pixCopiaECola, setPixCopiaECola] = useState('')
    const [pix, setPix] = useState('');
    const [pixGerado, setPixGerado] = useState(false);
    const rota = `http://${dadosConexao.api}:${dadosConexao.porta}/inserirPedido`
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(()=>{
        if(dadosConexao){
            handleDadosPix()
        }
    }, [dadosConexao])
    const handleDadosPix=()=>{
        const ROTADadosPix = `http://${dadosConexao.api}:${dadosConexao.porta}/dadosPIX`
        const dados_conexao = encodeURIComponent(ROTADadosPix)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata) => {
                setDadospix(getdata.data[0])
            })
            .catch((error) => {
                const mensagem = t('frase.RotaGenerico')
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    useEffect(()=>{
        if(dadosPix){
            handleGerarPix()
        }
    }, [dadosPix])
    const handleGerarPix=()=>{
        GerarPixPagarDelivery(dadosPix, rota, dadosCompraPix, setPix, setPixCopiaECola, setPixGerado)
    }
    useEffect(()=>{
        if(pixGerado === true){
            const intervalId = setInterval(() => {
                handleVerificarStatus()
              }, 2000); 
            return () => clearInterval(intervalId);
        }
    }, [pixGerado])
    const handleVerificarStatus=()=>{
        consultarStatusPix(dadosPix, pix.txid, setStatus)
    }
    useEffect(()=>{
        if(status === 'CONCLUIDA'){
            setPixGerado(false)
            const mensagem = ''
            const cor = 'verde'
                JanelaMensagem(mensagem, cor)
            navigate('/Pedidos')
        }
    }, [status])
    const handleCancelarPix=()=>{
        cancelarPixPagamento(dadosPix, pix.txid, setModalPagamentoPix)
    }
    const handleFechar=()=>{
        setModalPagamentoPix(false)
    }

    return(
        <>
        <div className='darkBG'/>
            <div className='centered'>
            <div className='modalPedidos'  style={{ background: `${css?.bgModalPagamentoPix}` , color: `${css?.colorModalPagamentoPix}` }}>
            <div className='modalPedidosContent'> 
                <div className='textoPedidoCartModal'>
                    <div className='textoloadpix'> {t('aguardandoPagamento')} </div>
                    <div className='areaPix'>
                        <PixCopiaECola
                            pixCopiaECola={pixCopiaECola}
                            css={css}
                        />
                    </div>
                </div>
            </div>
            <div>
                <button className='btn btnCancelar' style={{ background: `${css?.bgBtnCancelar}`, color: `${css?.colorBtnCancelar}` }}  onClick={() => handleCancelarPix(false)}> {t('cancelar')} </button>
            </div>
            </div>
        </div>
        </>
    )
}