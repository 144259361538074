import './modalinfocart.css'
import { capitalizeFirstLetter } from '../../../auxiliares/utilidades/PrimeiraMaiuscula'
import { useTranslation } from 'react-i18next';

export default function modalInfoCart({ item, setmodal, css }){
    const {t} = useTranslation();
    
    return(
        <>
        <div className='darkBG' onClick={() => setmodal(false)} />
            <div className='centered'>
                <div className='modalPedidos' style={{ background: `${css?.bgModalCartInfo}`, color: `${css?.colorModalCartInfo}` }}>
                    <button className='closeBtn' onClick={() => setmodal(false)}> 
                        <img src={'data:image/png;base64,' + css?.iconeClose} className='iconeCarrinhoCart'/>
                    </button>
                <div className='modalPedidosContent'> 
                    <div className='nomeitemmodalcartinfo'> {item.tipo === "NAO" || item.tipo === "NÃO" ? (<div> {capitalizeFirstLetter(item.produto.PRODUTO.toLowerCase())} </div>) : (<div> {t('pizza')} {item.produto.TAMANHO.toLowerCase()} </div>) } </div>
                    {item.tipo === "SIM" ? (
                        <div className='saboresmodalcartinfo'>
                            <div className='modalcartinfotit'> {t('sabores')} </div>
                            <div className=''>
                                {item.SaboresSelecionados.map((item, index)=> 
                                    <div key={index}>
                                        <div>{item.PRODUTO.toLowerCase()} </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                    {item.adicionalSelecionado !== null && item.adicionalSelecionado.length > 0 ?(
                        <div className='saboresmodalcartinfo' key={item.adicionalSelecionado.ID}>
                            <div className='modalcartinfotit'> {t('adicionais')} </div>
                            <div className=''>
                                {item.adicionalSelecionado.map((item, index)=>
                                    <div key={index}> 
                                        <div>{item.quantidade} x {item.DESCRICAO.toLowerCase()}</div>   
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                    {item.tamanhoEscolhido !== null && item.tamanhoEscolhido !== undefined ? (
                        <div className='modalitemtamanho'> {t('tamanho')} {item.tamanhoEscolhido.TAMANHO.toLowerCase()}  </div>
                    ) : null}
                </div>
                </div>
            </div>
        </>
    )
}