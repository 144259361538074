import '../../../../Styles/Styles.css'
import { formCurrency } from '../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../auxiliares/utilidades/FormataUSD';
import { useNavigate  } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';
import { useState } from 'react';

export default function ProdutoList({ produto, subGrupo, grupo, css, dadosConexao, theme }){
    const navigate = useNavigate();
    const moeda = dadosConexao?.moeda
    
    const Adicionais = (data, subGrupo, grupo) => {
        navigate ('/Adicionais', { state: { data, subGrupo, grupo } });
    };
    return(
           <div className='lista-produtos'>
                {Array.isArray(produto) ? (
                    produto.map((data, index)=>
                        <div className={`cardProdutos ${theme === 'dark' ? '' : 'border'}`} style={{ background: `${css?.bgCardProdutoMain}`, color: `${css?.colorCardProdutoMain}` }} key={index}>
                            <div className='boxProdutos' onClick={() => Adicionais(data, subGrupo, grupo)} style={{ height: data.FICHA_TECNICA ? '90px' : '70px',  borderRadius: '5px' }}>
                                <div className='produtosInfo'>
                                        <div className='itemNome'>{capitalizeFirstLetter(data.PRODUTO.toLowerCase())}</div>
                                        {data.FICHA_TECNICA !== null ? (<div className='produto-ingredientes'> {data.FICHA_TECNICA.toLowerCase()} </div>) : (null)}
                                    <div className='produtoValor'>
                                        <div className='boxValor'>
                                                {data.VALOR_MINIMO > 0 ? (
                                                    <div>
                                                        <div>
                                                            {
                                                                moeda === 'BRL' ? formCurrency.format(data.VALOR_MINIMO) :
                                                                moeda === 'ARS' ? formCurrencyArgentina.format(data.VALOR_MINIMO) : 
                                                                moeda === 'USD' ? formCurrencyUSD.format(data.VALOR_MINIMO) : null
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div>
                                                            {
                                                                moeda === 'BRL' ? formCurrency.format(data.VALOR_VENDA) :
                                                                moeda === 'ARS' ? formCurrencyArgentina.format(data.VALOR_VENDA) : 
                                                                moeda === 'USD' ? formCurrencyUSD.format(data.VALOR_VENDA) : null
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                    </div>
                                </div>
                                <div className='produtos-img'>
                                    {data === null && data.IMAGEM_WEB === null || data.IMAGEM_WEB === "" ? (
                                        <div></div>
                                    ) : (
                                        <img src={'data:image/png;base64,' + data.IMAGEM_WEB} key={data.ID_PRODUTO} alt='Restaurante' className='img-produto'/>
                                       )}
                                </div>
                            </div>
                        </div>
                    )) : null}
           </div>
    )
}
