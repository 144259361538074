import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css'
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function LoginSociais () {
  const navigate = useNavigate()  
  const {t} = useTranslation();
  
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCYWFJQHCOX0lGYXbMIffgwSpbee-rsBp8",
      authDomain: "cardapioweb-4190a.firebaseapp.com",
      projectId: "cardapioweb-4190a",
      storageBucket: "cardapioweb-4190a.appspot.com",
      messagingSenderId: "296774148999",
      appId: "1:296774148999:web:add883b730aa7327822922",
      measurementId: "G-46HFSFBDCE"
  };

  firebase.initializeApp(firebaseConfig);
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl){
            const user = authResult.user
            const dados = localStorage.getItem('dados')
            const dataNew = JSON.parse(dados)
            const dataObject = dataNew.respostaDaRota
            const ROTAinserirCliente = `http://${dataObject.api}:${dataObject.porta}/insereCliente`
            const dados_conexao = encodeURIComponent(ROTAinserirCliente)
            if(user !== null){
              api
                .post(`/inserirCliente/${dados_conexao}`, {
                  nome: user.displayName !== null ? user.displayName : '',
                  data_nascimento: '2023/08/24',
                  email: user.email !== null ? user.email : '',
                  firebase_token: user.uid,
                  numero_telefone: user.phoneNumber !== null ? user.phoneNumber : ''
                })
                .then((response)=>{
                  localStorage.setItem('idCliente', response.data)
                  localStorage.setItem('uidToken', user.uid)
                })
                .catch((error)=>{
                  const mensagem = t('frase.RotaGenerico')
                  const cor = 'amarelo'
                  JanelaMensagem(mensagem, cor)
                })
            }
          }},
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: 'select_account', 
          },
          defaultCountry: 'pt-BR'
        },
      ],
    };

    const ui = firebaseui.auth.AuthUI.getInstance() 
          || new firebaseui.auth.AuthUI(firebase.auth());
    
    ui.start('#firebaseui-auth-container', uiConfig);
    
  }, []);

  return (
    <div>
      <div lang='pt-BR' id="firebaseui-auth-container"></div>
    </div>
  )
};
