import './meuspedidos.css'
import TopoHeaderBar from '../../header/TopoHeaderBar'
import CorpoPedidosDelivery from './partes/CorpoPedidosDelivery'
import Footer from '../../Footer/Footer'
import BtnsFlutuantes from '../../BarrasNavegacao/BtnsFlutuantes'

export default function MeusPedidos({ css, dadosConexao }){

    return(
        <div className='MainPedidosDelivery'>
            <BtnsFlutuantes
                css={css}
            />
            <div className='CorpoPedidosDelivery' style={{ background: `${css?.bgCorpoPedidoDelivery}` }} >
                <div>
                    <TopoHeaderBar
                        css={css}
                    />
                </div>
                <div className='corpoPedidosDelivery'>
                    <CorpoPedidosDelivery
                        css={css}
                        dadosConexao={dadosConexao}
                    />
                </div>
            </div>
            <div>
                <Footer
                    css={css}
                />
            </div>
        </div>
    )
}
