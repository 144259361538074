import { useState, useEffect } from 'react'
import { api } from '../../../../conecções/api';
import '../../../../Styles/StyleCarrinho.css'
import '../../../../Styles/StyleImagens.css'
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula'
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem'


export default function Localizacao({ tipoComanda, dadosConexao, opçaoEscolhidaGarcom, setMesaSelecionada, css }){
    const [localizacao, setLocalizacao] = useState([]);
    const [selectedMesa, setSelectedMesa] = useState(null);
    const [listaLocalizacaoAtiva, setListaLocalizacaoAtiva] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const {t} = useTranslation();

    useEffect(()=>{
        if(dadosConexao){
            handleListaMesas()
        }
    }, [dadosConexao])
    const handleListaMesas=()=>{
        const ROTAlistaMesas = `http://${dadosConexao.api}:${dadosConexao.porta}/listaMesas`
        const dados_conexao = encodeURIComponent(ROTAlistaMesas)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                if(Array.isArray(getdata.data)){
                    setLocalizacao(getdata.data);
                }
            })
            .catch((error) => {
                const mensagem = 'Erro 550'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
    }
    const selecionarMesa = (item, index) => {
        if (selectedMesa === index) {
          return;
        } else {
            setMesaSelecionada(item.MESA)
        }
        setSelectedMesa(index);
    };
    const toggleListaLocalizacao = () => {
        if (listaLocalizacaoAtiva === "ativo") {
            setListaLocalizacaoAtiva(null);
        } else {
            setListaLocalizacaoAtiva("ativo");
        }
    } 
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value)
    }
    const filteredData = localizacao.filter((item) =>
      (item.MESA && item.MESA.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    const data = filteredData

    return(
        <div>
            {(tipoComanda === "CARTAO" || opçaoEscolhidaGarcom === "CARTAO")
                ? (
                    <div>
                        <div className='cartTitulo localizao' style={{ background: `${css?.bgCartTituloLocalizacao}`, color: `${css?.colorCartTituloLocalizacao}` }} onClick={toggleListaLocalizacao}> 
                            <div className='iconeEnomeLocalizacao'>
                                <img src={'data:image/png;base64,' + css?.iconeLocalizacao} className='iconeLocalizacao'/>
                                <div className='carrinhoName'> {t('localização')} </div>
                            </div>
                            <div className='iconeSetaLocalizacao'>
                                {listaLocalizacaoAtiva === "ativo" 
                                    ?<img src={'data:image/png;base64,' + css?.iconeParaCima} className='iconeSetaGrupo'/> 
                                    :<img src={'data:image/png;base64,' + css?.iconeParaBaixo} className='iconeSetaGrupo'/>
                                }
                            </div>
                        </div>
                        {listaLocalizacaoAtiva === "ativo" 
                            ? (
                            <div style={{ background: `${css?.bgcartListaLocalizacao}`, paddingTop: '5px' }}>
                                <div className='caixaPesquisaMesa'>
                                    <input type='text' className='pesquisaMesa' style={{ background: `${css?.bgCartPesquisaLocalizacao}`, color: `${css?.colorCartPesquisaLocalizacao}` }} placeholder={capitalizeFirstLetter(t('busca')) + "..."} value={searchQuery} onChange={handleSearchInputChange}/>
                                </div>
                                <div className='cartListItems'>
                                    {data.map((item, index)=>
                                        <div key={index} style={{ background: `${css?.bgCardMesaLocalizacao}`, color: `${css?.colorCardMesaLocalizacao}` }} className={`cardMesa ${selectedMesa === index ? 'mudarCorCardMesa' : ''}`} onClick={() => selecionarMesa(item, index)}>
                                            <div className='mesaNome'> {item.MESA} </div>
                                            <div>
                                            {
                                                selectedMesa === index ? (
                                                    <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                                                )
                                                : (
                                                    <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                                                ) 
                                            }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            ) : null }
                    </div>
                )
                : null
            }
        </div>
    )
}