import { useState, useEffect } from 'react'
import './PizzasInfo.css'
import Decimal from 'decimal.js';
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';

export default function PizzasInfo({ SaboresSelecionados, dadosConexao, listaSaboresPizzas, setListaSaboresPizzas, Produto, setTotalCompra, setCustoCompra, valorTotalItem, valorTotalCusto, valorTotalCustoPizza, valorTotalSabores, css, tamanhoParaAdd }){
    const [modoPreparo, setModoPreparo] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const valorVenda = new Decimal(valorTotalSabores || 0);
        const valorCusto = new Decimal(valorTotalCustoPizza || 0);
        const newTotalCompra = valorVenda.plus(valorTotalItem);
        const newCustoCompra = valorCusto.plus(valorTotalCusto);
        setTotalCompra(newTotalCompra.toNumber().toFixed(2));
        setCustoCompra(newCustoCompra.toNumber().toFixed(2));
    }, [valorTotalItem, valorTotalCusto, valorTotalSabores, valorTotalCustoPizza]);
    const removerSaborPizza = (itemClicado) => {
        const updatedListaOpcionais = [...listaSaboresPizzas];
        const removedSabor = itemClicado.PRODUTO.toLowerCase();
        const removedID_GRADE = itemClicado.ID_GRADE;
        const index = updatedListaOpcionais.findIndex(
          item =>
            item.ID_GRADE === removedID_GRADE && item.PRODUTO.toLowerCase() === removedSabor
        );
        if (index !== -1) {
          updatedListaOpcionais[index].quantidade = 0;
          setListaSaboresPizzas(updatedListaOpcionais);
        }
    };
    useEffect(()=>{
        if(dadosConexao){
            handleModoPreparo(dadosConexao)
        }
    }, [dadosConexao])
    const handleModoPreparo=()=>{
        const ROTAmodopreparoproduto = `http://${dadosConexao.api}:${dadosConexao.porta}/modoPreparoProduto/${Produto.ID_PRODUTO}`
        const dados_conexao = encodeURIComponent(ROTAmodopreparoproduto)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setModoPreparo(getdata.data[0])
            })
            .catch((error)=>{
                const mensagem = 'Erro 542'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            })
    }
    return(
        <div className='PizzasInfo'> 
            <div className='cardFichatecnica' style={{ background: `${css?.bgCardInfoPizza}`, color: `${css?.colorCardInfoPizza}` }}>
                <div className='pizzaInfoTamanho'> {Produto.TAMANHO? Produto.TAMANHO : tamanhoParaAdd.TAMANHO} </div>
                { SaboresSelecionados.length > 0 ? (
                    <>
                        <div className='font16 ' style={{ display: 'grid', placeItems: 'center' }}> {t('ingredientes')}  </div>
                        <div className='font16 ' style={{ padding: '10px' }}>
                            { SaboresSelecionados !== '' ? SaboresSelecionados.map((item) => item.FICHA_TECNICA.toLowerCase() + " / " ) : null }
                        </div>
                    </>
                    ) : null
                }
                { modoPreparo.MODO_PREPARO !== '' && modoPreparo.MODO_PREPARO !== null  ? (
                    <>
                        <div className='font16 ' style={{ display: 'grid', placeItems: 'center' }}> {t('modoPreparo')}  </div>
                        <div className='font16 ' style={{ padding: '10px' }}>
                            { modoPreparo.MODO_PREPARO }
                        </div>
                    </>
                    ) : ''
                }
            </div>
                <div className='pizzasInfoCaixaSabores' style={{ background: `${css?.bgBoxSaboresPizza}` }}> 
                    {SaboresSelecionados.map((item, index)=>
                        <div className='pizzasInfoSabores' key={index} onClick={() => removerSaborPizza(item)} style={{ background: `${css?.bgSaborPizzaSelecionado}`, color: `${css?.colorSaborPizzaSelecionado}`, border: `${css?.borderSaborPizzaSelecionado === 'NAO' ? 'none' : ''}` }}>
                            <img src={'data:image/png;base64,' + css?.iconePizza} className='iconePizza'/>
                            <div className='nomeInfo'>{item.PRODUTO.toLowerCase()}</div>
                            <div className='InfoX'> X </div>
                        </div>
                    )} 
                </div>
        </div>
    )
}