import { useState, useEffect } from 'react'
import AdicionaisInfo from './AdicionaisFuncoes/AdicionaisInfo'
import AdicionaisTamanho from '../AdicionaisTamanho/AdicionaisTamanho'
import GrupoAdicionais from './AdicionaisFuncoes/GrupoAdicionais'
import Observacoes from './AdicionaisFuncoes/Observacoes'
import { useLocation } from 'react-router-dom';
import TopoHeaderBar from '../../../header/TopoHeaderBar'
import Footer from '../../../Footer/Footer'
import BtnCarrinho from './BtnCarrinho'
import '../../../../Styles/Styles.css'
import BtnsFlutuantes from '../../../BarrasNavegacao/BtnsFlutuantes'
import FichaTecnica from './AdicionaisFuncoes/FichaTenica'

export default function AdicionaisMain({ css, dadosConexao }){
    const { state } = useLocation();
    //grupos
    const { grupo } = state;
    //produto 
    const { data } = state;
    //adicionais
    const [adicionalSelecionado, setAdicionalSelecionado] = useState([]);
    const [valorTotalItem, setValorTotalItem] = useState(0);
    const [valorTotalCusto, setValorTotalCusto] = useState(0);
    //AdicionaisTamanho
    const [tamanhoEscolhido, setTamanhoEscolhido] = useState(null);
    const [existeTamanho, setExisteTamanho] = useState('');
    //observaçoes
    const [observacoes, setObservacao] = useState('');
    
    const [ID_GRUPO_OPCOES, setID_GRUPO_OPCOES] = useState('');

    const [totalCompra, setTotalCompra] = useState('');
    const [custoCompra, setCustoCompra] = useState('');

    const [cardapioAbreviado, setCardapioAbreviado] = useState('');
    const [obrigatorioopcional, setObrigatorioOpcional] = useState(false);

    useEffect(()=>{
        const carrinhoAbreviado = localStorage.getItem('cardapioAbreviado');
            setCardapioAbreviado(carrinhoAbreviado)
    }, [])
    const [fichaTecnica, setFichaTecnica] = useState([]);
    
    return(
        <div className='pagina'>
            <BtnsFlutuantes
                css={css}
            />
            <div className='Main'>
            <div>
                <TopoHeaderBar
                    css={css}
                />
            </div>
            <div>
                <AdicionaisInfo
                    //Info Produtos
                        Produto={data}
                        grupo={grupo}
                    //Info Adicionais
                        ID_GRUPO_OPCOES={ID_GRUPO_OPCOES}
                        adicionalSelecionado={adicionalSelecionado}
                        valorTotalItem={valorTotalItem}
                        valorTotalCusto={valorTotalCusto}
                        fichaTecnica={fichaTecnica}
                        setFichaTecnica={setFichaTecnica}
                    //Info Adicionais Tamanho
                        existeTamanho={existeTamanho}
                        tamanhoEscolhido={tamanhoEscolhido}
                    //Observacoes
                        observacoes={observacoes}
                    //
                        setTotalCompra={setTotalCompra}
                        setCustoCompra={setCustoCompra}
                        totalCompra={totalCompra}
                        css={css}
                        dadosConexao={dadosConexao}
                />
            </div>
            <div>
                <AdicionaisTamanho
                    setTamanhoEscolhido={setTamanhoEscolhido}
                    setExisteTamanho={setExisteTamanho}
                    setAdicionalSelecionado={setAdicionalSelecionado}
                    css={css}
                    dadosConexao={dadosConexao}
                />
            </div>
            <div>
                <GrupoAdicionais
                    adicionalSelecionado={adicionalSelecionado}
                    setAdicionalSelecionado={setAdicionalSelecionado}
                    setID_GRUPO_OPCOES={setID_GRUPO_OPCOES}
                    setValorTotalCusto={setValorTotalCusto}
                    setValorTotalItem={setValorTotalItem}
                    setObrigatorioOpcional={setObrigatorioOpcional}
                    tamanhoEscolhido={tamanhoEscolhido}
                    css={css}
                    dadosConexao={dadosConexao}
                />
            </div>
            {
            //<div>
             //   <FichaTecnica
             //       css={css}
            //      fichaTecnica={fichaTecnica}
             //       setFichaTecnica={setFichaTecnica}
             //   />
            //</div>
        }
                {cardapioAbreviado === "SIM" || cardapioAbreviado === "" ? (null) : (
                    <>
                        <div>
                            <Observacoes
                                setObservacao={setObservacao}
                                css={css}
                            />
                        </div>
                        <div>
                            <BtnCarrinho
                                Produto={data}
                                PIZZA_MISTA={grupo.PIZZA_MISTA}
                                ID_GRUPO_OPCOES={ID_GRUPO_OPCOES}
                                adicionalSelecionado={adicionalSelecionado}
                                totalCompra={totalCompra}
                                tamanhoEscolhido={tamanhoEscolhido}
                                observacoes={observacoes}
                                totalCusto={custoCompra}
                                existeTamanho={existeTamanho}
                                obrigatorioopcional={obrigatorioopcional}
                                fichaTecnica={fichaTecnica}
                                css={css}
                                dadosConexao={dadosConexao}
                            />
                        </div>
                    </>
                )}
            </div>
            <div>
                <Footer
                    css={css}
                />
            </div>
        </div>
    )
}