import { useEffect, useState } from 'react'
import { formCurrency } from '../../../../auxiliares/utilidades/FormataBRL';
import { formCurrencyArgentina } from '../../../../auxiliares/utilidades/FormataARG';
import { formCurrencyUSD } from '../../../../auxiliares/utilidades/FormataUSD';
import { api } from '../../../../conecções/api'
import { useLocation } from 'react-router-dom';
import '../../../../Styles/StyleForAdicionais.css'
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';
import { useTranslation } from 'react-i18next';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem'

export default function GrupoTamanho({ dadosConexao, setAdicionalSelecionado, setExisteTamanho, setTamanhoEscolhido, css }){
  const [grupoTamanho, setGrupoTamanho] = useState([]);
  const { state } = useLocation();
  const { data } = state;
  const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
  const tipoComanda = localStorage.getItem('tipoComanda');
  const { t } = useTranslation();
  const moeda = dadosConexao?.moeda

  useEffect(()=>{
    if(Array.isArray(grupoTamanho) && grupoTamanho.length > 0 && grupoTamanho !== -1){
        setExisteTamanho(true)
      } else {
        setExisteTamanho(false)
      }
  }, [grupoTamanho])
  useEffect(()=>{
    if(dadosConexao){
      handleListaTamanhos()
    }
  }, []);
  const handleListaTamanhos=()=>{
      const ROTAadicionaisTamanho = `http://${dadosConexao.api}:${dadosConexao.porta}/listaTamanhos/${data.ID_PRODUTO}/${tipoComanda}`
      const dados_conexao = encodeURIComponent(ROTAadicionaisTamanho)
      api
          .post(`/requisicaoDados/${dados_conexao}`)
          .then((getdata)=>{
              setGrupoTamanho(getdata.data);
          })
          .catch((error) => {
            const mensagem = 'Erro 547'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        });
  }
  const RadioTamanhos = (item, index) => {
    setSelectedRadioIndex(index);
    setTamanhoEscolhido(item)
    setAdicionalSelecionado([])
  };
  
    return(
        <div>
          <div>{Array.isArray(grupoTamanho) ? (
              <div className='tituloTamanhos' style={{ background: `${css?.bgTituloTamanhosProduto}`, color: `${css?.colorTituloTamanhosProduto}`, marginTop: '10px' }}> {t('tamanhos').toUpperCase()} </div>
            ) : (
              <div></div>
            )}
          </div>    
          <div className='listaTamanhos' style={{ background: `${css?.bgListaTamanhos}` }}> 
            {Array.isArray(grupoTamanho)
              ? grupoTamanho.map((item, index) => (
                <div
                  className='cardTamanhos'
                  style={{ background: `${selectedRadioIndex === index ? css?.bgChecked : css?.bgCardTamanhosProduto}`, color: `${css?.colorCardTamanhosProduto}` }}
                  key={index}
                  onClick={() => RadioTamanhos(item, index)}
                >
                <div className='box-tamanhos-1'>
                  <div className='tamanhos-nome'>{capitalizeFirstLetter(item.TAMANHO.toLowerCase())}</div>
                </div>
                <div className='box-tamanhos-2'>
                  <div className='tamanhos-valor'>
                    {
                      moeda === 'BRL' ? formCurrency.format(item.VALOR_VENDA) :
                      moeda === 'ARS' ? formCurrencyArgentina.format(item.VALOR_VENDA) : 
                      moeda === 'USD' ? formCurrencyUSD.format(item.VALOR_VENDA) : null
                    }
                  </div>
                </div>
                <div className='Card-Icon'>
                  <input type='radio' name='tamanhos' checked={selectedRadioIndex === index} onChange={() => {}}/>
                    {selectedRadioIndex === index 
                      ? (
                        <img src={'data:image/png;base64,' + css?.iconeChecked} className='icone40P'/>
                      )
                      : (
                        <img src={'data:image/png;base64,' + css?.iconeUnchecked} className='icone40P'/>
                      ) 
                    }
                </div>
              </div>
                ))
              : null}
            </div> 
        </div>
    )

}