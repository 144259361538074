import { useTranslation } from 'react-i18next';
import '../../../../../Styles/StyleForAdicionais.css'

export default function Observacoes({ setObservacao, css }){
    const {t} = useTranslation();
    
    return(
    <div>
        <div>
            <div className='titulo-observacoes' style={{ background: `${css?.bgObservacoesTituloItem}`, color: `${css?.colorObservacoesTituloItem}` }} > {t('observacoes').toUpperCase()} </div>
        </div>
        <div className='ObservacoesAdicionais-box' style={{ background: `${css?.bgObservacoesItem}`, color: `${css?.colorObservacoesItem}` }}>
            <textarea className='ObservacoesAdicionais-text'
                style={{  color: `${css?.colorObservacoesItem}` }}
                placeholder={t('observacoes') + '...'}
                onChange={e => setObservacao(e.target.value)}
            />
        </div>
    </div>
    )
}