import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import '../../Styles/Styles.css'
import '../../Styles/StyleImagens.css'
import BarraCarrinhoAtalho from "./BarraCarrinhoAtalho";

export default function BtnsFlutuantes({ css }){
  const pagamento = localStorage.getItem('pagamento');
  const cardapio = localStorage.getItem('cardapio');
  const [aparelho, setAparelho] = useState('');
  const navigate = useNavigate('');
    
  useEffect(()=>{
    handleDetectarMobile()
  }, [])
  const handleDetectarMobile=()=> { 
    if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ){
      setAparelho(true)
    }
    else {
      setAparelho(false)
    }
  }
  const handleCart=()=>{
    navigate('/Carrinho')
  }
  const handleMenu=()=>{
    navigate('/Main')
  }
    return(
        <div>
            {
              pagamento === 'SIM' || cardapio === 'SIM' ? null : (
              aparelho === false ? (
                <>
                  <div className="menuflutuante" onClick={()=> handleCart()}>
                    <img src={'data:image/png;base64,' + css?.iconeCartBarra} className='iconeBarraAtalho'/>
                  </div>
                  <div className="cartflutuante" onClick={()=> handleMenu()}>
                    <img src={'data:image/png;base64,' + css?.iconeHomeBarra} className='iconeBarraAtalho'/>
                  </div>                    
                </>
              ) : aparelho === true ? (
                <BarraCarrinhoAtalho
                  css={css}
                />
              ) : null
            )
            }
        </div>
    )
}