import { useState, useEffect} from 'react'
import '../../../../Styles/StyleEndereco.css'
import { api } from '../../../../conecções/api';
import { JanelaMensagem } from '../../../../auxiliares/janelaFlutuante/JanelaMensagem';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../auxiliares/utilidades/PrimeiraMaiuscula';

export default function modal({ user, setIsOpenCadastrarEndereco, dadosConexao, setAtualizarEnderecos, css}){
    const [apelido, setApelido] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [referencia, setReferencias] = useState('');
    const [idCidade, setIdCidade] = useState('');
    const [cidade, setCidade] = useState('');
    const [idBairro, setIdBairro] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidadesAceitas, setCidadesAceitas] = useState([]);
    const [bairrosAceitos, setBairrosAceitos] = useState([]);
    const {t} = useTranslation();

    useEffect(()=>{
        if(dadosConexao){
            handleCidades()
        }
    }, [dadosConexao])
    const handleCidades=()=>{
        const ROTAcidades = `http://${dadosConexao.api}:${dadosConexao.porta}/cidades`
        const dados_conexao = encodeURIComponent(ROTAcidades)
        api
            .post(`/requisicaoDados/${dados_conexao}`)
            .then((getdata)=>{
                setCidadesAceitas(getdata.data)
            })
            .catch((error) =>{
                const mensagem = 'Erro 515'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            })
    }
    const handleCidade=(event)=>{
        const [id, nome] = event.split('|')
        setIdCidade(id)
        setCidade(nome)
        pegarBairro(id);
    }
    const pegarBairro=(id)=>{
        const ROTAbairros = `http://${dadosConexao.api}:${dadosConexao.porta}/bairros/${id}`
        const dados_conexao = encodeURIComponent(ROTAbairros)
        if(id !== ""){
            api
                .post(`/requisicaoDados/${dados_conexao}`)
                .then((response) => {
                    setBairrosAceitos(response.data);
                })
                .catch((error) => {
                    const mensagem = '516'
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                });
        }
    }
    const handlebairro=(event)=>{
        const [id, nome] = event.split('|')
        setBairro(nome)
        setIdBairro(id)
    }
    const salvar =(user)=>{
        const idToken= user.uid
        const ROTAinserirEndereco = `http://${dadosConexao.api}:${dadosConexao.porta}/insereEndereco`
        const dados_conexao2 = encodeURIComponent(ROTAinserirEndereco)
        if(idBairro !== "" && apelido !== "" && rua !== "" && numero !== ""){
        api
            .post(`/requisicaoDadosPost/${dados_conexao2}`,{
                    firebase_token: idToken,
                    apelido: apelido,
                    rua: rua,
                    numero: numero,
                    referencia: referencia,
                    id_cidade: idCidade,
                    id_bairro: idBairro,
                    bairro: bairro
                })
            .then((response) =>{
                if(response.data === -400){
                    const mensagem =  'Erro 514'
                    const cor = 'vermelho'
                    JanelaMensagem(mensagem, cor)
                } else if (response.data === 200){
                    const cor = 'verde'
                    const mensagem = t('salvo') + "!" 
                        JanelaMensagem(mensagem, cor)
                        setIsOpenCadastrarEndereco(false)
                        setAtualizarEnderecos(true)
                }
            })
            .catch((error) => {
                const mensagem = '515'
                const cor = 'vermelho'
                JanelaMensagem(mensagem, cor)
            });
        } else {
            const mensagem = '516'
            const cor = 'vermelho'
            JanelaMensagem(mensagem, cor)
        }
    }
    
    return(
    <>
        <div className='modalUser'  onClick={() => setIsOpenCadastrarEndereco(false)} />
            <div className='centeredModal'>
            <div className='modalUsuario' style={{ background: `${css?.bgModalEditEndereco}`, color: `${css?.colorModalEditEndereco}` }}>
            <button className='closeBtn' onClick={() => setIsOpenCadastrarEndereco(false)}> <div className='iconeBtnCloseModal'></div> </button>
                <div className='modalEditContent'> 
                    <div className='modalTitulo'> {capitalizeFirstLetter(t('cadastrarEndereco'))}</div>
                    <div className='avisoobrigatório'> {capitalizeFirstLetter(t('camposObrigatorios'))} {capitalizeFirstLetter(t('obrigatorios'))} </div>
                    <div className='itensModal'>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> * {t('apelido')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}`}}
                                type='text'
                                placeholder={t('exemploApelido')}
                                name='input'
                                className='input'
                                value={apelido}
                                onChange={(e)=> setApelido(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> * {t('rua')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}`}}
                                type='text'
                                placeholder={t('exemploRua')}
                                name='input'
                                className='input'
                                value={rua}
                                onChange={(e)=> setRua(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> * {t('numero')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}`}}
                                type='number'
                                placeholder='1000'
                                name='input'
                                className='input'
                                value={numero}
                                onChange={(e)=> setNumero(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> {t('referencia')} </label>
                            <input
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}`}}
                                type='text'
                                placeholder={t('exemploReferencia')}
                                name='input'
                                className='input'
                                value={referencia}
                                onChange={(e)=> setReferencias(e.target.value)}
                            />
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> * {t('cidade')} </label>
                            <select 
                                style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}`}}
                                id="escolha" 
                                name="escolha" 
                                className='select' 
                                onChange={(event) => handleCidade(event.target.value)}
                            >
                                <option value=""> {t('selecioneCidade')} </option>
                                {Array.isArray(cidadesAceitas) ? (
                                    cidadesAceitas.map((item, index) => (
                                        <option key={index} value={`${item.ID}|${item.NOME}`}> {item.NOME} </option>
                                    ))
                                ) : null}
                            </select>
                        </div>
                        <div className="coolinput">
                            <label htmlFor="input" className="text"> * {t('bairro')} </label>
                        <select 
                            style={{ background: `${css?.bgInputEditEndereco}`, color: `${css?.colorInputEditEndereco}`}}
                            id="escolha" 
                            name="escolha" 
                            className='select' 
                            onChange={(event) => handlebairro(event.target.value)}
                        >
                                <option value=""> {t('selecioneBairro')} </option>
                                {Array.isArray(bairrosAceitos) ? (
                                    bairrosAceitos.map((item, index) => (
                                        <option key={index} value={`${item.ID}|${item.DESCRICAO}`}> {item.DESCRICAO} </option>
                                    ))
                                ) : null}
                            </select>
                        </div>
                        <div className='caixaBtnSalvarEnredereco'>
                            <button style={{ background: `${css?.bgBtnSalvarEndereco}`, color: `${css?.colorBtnSalvarEndereco}` }} className='enderecobtn' onClick={()=> salvar(user)}> {t('salvar')} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};